<template>
    <v-card elevation="1">
      <v-card-title class="mb-3">
        Liste des recherches enregistrées
      </v-card-title>
      <v-divider class="mb-5"></v-divider>
      <v-card-text>
        <v-form>
            <v-text-field v-model="label" label="Nom de la recherche"></v-text-field>
            <v-checkbox
                v-model="activeAlert"
                :label="`Recevoir une alerte ?`"
            ></v-checkbox>
            <v-btn @click="saveSearch">
                <v-icon>
                    mdi-content-save-outline
                </v-icon>
                Enregistrer
            </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: 'AddSavedSearchDialog',
    props: ['selectedFilters'],

    data() {
        return {
            label: '',
            activeAlert: 0
        }
    },
    created() {
      
    },
    methods: {
      saveSearch: async function () {
        try {
          // var data = this.selectedFilters;
          // var label = "Test seach";
          // var activeAlert = 1;

          const response = await axios.post(this.baseApiURL() + '/saved_search', 
          {
            user: this.user.id,
            label: this.label,
            data: JSON.stringify(this.selectedFilters),
            activeAlert: this.activeAlert,
          }
          ,this.getHeaders());

          if(response) {
            this.$emit('saveSearch', true)
            console.log("Saved !")
          }

        } catch (e) {
          console.error(e);
        }
      }, 
    }
  }
  </script>