<template>
    <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer ?</v-card-title>
        <v-card-text>
            <v-alert outlined
                    type="warning"
                    border="left">
                Supprimer l'agence : {{ label }} ? <br>
                Cet action supprimera aussi toutes les données qui lui sont liées
            </v-alert>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">Annuler</v-btn>
            <v-btn color="red darken-1" text @click="$emit('valid')">Supprimer</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
  name: 'DeleteAgenceDialog',
  props: ['label']
}
</script>
