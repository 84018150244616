<template>
    <v-row class="mt-3">
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Calendrier
                </v-card-title>
                <v-row class="fill-height">
                    <v-col>
                        <v-sheet height="64">
                            <v-toolbar
                            flat
                            >
                                <v-btn
                                    outlined
                                    class="mr-4"
                                    color="grey darken-2"
                                    @click="setToday"
                                >
                                    Today
                                </v-btn>
                                <v-btn
                                    fab
                                    text
                                    small
                                    color="grey darken-2"
                                    @click="prev"
                                >
                                    <v-icon small>
                                    mdi-chevron-left
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    fab
                                    text
                                    small
                                    color="grey darken-2"
                                    @click="next"
                                >
                                    <v-icon small>
                                    mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                                <v-toolbar-title v-if="$refs.calendar">
                                    {{ $refs.calendar.title }}
                                </v-toolbar-title>
                                <v-spacer v-if="user.role.id == 1"></v-spacer>
                                <v-select
                                    v-if="user.role.id == 1"
                                    v-model="agence"
                                    :items="agences"
                                    item-text="label"
                                    item-value="value"
                                    @change="getEvents"
                                    dense
                                    outlined
                                    hide-details
                                    class="ma-2"
                                    label="Agence"
                                ></v-select>
                                <v-spacer></v-spacer>
                                <v-select
                                    v-model="type"
                                    :items="types"
                                    item-text="label"
                                    item-value="value"
                                    dense
                                    outlined
                                    hide-details
                                    class="ma-2"
                                    label="Affichage"
                                ></v-select>
                            </v-toolbar>
                        </v-sheet>
                        <v-sheet height="600">
                            <v-calendar
                            ref="calendar"
                            v-model="value"
                            color="primary"
                            :type="type"
                            locale="fr"
                            locale-first-day-of-year="1"
                            :events="events"
                            :event-color="getEventColor"
                            :event-ripple="true"
                            :weekdays="weekdays"
                            @click:event="showEvent"
                            @click:more="viewDay"
                            
                            @change="getEvents"
                            @mousedown:event="startDrag"
                            @mousedown:time="startTime"
                            @mousemove:time="mouseMove"
                            @mouseup:event="showEvent"
                            @mouseup:time="endDrag"
                            
                            @mouseleave.native="cancelDrag"
                            >
                            <template v-slot:event="{ eventParsed, event, timed }">
                                <div class="v-event-draggable">
                                    <span class="v-event-summary">
                                        <strong>{{ event.name }}</strong>
                                        {{ eventParsed.start.time }} - {{ eventParsed.end.time }}
                                        <p v-if="event.agence" class="mb-1"><v-icon color="white" small>mdi-store</v-icon> {{ event.agence.label }}</p>
                                        <p v-if="event.customer"><v-icon color="white" small>mdi-account</v-icon> {{ event.customer.firstname }} {{ event.customer.lastname }}</p>
                                    </span>
                                </div>
                                <div
                                    v-if="timed"
                                    class="v-event-drag-bottom"
                                    @mousedown.stop="extendBottom(event)"
                                ></div>
                            </template>
                            <template v-slot:day-body="{ date, week }">
                                <div
                                class="v-current-time"
                                :class="{ first: date === week[0].date }"
                                :style="{ top: nowY }"
                                ></div>
                            </template>
                            </v-calendar>
                            <v-menu
                            v-model="selectedOpen"
                            :close-on-content-click="false"
                            :activator="selectedElement"
                            offset-x
                            >
                            <v-card
                                color="grey lighten-4"
                                min-width="350px"
                                flat
                            >
                                <v-toolbar
                                :color="selectedEvent.color"
                                dark
                                >
                                <v-btn v-if="canEdit==false" icon @click="canEdit = true;">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn v-if="canEdit==true" icon @click="canEdit = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title v-if="!selectedEvent.name">
                                    <v-select
                                        :items="['RDV', 'Indisponible']"
                                        v-model="selectedEvent.name"
                                        label="Standard"
                                    ></v-select>
                                </v-toolbar-title>
                                <v-toolbar-title v-if="selectedEvent.name" v-html="selectedEvent.name"></v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn color="red" small fab @click="DeleteRdvDialog = true;selectedOpen = false;">
                                    <v-icon color="white">mdi-trash-can-outline</v-icon>
                                </v-btn>
                                </v-toolbar>
                                <v-card-text>
                                    <div v-if="canEdit==true && selectedEvent.name != 'RDV'" >
                                        <v-menu
                                            v-model="eventDay"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="selectedEvent.day"
                                                label="Jour de l'évènement"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="selectedEvent.day"
                                            @input="eventDay = false"
                                            ></v-date-picker>
                                        </v-menu>
                                        <v-row>
                                            <v-col md="6" class="text-center">
                                                <v-menu
                                                    ref="menuStart"
                                                    v-model="menuStartOpen"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    :return-value.sync="selectedEvent.startTime"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="selectedEvent.startTime"
                                                        label="Heure de début :"
                                                        prepend-icon="mdi-clock-time-four-outline"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-time-picker
                                                    v-if="menuStartOpen"
                                                    v-model="selectedEvent.startTime"
                                                    :max="selectedEvent.endTime"
                                                    format="24hr"
                                                    full-width
                                                    @click:minute="$refs.menuStart.save(selectedEvent.startTime)"
                                                    ></v-time-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col md="6">
                                                <v-menu
                                                    ref="menuEnd"
                                                    v-model="menuEndOpen"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    :return-value.sync="selectedEvent.endTime"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="selectedEvent.endTime"
                                                        label="Heure de fin :"
                                                        prepend-icon="mdi-clock-time-four-outline"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-time-picker
                                                    v-if="menuEndOpen"
                                                    v-model="selectedEvent.endTime"
                                                    :min="selectedEvent.startTime"
                                                    format="24hr"
                                                    full-width
                                                    @click:minute="$refs.menuEnd.save(selectedEvent.endTime)"
                                                    ></v-time-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div v-if="canEdit==true && selectedEvent.name == 'RDV'" >
                                        <v-menu
                                            v-model="eventDay"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="selectedEvent.day"
                                                label="Jour de l'évènement"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="selectedEvent.day"
                                            @input="eventDay = false"
                                            ></v-date-picker>
                                        </v-menu>
                                        <v-row>
                                            <v-col md="6" class="text-center">
                                                <v-menu
                                                    ref="menuStart"
                                                    v-model="menuStartOpen"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    :return-value.sync="selectedEvent.startTime"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="selectedEvent.startTime"
                                                        label="Heure de début :"
                                                        prepend-icon="mdi-clock-time-four-outline"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-time-picker
                                                    v-if="menuStartOpen"
                                                    v-model="selectedEvent.startTime"
                                                    :max="selectedEvent.endTime"
                                                    format="24hr"
                                                    full-width
                                                    @click:minute="$refs.menuStart.save(selectedEvent.startTime)"
                                                    ></v-time-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col md="6">
                                                <v-menu
                                                    ref="menuEnd"
                                                    v-model="menuEndOpen"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    :return-value.sync="selectedEvent.endTime"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="selectedEvent.endTime"
                                                        label="Heure de fin :"
                                                        prepend-icon="mdi-clock-time-four-outline"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-time-picker
                                                    v-if="menuEndOpen"
                                                    v-model="selectedEvent.endTime"
                                                    :min="selectedEvent.startTime"
                                                    format="24hr"
                                                    full-width
                                                    @click:minute="$refs.menuEnd.save(selectedEvent.endTime)"
                                                    ></v-time-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-autocomplete
                                            label="Sélectionnez une annonce"
                                            v-model="selectedEvent.annonce"
                                            :items="annonces"
                                            item-value="id"
                                            item-text="label"
                                            @change="setCustomer();"
                                        ></v-autocomplete>
                                        
                                        <v-autocomplete
                                            v-if="canEdit==true"
                                            label="Sélectionnez un client"
                                            v-model="selectedEvent.customer"
                                            :items="customers"
                                            item-value="id"
                                            item-text="label"
                                        ></v-autocomplete>

                                        <v-textarea
                                        v-if="canEdit==true"
                                        outlined
                                        v-model="selectedEvent.description"
                                        label="Description"
                                        :value="selectedEvent.description"
                                        ></v-textarea>
                                    </div>
                                    <div v-if="canEdit==false">
                                        <p v-if="selectedEvent.agence" class="pa-2" style="background: #ededed;border-radius: 5px;line-height: 2;">
                                            <v-icon color="#3788D8" small>mdi-store</v-icon>{{ selectedEvent.agence.label }}
                                        </p>
                                        <p v-if="selectedEvent.annonce" class="pa-2" style="background: #ededed;border-radius: 5px;line-height: 2;">
                                            <v-icon color="#3788D8" small>mdi-car-back</v-icon>{{ selectedEvent.vehicule.marque }} {{ selectedEvent.vehicule.modele }} (#{{ selectedEvent.annonce.reference }})
                                            <v-btn class="float-right" small icon :href="'/annonce_edit/' + selectedEvent.annonce.id">
                                                <v-icon small>mdi-eye</v-icon>
                                            </v-btn>
                                        </p>
                                        <p v-if="selectedEvent.customer"><v-icon color="#3788D8" small>mdi-account</v-icon> {{ selectedEvent.customer.firstname }} {{ selectedEvent.customer.lastname }}
                                        </p>
                                        <p v-if="selectedEvent.customer"><v-icon color="#3788D8" small>mdi-phone</v-icon> {{ selectedEvent.customer.phone }}</p>
                                        <p v-if="selectedEvent.customer"><v-icon color="#3788D8" small>mdi-email</v-icon> {{ selectedEvent.customer.email }}</p>
                                        <p v-if="selectedEvent.description" class="pa-2" style="background: #ededed;border-radius: 5px;">
                                            Note : <br>
                                            {{ selectedEvent.description }}
                                        </p>
                                        

                                    </div>
                                </v-card-text>
                                <v-card-actions>
                                <v-btn
                                    text
                                    color="secondary"
                                    @click="selectedOpen = false;getEvents()"
                                >
                                    Annuler
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="saveEvents();selectedOpen = false"
                                >
                                    Enregistrer
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-menu>
                        </v-sheet>
                    </v-col>
                </v-row>

                <v-dialog v-model="DeleteRdvDialog" max-width="600px">
                    <DeleteRdvDialog :rdv="selectedEvent" v-on:valid="removeRdv" v-on:close="DeleteRdvDialog = false"></DeleteRdvDialog>
                </v-dialog>

            </v-card>
        </v-col>
    </v-row>
</template>
  
<script>
    import axios from "axios";
    import DeleteRdvDialog from "@/components/rdv/DeleteRdvDialog";
  
    export default {
        name: 'CalendarView',
    
        components: {
            DeleteRdvDialog
        },

        data: () => ({

            DeleteRdvDialog: false,

            menuStartOpen: false,
            menuEndOpen: false,
            eventDay: false,

            openHours: [],

            type: 'week',
            types: [
                {
                    label: 'Mois', 
                    value: 'month', 
                },
                {
                    label: 'Semaine', 
                    value: 'week', 
                },
                {
                    label: 'Jour',
                    value: 'day',
                }
            ],
            focus: '',
            value: '',
            events: [],
            colors: ['#3788D8', 'red'],
            names: ['RDV', 'Indisponnible'],
            dragEvent: null,
            dragStart: null,
            createEvent: null,
            createStart: null,
            extendOriginal: null,
            weekdays: [1,2,3,4,5,6,0],

            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,

            canEdit: false,

            customers: [],
            annonces: [],
            listeAnnonce: [],

            agence: null,
            agences: [],

            ready: false,
        }),
        computed: {
            cal () {
                return this.ready ? this.$refs.calendar : null
            },
            nowY () {
                return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
            },
        },
        mounted () {
            this.$refs.calendar.checkChange()
            this.ready = true
            // this.scrollToTime()
            this.$refs.calendar.scrollToTime('06:00')
            this.updateTime()
        },
        async created() {
            await this.getOpenHours();
            await this.getCustomers();
            await this.getAnnoncesByAgence();
            await this.getAgences();
        },
        methods: {
            getAgences: async function () {
                const response = await axios.get(this.baseApiURL() + '/agences', this.getHeaders());
                if(response){
                    for(let agence of response.data){
                        this.agences.push({
                            label: agence.label,
                            value: agence.id
                        })
                    }
                }
            },
            getCurrentTime () {
                return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
            },
            // scrollToTime () {
            //     const time = this.getCurrentTime()
            //     const first = Math.max(0, time - (time % 30) - 30)

            //     this.cal.scrollToTime(first)
            // },
            updateTime () {
                setInterval(() => this.cal.updateTimes(), 60 * 1000)
            },
            viewDay ({ date }) {
                this.value = date
                this.type = 'day'
            },
            setToday () {
                this.value = ''
            },
            prev () {
                this.$refs.calendar.prev()
            },
            next () {
                this.$refs.calendar.next()
            },
            showEvent ({ nativeEvent, event }) {
                this.canEdit = false;

                const open = () => {
                    this.selectedEvent = event
                    this.selectedElement = nativeEvent.target
                    requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
                }

                if (this.selectedOpen) {
                    this.selectedOpen = false
                    requestAnimationFrame(() => requestAnimationFrame(() => open()))
                } else {
                    open()
                }

                console.log(nativeEvent.target)

                //nativeEvent.stopPropagation()
            },
            startDrag ({ event, timed }) {
                if (event && timed) {
                this.dragEvent = event
                this.dragTime = null
                this.extendOriginal = null
                }
            },
            startTime (tms) {
                if(this.selectedOpen){
                    this.selectedOpen = false;
                    this.canEdit = false;
                    this.getEvents();
                }else{
                    console.log("test create")
                    const mouse = this.toTime(tms)

                    if (this.dragEvent && this.dragTime === null) {
                        const start = this.dragEvent.start

                        this.dragTime = mouse - start
                    } else {
                        this.canEdit = true;
                        this.createStart = this.roundTime(mouse)
                        this.createEvent = {
                            id: '',
                            customer: '',
                            annonce: '',
                            vehicule: '',
                            description: '',

                            name: '',
                            color: 'blue',
                            start: this.createStart,
                            end: this.createStart,
                            // startTime: ,
                            // endTime: ,
                            timed: true,
                        }

                        this.events.push(this.createEvent)
                    }
                }
                
            },
            extendBottom (event) {
                this.createEvent = event
                this.createStart = event.start
                this.extendOriginal = event.end
            },
            mouseMove (tms) {
                const mouse = this.toTime(tms)

                if (this.dragEvent && this.dragTime !== null) {
                    
                    const start = this.dragEvent.start
                    const end = this.dragEvent.end
                    const duration = end - start
                    const newStartTime = mouse - this.dragTime
                    const newStart = this.roundTime(newStartTime)
                    const newEnd = newStart + duration

                    this.dragEvent.start = newStart
                    this.dragEvent.end = newEnd
                    console.log("test drop 1")
                } else if (this.createEvent && this.createStart !== null) {
                    const mouseRounded = this.roundTime(mouse, false)
                    const min = Math.min(mouseRounded, this.createStart)
                    const max = Math.max(mouseRounded, this.createStart)

                    this.createEvent.startTime = new Date(min).toLocaleTimeString('fr-FR');
                    this.createEvent.endTime = new Date(max).toLocaleTimeString('fr-FR');

                    this.createEvent.day = new Date(min).toISOString().split('T')[0];

                    this.createEvent.start = min
                    this.createEvent.end = max
                }

                
            },
            endDrag ({ nativeEvent }) {
                
                if(this.selectedEvent.id){
                    this.updateEventsDate();
                }else{
                    this.canEdit = true;
                }
                this.dragTime = null
                this.dragEvent = null
                this.createEvent = null
                this.createStart = null
                this.extendOriginal = null

                nativeEvent.stopPropagation()
            },
            cancelDrag () {
                if (this.createEvent) {
                    if (this.extendOriginal) {
                        this.createEvent.end = this.extendOriginal
                    } else {
                        const i = this.events.indexOf(this.createEvent)
                        if (i !== -1) {
                        this.events.splice(i, 1)
                        }
                    }
                }

                this.createEvent = null
                this.createStart = null
                this.dragTime = null
                this.dragEvent = null
            },
            roundTime (time, down = true) {
                const roundTo = 15 // minutes
                const roundDownTime = roundTo * 60 * 1000

                return down
                ? time - time % roundDownTime
                : time + (roundDownTime - (time % roundDownTime))
            },
            toTime (tms) {
                return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
            },
            getEventColor (event) {
                const rgb = parseInt(event.color.substring(1), 16)
                const r = (rgb >> 16) & 0xFF
                const g = (rgb >> 8) & 0xFF
                const b = (rgb >> 0) & 0xFF

                return event === this.dragEvent
                ? `rgba(${r}, ${g}, ${b}, 0.7)`
                : event === this.createEvent
                    ? `rgba(${r}, ${g}, ${b}, 0.7)`
                    : event.color
            },
            getEvents: async function () {
                this.events = [];
                let response;
                console.log(this.user.role )
                if(this.user.role.id == 1){
                    if(this.agence != null){
                        response = await axios.get(this.baseApiURL() + '/rdv_list/' + this.agence, this.getHeaders());
                    }else{
                        response = await axios.get(this.baseApiURL() + '/rdv_list/all', this.getHeaders());
                    }
                    
                }else{
                    response = await axios.get(this.baseApiURL() + '/rdv_list/' + this.user.id, this.getHeaders());
                }
                

                for(let rdv of response.data){
                    let start = new Date(rdv.date_debut).getTime();
                    let end = new Date(rdv.date_fin).getTime();
                    //let timed = this.rnd(0, 3) !== 0
                    let currentColor = "";
                    if(rdv.titre == "RDV"){
                        currentColor = this.colors[0];
                    }else{
                        currentColor = this.colors[1];
                    }
                    let annonce = [];
                    if(rdv.annonce){
                        annonce = await axios.get(this.baseApiURL() + '/annonce/' + rdv.annonce.id, this.getHeaders());
                        
                    }

                    // let startTimeH = new Date(rdv.date_debut).getHours();
                    // console.log(String(startTimeH).length)
                    // if(String(startTimeH).length == 1) startTimeH = '0' + startTimeH
                    // let startTimeM = new Date(rdv.date_debut).getMinutes();
                    // if(String(startTimeM).length == 1) startTimeM = '0' + startTimeM
                    // let startTime = startTimeH + ":" + startTimeM;

                    // let endTimeH = new Date(rdv.date_fin).getHours();
                    // if(String(endTimeH).length == 1) endTimeH = '0' + endTimeH
                    // let endTimeM = new Date(rdv.date_fin).getMinutes();
                    // if(String(endTimeM).length == 1) endTimeM = '0' + endTimeM
                    // let endTime = endTimeH + ":" + endTimeM;

                    let startTime = new Date(rdv.date_debut).toLocaleTimeString('fr-FR');
                    let endTime = new Date(rdv.date_fin).toLocaleTimeString('fr-FR');

                    let date = new Date(rdv.date_debut).toISOString().split('T')[0];
                    // date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                    // date = '2024-10-01'
                    
                    //console.log(annonce.data)
                    this.events.push({
                        id: rdv.id,
                        customer: rdv.customer,
                        annonce: rdv.annonce,
                        agence: rdv.agence,
                        vehicule: annonce.data ? annonce.data.vehicule : '',
                        description: rdv.description,
                        name: rdv.titre,
                        color: currentColor,
                        day: date,
                        startTime: startTime,
                        endTime: endTime,
                        start: start,
                        end: end,
                        timed: true,
                    })
                }

            },
            formatDate(date){
                let day = date.getDate();
                if(day.length == 1){
                    day = '0' + day
                }
                let month = date.getMonth() + 1;
                if(month.length == 1){
                    month = '0' + month
                }
                let year = date.getFullYear();

                let hour = date.getHours();
                if(hour.length == 1){
                    hour = '0' + hour
                }
                let minute = date.getMinutes();
                if(minute.length == 1){
                    minute = '0' + minute
                }

                return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':00';
            },
            saveEvents: async function (){
                if(this.selectedEvent.id) {
                    await this.updateEvents();
                }else{
                    let id_annonce = null;
                    if(this.selectedEvent.annonce){
                        id_annonce = this.selectedEvent.annonce.id ? this.selectedEvent.annonce.id : this.selectedEvent.annonce;
                    }

                    let id_customer = null;
                    if(this.selectedEvent.customer){
                        id_customer = this.selectedEvent.customer.id ? this.selectedEvent.customer.id : this.selectedEvent.customer;
                    }

                    let annonce = [];
                    if(id_annonce){
                        annonce = await axios.get(this.baseApiURL() + '/annonce/' + id_annonce, this.getHeaders());
                        
                    }

                    let start = this.selectedEvent.day + " " + this.selectedEvent.startTime;
                    let end = this.selectedEvent.day + " " + this.selectedEvent.endTime;

                    const response = await axios.post(this.baseApiURL() + '/rdv_list', {
                        date_debut: this.formatDate(new Date(start)),
                        date_fin: this.formatDate(new Date(end)),
                        titre: this.selectedEvent.name,
                        description: this.selectedEvent.description,
                        annonce: id_annonce,
                        customer: id_customer,
                        vehicule: annonce.data ? annonce.data.vehicule.id : null,
                        agence: this.user.agence.id,
                        user: this.user.id,
                    }, this.getHeaders());

                    if(response ){
                        //console.log(response.data)
                        this.getEvents();
                    }
                }
                
            },
            updateEvents: async function (){
                let id_annonce = null;
                if(this.selectedEvent.annonce){
                    id_annonce = this.selectedEvent.annonce.id ? this.selectedEvent.annonce.id : this.selectedEvent.annonce;
                }

                let id_customer = null;
                if(this.selectedEvent.customer){
                    id_customer = this.selectedEvent.customer.id ? this.selectedEvent.customer.id : this.selectedEvent.customer;
                }
                

                let annonce = [];
                if(id_annonce){
                    annonce = await axios.get(this.baseApiURL() + '/annonce/' + id_annonce, this.getHeaders());
                    
                }

                let start = this.selectedEvent.day + " " + this.selectedEvent.startTime;
                let end = this.selectedEvent.day + " " + this.selectedEvent.endTime;

                const response = await axios.put(this.baseApiURL() + '/rdv_list/' + this.selectedEvent.id, {
                    date_debut: this.formatDate(new Date(start)),
                        date_fin: this.formatDate(new Date(end)),
                    titre: this.selectedEvent.name,
                    description: this.selectedEvent.description,
                    annonce: id_annonce,
                    customer: id_customer,
                    // vehicule: this.selectedEvent.annonce.vehicule,
                    vehicule: annonce.data ? annonce.data.vehicule.id : null,
                    user: this.user.id,
                }, this.getHeaders());

                if(response){
                    console.log(response.data)
                    this.getEvents();
                }

            },
            updateEventsDate: async function (){
                const response = await axios.put(this.baseApiURL() + '/rdv_list/' + this.selectedEvent.id, {
                    date_debut: this.formatDate(new Date(this.selectedEvent.start)),
                    date_fin: this.formatDate(new Date(this.selectedEvent.end)),
                }, this.getHeaders());

                if(response){
                    console.log(response.data)
                    // this.getEvents();
                }

            },
            getCustomers: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/customers', this.getHeaders());
                    if(response) {
                        this.customers = response.data;
                        for(const customer in this.customers){
                            
                            this.customers[customer].label = this.customers[customer].firstname + " " + this.customers[customer].lastname + " (" + this.customers[customer].email + ")";
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            setCustomer: async function () {
                try {
                    let id_annonce = this.selectedEvent.annonce.id ? this.selectedEvent.annonce.id : this.selectedEvent.annonce;
                    console.log(id_annonce)
                    let annonce = [];
                    if(id_annonce){
                        annonce = await axios.get(this.baseApiURL() + '/annonce/' + id_annonce, this.getHeaders());
                        this.selectedEvent.customer = annonce.data.customer;
                    }
                    console.log(this.selectedEvent.customer)

                } catch (e) {
                    console.error(e);
                }
            },
            getAnnoncesByAgence: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/annonces/' + this.user.agence.id, this.getHeaders());
                    if(response) {
                        this.annonces = response.data;
                        for(const annonce in this.annonces) {
                            this.annonces[annonce].label = this.annonces[annonce].vehicule.marque + " " + this.annonces[annonce].vehicule.modele + "(#" + this.annonces[annonce].reference + ")";
                           
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            removeRdv: async function () {
                try {
                    const response = await axios.delete(this.baseApiURL() + '/rdv_list/' + this.selectedEvent.id, this.getHeaders());
                    if(response) {
                        this.DeleteRdvDialog = false;
                        await this.getEvents();
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            rnd (a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            },
            rndElement (arr) {
                return arr[this.rnd(0, arr.length - 1)]
            },
            getOpenHours: async function() {
                const response = await axios.get(this.baseApiURL() + '/open_hours/' + this.user.agence.id, this.getHeaders());
                if(response) {
                    this.openHours = response.data;
                }
            }
        }
    }
</script>
<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>