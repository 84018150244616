<template>
    <div class="bg-login fill-height">
      <v-container>
        <v-row justify="center" class="mt-16">
          <v-col cols="3" md="5" sm="8" class="text-center white rounded-lg" style="position:relative;">
            <h1>Mot de passe oublié</h1>
            <v-row justify="center">
              <v-col cols="6" md="8" sm="10" >
                <form>
                  <v-text-field
                      v-model="email"
                      label="Adresse Email"
                      required
                  ></v-text-field>
                  <v-btn class="mb-6 mt-2" color="#9CCAE2" @click="resetPassword">
                    Envoyer
                  </v-btn><br>
                  <v-btn class="mb-6 mt-2" color="primary" to="/login">
                    Retour
                  </v-btn>
                </form>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <Snackbar color="success" title="Un email vous à été envoyé !" v-on:close="success = false" :model="success"></Snackbar>
      <Snackbar color="error" title="Une erreure s'est produite !" v-on:close="error = false" :model="error"></Snackbar>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import Snackbar from "@/components/partials/Snackbar";
  
  export default {
    name: 'ForgotPasswordView',
    data() {
      return {
        success: false,
        error: false,
        email: null,
      }
    },
    components: {
      Snackbar
    },
    created() {
      document.title = 'Mot de passe oublié | EFM';
    },
    methods: {
      resetPassword: async function () {
        try {
          const response = await axios.post(this.baseApiURL() + '/user/forgotPassword', {
            email: this.email
          },this.getHeaders());
          if(response.data.success) {
            this.success = true;
          } else {
            this.error = true;
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  }
  </script>
  <style>
  .bg-login {
    /* background-image: linear-gradient(rgba(19, 147, 216, 0.3), rgba(19, 147, 216, 0.6)), url("../assets/ecole_des_peches.jpg"); */
    background-size: cover;
  }
  </style>
  