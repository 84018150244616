<template>
    <v-row class="mt-3">
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Nouvelle Reprise
                    
                </v-card-title>
                <v-card style="box-shadow: none;">
                    <v-form>
                        <v-row class="pl-6">
                            <v-col cols="12" md="12">
                                <div class="text-h6 ">Souhaitez-vous lier le formulaire de reprise à une annonce existante ?</div>
                                    <v-radio-group
                                        v-model="reprise_type"
                                        row
                                    >
                                    <v-radio
                                        label="Lier à une annonce existante"
                                        value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Créer un nouveau formulaire"
                                        value="0"
                                    ></v-radio>
                                </v-radio-group>
                                
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-select
                                v-if="reprise_type == 1"
                                label="Sélectionnez une annonce"
                                v-model="annonce"
                                :items="['Annonce 1', 'Annonce 2']"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-stepper
                            v-model="e1"
                            vertical
                            style="box-shadow: none;"
                        >
                            <v-stepper-step
                            :complete="e1 > 1"
                            step="1"
                            >
                            PROPRIÉTAIRE / CO-PROPRIÉTAIRE
                            </v-stepper-step>

                            <v-stepper-content step="1">
                                <v-card class="overflow-hidden">
                                    <v-row>
                                        <v-col cols="12" md="8">
                                            <div class="text-h6">Propriétaire</div>
                                            <v-row v-if="new_customer == false">
                                                <v-col cols="6" md="6">
                                                    <v-select
                                                    label="Sélectionnez un client"
                                                    v-model="customer"
                                                    :items="customers"
                                                    item-value="id"
                                                    item-text="label"
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-btn
                                                        class="mr-2"
                                                        color="primary"
                                                        @click="new_customer = true"
                                                        >
                                                        Nouveau
                                                    </v-btn>
                                                </v-col>
                                            </v-row>

                                            <v-row v-if="new_customer == true">
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_firstname"
                                                        label="Prénom"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_lastname"
                                                        label="Nom"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_email"
                                                        label="Adresse email"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_phone"
                                                        label="Téléphone"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            
                                            <v-radio-group
                                                v-model="c_type"
                                                column
                                            >
                                                <v-row>
                                                    <v-col cols="6" md="6">
                                                    <v-radio
                                                        label="Personne physique"
                                                        value="physique"
                                                    ></v-radio>
                                                    </v-col>
                                                    <v-col cols="6" md="6">
                                                        <v-radio
                                                            label="Personne moral"
                                                            value="moral"
                                                        ></v-radio>
                                                    </v-col>
                                                </v-row>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <div class="text-h6">Co-Propriétaire</div>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-text-field
                                                        v-model="co_c_firstname"
                                                        label="Prénom"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="12">
                                                    <v-text-field
                                                        v-model="co_c_lastname"
                                                        label="Nom"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        class="primary float-right"
                                        @click="e1 = e1 + 1"
                                    >
                                        Continuer
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>

                            <v-stepper-step
                            :complete="e1 > 2"
                            step="2"
                            >
                            CARACTÉRISTIQUES DU VÉHICULE
                            </v-stepper-step>

                            <v-stepper-content step="2">
                                <v-card class="overflow-hidden">
                                    <v-row>
                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_marque"
                                                label="Marque du véhicule"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_modele"
                                                label="Modèle du véhicule"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                            v-model="v_finition"
                                                label="Finition"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_transmission"
                                                label="Transmission"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_date_first"
                                                label="1ère mise en circulation"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_immat"
                                                label="N° Immatriculation"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_carroserie"
                                                label="État Carrosserie"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-select
                                            label="Genre véhicule"
                                            v-model="v_genre"
                                            :items="v_genre_list"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-select
                                            label="Energie"
                                            v-model="v_energie"
                                            :items="['Essence', 'Diesel']"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_nb_portes"
                                                label="Nombre de portes"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_nb_km"
                                                label="Km au compteur"
                                                suffix="Km"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_puissance"
                                                label="Puissance véhicule"
                                                suffix="CV"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-select
                                            label="Boite de vitesse"
                                            v-model="v_boite_vitesse"
                                            :items="['Automatique', 'Manuelle']"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_moteur"
                                                label="Cylindrée moteur"
                                            ></v-text-field>
                                        </v-col>

                                        <!-- <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_version"
                                                label="Version"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_nb_portes"
                                                label="Nombre de portes"
                                            ></v-text-field>
                                        </v-col> -->
                                        
                                    </v-row>

                                    <v-btn
                                        class="float-right"
                                        @click="e1 = e1 - 1"
                                    >
                                        Précédent
                                    </v-btn>
                                    <v-btn
                                        class="primary float-right mr-3"
                                        @click="e1 = e1 + 1"
                                    >
                                        Continuer
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>

                            <v-stepper-step
                            :complete="e1 > 3"
                            step="3"
                            >
                            ÉTAT DU VÉHICULE
                            </v-stepper-step>

                            <v-stepper-content step="3">
                                <v-card class="overflow-hidden">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-textarea
                                                v-model="v_observations"
                                                filled
                                                label="Observations"
                                                auto-grow
                                            ></v-textarea>
                                            <v-text-field
                                                v-model="v_repa_price"
                                                label="Coût total de remiseen état estimé"
                                                suffix="€ TTC"
                                            ></v-text-field>
                                            <v-file-input
                                                multiple
                                                counter
                                                small-chips
                                                v-model="v_photos"
                                                label="Ajouter des photos"
                                                aria-disabled="true"
                                                disabled
                                            ></v-file-input>
                                            <v-alert outlined
                                                    type="warning"
                                                    border="left">
                                                    Veuillez d'abord enregistrer le formulaire pour pouvoir ajouter des photos
                                            </v-alert>
                                            <v-btn
                                                color="primary"
                                                @click.stop="onUpload"
                                                aria-disabled="true"
                                                disabled
                                            >UPLOAD</v-btn>
                                        </v-col>
                                        <v-col cols="12" md="6" class="bloc_repas">
                                            <v-checkbox
                                                v-for="item in v_repas_list" :key="item.label" :value="item.value"
                                                v-model="v_repas"
                                                class="d-inline-block mt-0 ml-2 mr-2"
                                            >
                                                <template v-slot:label >
                                                    {{ item.label }}
                                                </template>
                                            </v-checkbox>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        class="float-right"
                                        @click="e1 = e1 - 1"
                                    >
                                        Précédent
                                    </v-btn>
                                    <v-btn
                                        class="primary float-right mr-3"
                                        @click="e1 = e1 + 1"
                                    >
                                        Continuer
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>

                            <v-stepper-step
                            :complete="e1 > 4"
                            step="4"
                            >
                            MONTANT TOTAL DE RACHAT
                            </v-stepper-step>

                            <v-stepper-content step="4">
                                <v-card class="overflow-hidden">
                                    <v-row>
                                        <v-col cols="6" md="3">
                                            <div class="text-h6">Si TVA sur marge</div>
                                            <v-text-field
                                                v-model="v_price_marge"
                                                label="Si TVA sur marge"
                                                suffix="€ TTC"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <div class="text-h6">Si TVA récupérable</div>
                                            <v-text-field
                                                v-model="v_price_tva"
                                                label="Si TVA récupérable"
                                                suffix="€ HT"
                                            ></v-text-field>

                                            <div class="text-h6 text-right" style="font-size:1rem;color:grey;">{{ parseInt(v_price_tva/100*20)+parseInt(v_price_tva) }} <span>€ TTC</span></div>
                                            <div class="text-h6 text-right" style="font-size:1rem;color:grey;">{{ v_price_tva/100*20 }} <span>€ TVA 20%</span></div>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        class="float-right"
                                        @click="e1 = e1 - 1"
                                    >
                                        Précédent
                                    </v-btn>
                                    <v-btn
                                        class="primary float-right mr-3"
                                        @click="addReprise()"
                                    >
                                        Terminer
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>
                        
                        </v-stepper>
                    </v-form>
                </v-card>
            </v-card>
        </v-col>
    </v-row>
</template>

<style>
    .bloc_repas .v-input--selection-controls__input {
        display: none;
    }
    .bloc_repas .v-input:not(.v-text-field) .v-input__slot {
        margin: 0;
        padding: 15px;
        border-radius: 5px;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
        transition-duration: .2;
    }
    .bloc_repas .v-input:not(.v-text-field) .v-input__slot:hover {
        margin: 0;
        padding: 15px;
        border-radius: 5px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .bloc_repas .v-input--is-label-active:not(.v-text-field) .v-input__slot {
        background: rgb(2, 125, 176);
        color: #fff;
    }
    .bloc_repas .v-input--is-label-active:not(.v-text-field) .v-input__slot label {
        color: #fff;
    }
    .select_color .v-input--radio-group__input .v-item--active > label > div {
        border: 2px solid #000;
    }
    .v-input.v-select {
        flex: 0 1 auto;
    }
</style>

<script>
    import axios from "axios";

    export default {
        name: 'RepriseAddView',

        data() {
            return {
                e1: 1,

                customers: [],
                new_customer: false,

                reprise_type: '',
                annonce: '',
                customer: '',
                c_firstname: '',
                c_lastname: '',
                c_email: '',
                c_phone: '',
                c_type: '',

                co_c_firstname: '',
                co_c_lastname: '',
                
                //Data véhicule
                v_marque: '',
                v_modele: '',
                v_finition: '',
                v_transmission: '',
                v_date_first: '',
                v_immat: '',
                v_carroserie: '',
                v_genre_list: [
                    "Cabriolet",
                    "Coupé",
                    "Break",
                    "Berline",
                    "SUV",
                    "Pick Up",
                    "Utilitaire",
                    "Citadine",
                ],
                v_genre: '',
                v_energie: '',
                v_nb_portes: '',
                v_nb_km: '',
                v_puissance: '',
                v_boite_vitesse: '',
                v_moteur: '',

                // v_version: '',
                v_observations: '',
                v_repas: [],
                v_photos: [],
                v_repa_price: 0,
                v_repas_list: [
                    {
                        label: "Courroie de distribution",
                        value: "Courroie de distribution"
                    },
                    {
                        label: "Vidange",
                        value: "Vidange"
                    },
                    {
                        label: "2 pneus",
                        value: "2 pneus"
                    },
                    {
                        label: "4 pneus",
                        value: "4 pneus"
                    },
                    {
                        label: "Freins plaquettes avant",
                        value: "Freins plaquettes avant"
                    },
                    {
                        label: "Freins étriers avant",
                        value: "Freins étriers avant"
                    },
                    {
                        label: "Freins disque avant",
                        value: "Freins disque avant"
                    },
                    {
                        label: "Freins plaquettes arrière",
                        value: "Freins plaquettes arrière"
                    },
                    {
                        label: "Freins étriers arrière",
                        value: "Freins étriers arrière"
                    },
                    {
                        label: "Freins disque arrière",
                        value: "Freins disque arrière"
                    }
                    ,
                    {
                        label: "Embrayange",
                        value: "Embrayange"
                    },
                    {
                        label: "Boite de vitesse",
                        value: "Boite de vitesse"
                    }
                ],

                v_price_marge: 0,
                v_price_tva: 0

            }
        },
        async created() {
            await this.getCustomers();
        },
        methods: {
            getCustomers: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/customers', this.getHeaders());
                    if(response) {
                        this.customers = response.data;
                        for(const customer in this.customers){
                            this.customers[customer].label = this.customers[customer].firstname + " " + this.customers[customer].lastname + " (" + this.customers[customer].email + ")";
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },

            addVehicule: async function () {
                try {
                    const response = await axios.post(this.baseApiURL() + '/vehicule', {
                        marque: this.v_marque,
                        modele: this.v_modele,
                        finition: this.v_finition,
                        transmission: this.v_transmission,
                        date_first: this.v_date_first,
                        immat: this.v_immat,
                        carrosserie: this.v_carroserie,
                        genre: this.v_genre,
                        energie: this.v_energie,
                        nb_porte: this.v_nb_portes,
                        km: this.v_nb_km,
                        puissance: this.v_puissance,
                        boite_vitesse: this.v_boite_vitesse,
                        moteur: this.v_moteur,
                        
                    }, this.getHeaders());
                    if (response) {
                        //console.log(response.data.id);
                        return response.data.id;
            
                    }
                } catch (e) {
                    console.error(e);
                }
            },

            addCustomer: async function () {
                try {

                    this.success = false;
                    const response = await axios.post(this.baseApiURL() + '/customer', {
                        firstname: this.c_firstname,
                        lastname: this.c_lastname,
                        address: '',
                        cp: '',
                        city: '',
                        email: this.c_email,
                        phone: this.c_phone,
                        note: '',
                        
                    }, this.getHeaders());
                    if (response) {
                        return response.data.id;
                    }
                } catch (e) {
                    console.error(e);
                }
            },

            addReprise: async function () {
                try {
                    var customer_id = '';
                    if(this.new_customer){
                        customer_id = await this.addCustomer();
                    }else{
                        customer_id = this.customer;
                    }

                    const vehicule_id = await this.addVehicule();

                    //console.log(JSON.parse(JSON.stringify(this.v_repas)));

                    if(vehicule_id && customer_id) {

                        const response = await axios.post(this.baseApiURL() + '/reprise', {
                            user: this.user.id,
                            customer: customer_id,
                            vehicule: vehicule_id,
                            co_firstname: this.co_c_firstname,
                            co_lastname: this.co_c_lastname,
                            type: this.c_type,
                            observations: this.v_observations,
                            repa_list: JSON.stringify(this.v_repas),
                            photos: '',
                            price_repa: this.v_repa_price,
                            price_marge: this.v_price_marge,
                            price_tva: this.v_price_tva,
                            status: 1,
                            
                        }, this.getHeaders());
                        if (response) {
                            //console.log(response.data);
                            this.$router.push({ path: 'annonces/?isCreate=1'});
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            onUpload: async function () {
                const formdata = new FormData(undefined);
                formdata.append('photo',this.v_photos[0]);

                console.log(formdata);
                //return await axios.post(this.baseApiURL() + '/reprise/:reprise/photo/:name', formdata, this.getHeaders());
            }
        }
    }
</script>