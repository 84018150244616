<template>
    <v-snackbar
        v-model="currentSnackbar"
        absolute
        timeout="-1"
        top
        outlined
        :color="color"
    >
        {{ title }}
        <template v-slot:action="{ attrs }">
            <v-icon v-bind="attrs" @click="$emit('close')">mdi-close-circle</v-icon>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        name: 'SnackBar',
        props: ['model', 'color' , 'title'],
        data() {
            return {
                currentSnackbar: false,
            }
        },
        watch: {
        model(val) {
            // Be sure to validate default values
                if(val !== '') {
                    this.currentSnackbar = val
                }
            }
        },
        created() {
            this.currentSnackbar = this.model;
        },
    }
</script>
  