<template>
    <v-row class="mt-3">
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Ajouter Horaires d'ouverture
                </v-card-title>
                <v-form class="pa-3">
                    <v-row>
                        <v-col md="6">
                            <p class="text-h5">Jours</p>
                            <v-checkbox
                                class="d-inline-block mr-2"
                                v-model="days"
                                label="Lundi"
                                color="blue"
                                value="1"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                class="d-inline-block mr-2"
                                v-model="days"
                                label="Mardi"
                                color="blue"
                                value="2"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                class="d-inline-block mr-2"
                                v-model="days"
                                label="Mercredi"
                                color="blue"
                                value="3"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                class="d-inline-block mr-2"
                                v-model="days"
                                label="Jeudi"
                                color="blue"
                                value="4"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                class="d-inline-block mr-2"
                                v-model="days"
                                label="Vendredi"
                                color="blue"
                                value="5"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                class="d-inline-block mr-2"
                                v-model="days"
                                label="Samedi"
                                color="blue"
                                value="6"
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                class="d-inline-block mr-2"
                                v-model="days"
                                label="Dimanche"
                                color="blue"
                                value="0"
                                hide-details
                            ></v-checkbox>
                        </v-col>

                        <v-col md="6">
                            <v-row>
                                <v-col md="6">
                                    <v-menu
                                        ref="menu"
                                        v-model="menuDebut"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="heure_debut"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="heure_debut"
                                            label="Heure de début :"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-time-picker
                                        v-if="menuDebut"
                                        v-model="heure_debut"
                                        :max="heure_fin"
                                        format="24hr"
                                        full-width
                                        @click:minute="$refs.menu.save(heure_debut)"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                                <v-col md="6">
                                    <v-menu
                                        ref="menu1"
                                        v-model="menuFin"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="heure_fin"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="heure_fin"
                                            label="Heure de fin :"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-time-picker
                                        v-if="menuFin"
                                        v-model="heure_fin"
                                        :min="heure_debut"
                                        format="24hr"
                                        full-width
                                        @click:minute="$refs.menu1.save(heure_fin)"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                                <v-col md="12">
                                    <v-checkbox
                                        v-model="coupure"
                                        label="Coupure dans la journée ?"
                                        color="blue"
                                        value="0"
                                        hide-details
                                    ></v-checkbox>
                                </v-col>
                                <v-col md="6" v-if="coupure">
                                    <v-menu
                                        ref="menu2"
                                        v-model="menuDebut2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="heure_debut2"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="heure_debut2"
                                            label="Heure de début :"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-time-picker
                                        v-if="menuDebut2"
                                        v-model="heure_debut2"
                                        :max="heure_fin2"
                                        format="24hr"
                                        full-width
                                        @click:minute="$refs.menu2.save(heure_debut2)"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                                <v-col md="6" v-if="coupure">
                                    <v-menu
                                        ref="menu3"
                                        v-model="menuFin2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="heure_fin2"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="heure_fin2"
                                            label="Heure de fin :"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                        </template>
                                        <v-time-picker
                                        v-if="menuFin2"
                                        v-model="heure_fin2"
                                        :min="heure_debut2"
                                        format="24hr"
                                        full-width
                                        @click:minute="$refs.menu3.save(heure_fin2)"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-col>
                        
                        <v-col md="12">
                            <v-btn class="float-right" color="primary" @click="saveOpenHours">
                                Enregister
                        </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-col>
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Liste Horaires d'ouverture
                </v-card-title>
                <div class="pa-4">
                    <p>
                        Lundi : 
                        <span v-for="(hours, key) in hLun" :key="key"><span v-if="key == 1"> et </span>{{ hours.heure_debut }} à {{ hours.heure_fin }}</span> 
                        <v-btn
                            v-if="hLun.length > 0"
                            class="mx-2"
                            small
                            color="red"
                            style="color:white;"
                            @click="deleteHours(1)"
                        >
                            <v-icon>
                                mdi-trash-can-outline
                            </v-icon>
                        </v-btn>
                        <span v-if="hLun.length <= 0">Fermé (Non renseigné)</span> 
                    </p>
                    <p>
                        Mardi : 
                        <span class="mr-2" v-for="(hours, key) in hMar" :key="key"><span v-if="key == 1"> et </span>{{ hours.heure_debut }} à {{ hours.heure_fin }}</span>
                        <v-btn
                            v-if="hMar.length > 0"
                            class="mx-2"
                            small
                            color="red"
                            style="color:white;"
                            @click="deleteHours(2)"
                        >
                            <v-icon>
                                mdi-trash-can-outline
                            </v-icon>
                        </v-btn>
                        <span v-if="hMar.length <= 0">Fermé (Non renseigné)</span> 
                    </p>
                    <p>
                        Mercredi : 
                        <span class="mr-2" v-for="(hours, key) in hMer" :key="key"><span v-if="key == 1"> et </span>{{ hours.heure_debut }} à {{ hours.heure_fin }}</span>
                        <v-btn
                            v-if="hMer.length > 0"
                            class="mx-2"
                            small
                            color="red"
                            style="color:white;"
                            @click="deleteHours(3)"
                        >
                            <v-icon>
                                mdi-trash-can-outline
                            </v-icon>
                        </v-btn>
                        <span v-if="hMer.length <= 0">Fermé (Non renseigné)</span> 
                    </p>
                    <p>
                        Jeudi : 
                        <span class="mr-2" v-for="(hours, key) in hJeu" :key="key"><span v-if="key == 1"> et </span>{{ hours.heure_debut }} à {{ hours.heure_fin }}</span>
                        <v-btn
                            v-if="hJeu.length > 0"
                            class="mx-2"
                            small
                            color="red"
                            style="color:white;"
                            @click="deleteHours(4)"
                        >
                            <v-icon>
                                mdi-trash-can-outline
                            </v-icon>
                        </v-btn>
                        <span v-if="hJeu.length <= 0">Fermé (Non renseigné)</span> 
                    </p>
                    <p>
                        Vendredi : 
                        <span class="mr-2" v-for="(hours, key) in hVen" :key="key"><span v-if="key == 1"> et </span>{{ hours.heure_debut }} à {{ hours.heure_fin }}</span>
                        <v-btn
                            v-if="hVen.length > 0"
                            class="mx-2"
                            small
                            color="red"
                            style="color:white;"
                            @click="deleteHours(5)"
                        >
                            <v-icon>
                                mdi-trash-can-outline
                            </v-icon>
                        </v-btn>
                        <span v-if="hVen.length <= 0">Fermé (Non renseigné)</span> 
                    </p>
                    <p>
                        Samedi : 
                        <span class="mr-2" v-for="(hours, key) in hSam" :key="key"><span v-if="key == 1"> et </span>{{ hours.heure_debut }} à {{ hours.heure_fin }}</span>
                        <v-btn
                            v-if="hSam.length > 0"
                            class="mx-2"
                            small
                            color="red"
                            style="color:white;"
                            @click="deleteHours(6)"
                        >
                            <v-icon>
                                mdi-trash-can-outline
                            </v-icon>
                        </v-btn>
                        <span v-if="hSam.length <= 0">Fermé (Non renseigné)</span> 
                    </p>
                    <p>
                        Dimanche : 
                        <span class="mr-2" v-for="(hours, key) in hDim" :key="key"><span v-if="key == 1"> et </span>{{ hours.heure_debut }} à {{ hours.heure_fin }}</span>
                        <v-btn
                            v-if="hDim.length > 0"
                            class="mx-2"
                            small
                            color="red"
                            style="color:white;"
                            @click="deleteHours(0)"
                        >
                            <v-icon>
                                mdi-trash-can-outline
                            </v-icon>
                        </v-btn>
                        <span v-if="hDim.length <= 0">Fermé (Non renseigné)</span> 
                    </p>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>
  
<script>
    import axios from "axios";
  
    export default {
        name: 'OpenHoursView',
    
        components: {
            
        },

        data: () => ({
            openHours: [],

            days: [],

            menuDebut: false,
            heure_debut: "",
            menuFin: false,
            heure_fin: "",

            menuDebut2: false,
            heure_debut2: "",
            menuFin2: false,
            heure_fin2: "",

            coupure: false,

            hLun: [],
            hMar: [],
            hMer: [],
            hJeu: [],
            hVen: [],
            hSam: [],
            hDim: [],

            //days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        }),
        async created() {
            this.getOpenHoursByAgenceAndDay();
        },
        methods: {
            // getOpenHoursByAgence: async function (day) {
            //     try {
            //         console.log(day)
            //         const response = await axios.get(this.baseApiURL() + '/open_hours/' + this.user.agence.id, this.getHeaders());
            //         if(response) {
            //             console.log(response.data)
            //             this.openHours = response.data;
                        
            //         }
            //     } catch (e) {
            //         console.error(e);
            //     }
            // },
            getOpenHoursByAgenceAndDay: async function () {
                try {
                    let dataDays = [0,1,2,3,4,5,6];

                    for (let day of dataDays){
                        const response = await axios.get(this.baseApiURL() + '/open_hours/' + this.user.agence.id + '/by_day/' + day, this.getHeaders());

                        switch (day) {
                            case 1:
                                this.hLun = response.data;
                                break;
                            case 2:
                                this.hMar = response.data;
                                break;
                            case 3:
                                this.hMer = response.data;
                                break;
                            case 4:
                                this.hJeu = response.data;
                                break;
                            case 5:
                                this.hVen = response.data;
                                break;
                            case 6:
                                this.hSam = response.data;
                                break;
                            case 0:
                                this.hDim = response.data;
                                break;
                        }
                    }

                    //console.log(this.hLun)
                    
                    // if(response) {
                    //     console.log(response.data)
                    //     return response.data;
                        
                    // }
                } catch (e) {
                    console.error(e);
                }
            },
            saveOpenHours: async function () {
                try {
                    for(let day of this.days){
                        let checkExist = await axios.get(this.baseApiURL() + '/open_hours/' + this.user.agence.id + '/by_day/' + day, this.getHeaders()); 
                        //console.log(checkExist)
                        if(checkExist.data.length > 0) {
                            let deleteHours = await axios.delete(this.baseApiURL() + '/open_hours/' + this.user.agence.id + '/by_day/' + day, this.getHeaders()); 
                            console.log(deleteHours);
                            //continue;
                        }

                        console.log(this.heure_debut + " => " + this.heure_fin)

                        await axios.post(this.baseApiURL() + '/open_hours', {
                        agence: this.user.agence.id,
                            days: day,
                            heure_debut: this.heure_debut,
                            heure_fin: this.heure_fin,
                        }, this.getHeaders());


                        if(this.coupure){
                                await axios.post(this.baseApiURL() + '/open_hours', {
                                agence: this.user.agence.id,
                                days: day,
                                heure_debut: this.heure_debut2,
                                heure_fin: this.heure_fin2,
                            }, this.getHeaders());

                            // if(response2) {
                            //     this.coupure = false;
                            //     this.heure_debut2 = "";
                            //     this.heure_fin2 = "";
                            // }
                        }

                        
                        // if(response) {
                        //     this.days = [];
                        //     this.heure_debut = "";
                        //     this.heure_fin = "";
                        // }
                        


                        
                    }

                    this.days = [];
                    this.heure_debut = "";
                    this.heure_fin = "";
                    this.coupure = false;
                    this.heure_debut2 = "";
                    this.heure_fin2 = "";
                    await this.getOpenHoursByAgenceAndDay();
                    
                } catch (e) {
                    console.error(e);
                }
            },
            deleteHours: async function (day) {
                try {
                    await axios.delete(this.baseApiURL() + '/open_hours/' + this.user.agence.id + '/by_day/' + day, this.getHeaders()); 
                    await this.getOpenHoursByAgenceAndDay();
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }
</script>