<template>
    <v-row class="mt-3">
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Liste des Ventes
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>

                <v-data-table 
                    :headers="headersReprise"
                    :items="reprises"
                    :search="search"
                >
                    <template v-slot:[`item.typeLine`]="{ item }">
                        <v-chip v-if="item.r_type === 0" color="light-green lighten-1">{{item.typeLine}}</v-chip>
                        <v-chip v-if="item.r_type === 1" color="light-blue lighten-1">{{item.typeLine}}</v-chip>
                    </template>

                    <template v-slot:[`item.custLine`]="{ item }">
                        <div><a :href="'/customers/?idCustomer=' + item.customer.id">{{item.custLine}}</a></div>
                    </template>

                    <template v-slot:[`item.userLine`]="{ item }">
                        <div>{{item.userLine}}</div>
                    </template>

                    <template v-slot:[`item.vehiculeLine`]="{ item }">
                        <div>{{item.vehiculeLine}}</div>
                    </template>

                    <template v-slot:[`item.price_marge`]="{ item }">
                        <div>{{item.price_marge}} €</div>
                    </template>

                    <template v-slot:[`item.price_tva`]="{ item }">
                        <div>{{item.price_tva}} €</div>
                    </template>

                    <template v-slot:[`item.status.label`]="{ item }">
                        <v-chip v-if="item.status.id === 1" color="grey-darken-1">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 2" color="warning">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 3" color="success">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 4" color="error">{{item.status.label}}</v-chip>
                    </template>

                    <!-- <template v-slot:[`item.repa_list`]="{ item }">
                        
                        <v-chip x-small v-for="repa in JSON.parse(item.repa_list)" :key="repa">{{ repa }}</v-chip>
                    </template> -->

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom v-if="!item.url_fiche">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="orange" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeReprise = item;generatePDF();">
                                    <v-icon>
                                    mdi-file-pdf-box
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Générer Pdf</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="item.url_fiche">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="orange" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeReprise = item;getPDF();">
                                    <v-icon>
                                    mdi-download
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Télécharger Pdf</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="blue" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" :to="{ name: 'ReprisesEditView', params: { id: item.id }}">
                                    <v-icon>
                                    mdi-pencil
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Editer</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="red" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeReprise = item;deleteRepriseDialog = true">
                                    <v-icon>
                                    mdi-trash-can-outline
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Supprimer</span>
                        </v-tooltip>

                    </template>
            
                </v-data-table>
                <SnackBar color="success" title="La fiche de reprise à été généré au format PDF" v-on:close="snackbarCreatePdf = false" :model="snackbarCreatePdf"></SnackBar>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import axios from "axios";
    import SnackBar from "@/components/partials/Snackbar";
    //import puppeteer from "puppeteer";

    export default {
        name: 'VentesView',

        components: {
            SnackBar,
        },

        data: () => ({
            
            search: null,

            snackbarCreatePdf: false,

            reprises: [],
            activeReprise: [],

            headersReprise: [
                { text: 'N°', value: 'reference'},
                { text: 'Type vente', value: 'typeLine'},
                { text: 'Client', value: 'custLine'},
                { text: 'Utilisateur', value: 'userLine' },
                { text: 'Véhicule', value: 'vehiculeLine' },
                { text: 'Prix TVA sur marge', value: 'price_marge' },
                { text: 'Prix TVA récupérable', value: 'price_tva' },
                // { text: 'Liste Réparations', value: 'repa_list' },
                // { text: 'Role', value: 'role.label' },
                { text: 'Status', value: 'status.label' },
                { text: 'Actions', value: 'actions' },
            ],
        }),
        async created() {
            await this.getReprises();
           
        },
        methods: {
            getReprises: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/reprises_vendus', this.getHeaders());
                    if(response) {
                        
                        for(let reprise of response.data) {
                            let agence_id = reprise.user.agence;
                            const aResponse = await axios.get(this.baseApiURL() + '/agence/' + agence_id, this.getHeaders());
                            //console.log(aResponse.data);
                            reprise.typeLine = reprise.r_type == 0 ? "Vente direct" : "Dépôt / Vente";
                            reprise.user.agence = aResponse.data.label;
                            reprise.custLine = reprise.customer.firstname + " " + reprise.customer.lastname;
                            reprise.userLine = reprise.user.firstname + " " + reprise.user.lastname + '(' + aResponse.data.label + ')';
                            reprise.vehiculeLine = reprise.vehicule.marque + " " + reprise.vehicule.modele;
                        }
                        console.log(this.reprises)
                        this.reprises = response.data;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getPDF: async function () {
                // const response = await axios.get(this.baseApiURL() + '/reprise/pdf/' + this.activeReprise.id, this.getHeaders());
                // if(response){
                //     console.log(response)
                // }

                const response = await axios.get(this.baseApiURL() + '/reprise/pdf/' + this.activeReprise.id, {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf'
                    }
                });
                let fileURL = window.URL.createObjectURL(new Blob([response.data], {
                type: 'application/pdf'
                }));
                
                window.open(fileURL, '_blank');
                
            },
            generatePDF: async function () {
                if(await axios.put(this.baseApiURL() + '/reprise/pdf/generate/' + this.activeReprise.id, {},this.getHeaders())) {
                    this.snackbarCreatePdf = true;
                    await this.getReprises();
                }
                
            }
        }
    }
</script>
  