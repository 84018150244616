<template>
  <!-- <hello-world /> -->
  <v-row class="mt-3" v-if="user.role.id == 1">
    <v-col md="12">
      <p class="text-h6">Tableau de bord</p>
    </v-col>
    <v-col md="3">
      <v-card class="pa-3" style="height: 100%;">
       <div class="text-h5 text-uppercase font-weight-bold mb-4">Nombre d'annonce déposées</div> 
       <div class="text-h4 font-weight-bold">{{ countAnnonces }}</div> 
      </v-card>
    </v-col>

    <v-col md="3">
      <v-card class="pa-3" style="height: 100%;">
       <div class="text-h5 text-uppercase font-weight-bold mb-4">Annonces en cours d'enchère</div> 
       <div class="text-h4 font-weight-bold">{{ countBid }}</div> 
      </v-card>
    </v-col>

    <v-col md="3">
      <v-card class="pa-3" style="height: 100%;">
       <div class="text-h5 text-uppercase font-weight-bold mb-4">Nombre de comptes Franchisés</div> 
       <div class="text-h4 font-weight-bold">{{ countUsers }}</div> 
      </v-card>
    </v-col>

    <v-col md="3">
      <v-card class="pa-3" style="height: 100%;">
       <div class="text-h5 text-uppercase font-weight-bold mb-4">Dernières factures</div> 
       <a class="d-block" v-for=" fact of lastFactures" :key="fact.id" @click="getPDF(fact.id)">{{ fact.reference }} ({{ fact.agence.label }})</a>
      </v-card>
    </v-col>

    <v-col md="12">
      <v-card class="pa-3">
        <v-row>
          <v-col md="4">
            <v-select
                v-model="agence"
                :items="agences"
                item-text="label"
                item-value="id"
                dense
                outlined
                hide-details
                class="ma-2"
                label="Agences"
                @change="allAnnonceByMonthInDate();"
            ></v-select>
          </v-col>
          <v-col md="4">
            <v-select
                v-model="echelle"
                :items="echelles"
                item-text="label"
                item-value="value"
                dense
                outlined
                hide-details
                class="ma-2"
                label="Echelle"
                @change="allAnnonceByMonthInDate();"
            ></v-select>
          </v-col>
          <v-col md="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedDatefns"
                  label="Sélectionnez les dates"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                range
                v-model="date"
                no-title
                scrollable
                @change="allAnnonceByMonthInDate();"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
       

        <div class="text-h5 text-uppercase font-weight-bold mb-4">Annonces par mois</div> 
        <LineChartGenerator
          v-if="this.chartData.datasets[0].data.length > 0"
          ref="line"
          id="my-chart-id"
          :options="chartOptions"
          :data="chartData"
        />
      </v-card>
    </v-col>
  </v-row>

  <v-row class="mt-3" v-else>
    <v-col md="12">
      <p class="text-h6">Tableau de bord</p>
    </v-col>
    <v-col md="3">
      <v-card class="pa-3" style="height: 100%;">
       <div class="text-h5 text-uppercase font-weight-bold mb-4">Nombre d'annonce déposées</div> 
       <div class="text-h4 font-weight-bold">{{ countAnnonces }}</div> 
      </v-card>
    </v-col>

    <v-col md="3">
      <v-card class="pa-3" style="height: 100%;">
       <div class="text-h5 text-uppercase font-weight-bold mb-4">Annonces en cours d'enchère</div> 
       <div class="text-h4 font-weight-bold">{{ countBid }}</div> 
      </v-card>
    </v-col>

    <v-col md="3">
      <v-card class="pa-3" style="height: 100%;">
       <div class="text-h5 text-uppercase font-weight-bold mb-4">Nombre de reprises en cours</div> 
       <div class="text-h4 font-weight-bold">{{ countReprises }}</div> 
      </v-card>
    </v-col>

    <v-col md="3">
      <v-card class="pa-3" style="height: 100%;">
       <div class="text-h5 text-uppercase font-weight-bold mb-4">Dernières factures</div> 
       <a class="d-block" v-for=" fact of lastFacturesUser" :key="fact.id" @click="getPDF(fact.id)">{{ fact.reference }} ({{ fact.agence.label }})</a>
      </v-card>
    </v-col>

    <v-col md="12">
      <v-card class="pa-3">
        <v-row>
          <v-col md="4">
            <v-select
                v-model="echelle"
                :items="echelles"
                item-text="label"
                item-value="value"
                dense
                outlined
                hide-details
                class="ma-2"
                label="Echelle"
                @change="allAnnonceByMonthInDate();"
            ></v-select>
          </v-col>
          <v-col md="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedDatefns"
                  label="Sélectionnez les dates"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                range
                v-model="date"
                no-title
                scrollable
                @change="allAnnonceByMonthInDate();"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
       

        <div class="text-h5 text-uppercase font-weight-bold mb-4">Annonces par mois</div> 
        <LineChartGenerator
          v-if="this.chartData.datasets[0].data.length > 0"
          ref="line"
          id="my-chart-id"
          :options="chartOptions"
          :data="chartData"
        />
      </v-card>
    </v-col>
  </v-row>

  
</template>

<script>
  //import HelloWorld from '../components/HelloWorld'
  import axios from "axios";
  import { Line as LineChartGenerator } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'

  ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

  export default {
    name: 'HomeView',

    components: {
      LineChartGenerator
      //HelloWorld,
    },
    data: () => ({
      menu: false,
      date: null,
      agences: [],
      agence: 0,
      echelle: 'month',
      echelles: [
          {
              label: 'Mois', 
              value: 'month', 
          },
          {
              label: 'Jour',
              value: 'day',
          }
      ],
      type: 'annonces',
      types: [
          {
              label: 'Annonces', 
              value: 'annonces', 
          },
          {
              label: 'RDV',
              value: 'rdv',
          },
          {
              label: 'Offres',
              value: 'offres',
          }
      ],

      countAnnonces: 0,
      countBid: 0,
      countUsers: 0,
      countReprises: 0,
      chartData: {
        labels: [ 'Jan.', 'Fev.', 'Mar.', 'Avr.', 'Mai', 'Juin', 'Jui.', 'Aout', 'Sep.', 'Oct.', 'Nov.', 'Dec.' ],
        datasets: [ { 
          label: 'Nouvelles annonces',
          borderColor: '#187FBB',
          backgroundColor: '#187FBB',
          data: [] 
        } ]
      },
      chartOptions: {
        responsive: true
      },
      lastFactures: [],
      lastFacturesUser: [],
    }),
    mounted() {
      // this.allAnnonceByMonth();
    },
    computed: {
      chartDataComputed() {
        //console.log("test")
        return this.chartData
      },
      computedDateFormattedDatefns () {
        if(this.date && this.date.length == 2){
          var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
          
          return new Date(this.date[0]).toLocaleDateString("fr-FR", options) + " - " + new Date(this.date[1]).toLocaleDateString("fr-FR", options);
        }else{
          return null;
        }
        
        //return this.date ? format(parseISO(this.date), 'EEEE, MMMM do yyyy') : ''
      },
    },
    async created() {
      await this.allAnnonceCount();
      // await this.allAnnonceByMonth();
      
      await this.allUsersCount();
      await this.allBidCount();
      await this.getAgences();
      await this.allRepriseCount();
      await this.setDefaultDate();
      await this.allAnnonceByMonthInDate();
      await this.getLastFactures();
      await this.getLastFacturesUser();
      //console.log(ChartJS)
    },
    methods: {
      getLastFactures: async function () {
        const response = await axios.get(this.baseApiURL() + '/lasts_factures', this.getHeaders());
        if(response) {
      
          this.lastFactures = response.data
          // console.log(response.data)
        }
      },
      getLastFacturesUser: async function () {
        console.log(this.user)
        const response = await axios.get(this.baseApiURL() + '/lasts_factures_user/' + this.user.agence.id, this.getHeaders());
        if(response) {
      
          this.lastFacturesUser = response.data
          console.log(response.data)
        }
      },
      getPDF: async function (id) {
          const response = await axios.get(this.baseApiURL() + '/facture/pdf/' + id, {
              responseType: 'blob',
              headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/pdf'
              }
          });
          let fileURL = window.URL.createObjectURL(new Blob([response.data], {
          type: 'application/pdf'
          }));
          
          window.open(fileURL, '_blank');
          
      },
      getAgences: async function () {
        const response = await axios.get(this.baseApiURL() + '/agences', this.getHeaders());
        if(response) {
          this.agences = response.data;
          this.agences.unshift({id:0,label:'Toutes'})
        }
      },
      setDefaultDate: async function () {
        const start = new Date();
        let year = start.getFullYear();
        this.date = [year + '-01-01', year + '-12-31'];
      },
      allAnnonceCount: async function () {
        const response = await axios.get(this.baseApiURL() + '/annonces', this.getHeaders());
        if(response) {
          if(this.user.role.id == 1){
            this.countAnnonces = response.data.length
          }else{
              for(let annonce of response.data){
                if(annonce.agence && (annonce.agence.id == this.user.agence.id)){
                  this.countAnnonces++;
                }
              }
          }
          
        }
      },
      allAnnonceByMonthInDate: async function () {
        //console.log(this.date[0])
        const response = await axios.post(this.baseApiURL() + '/statistic/allByMonth/' + this.user.id, {
          dateBegin:  this.date[0],
          dateEnd:  this.date[1],
          agence: this.agence,
          type: this.type,
          echelle: this.echelle
        }, this.getHeaders());
        if(response) {
          console.log(response.data)
          this.chartData = {
            labels: response.data.labels[0],
            datasets: [ { 
              label: 'Nouvelles annonces',
              borderColor: '#187FBB',
              backgroundColor: '#187FBB',
              data: response.data.res[0]
            },{ 
              label: 'RDV',
              borderColor: '#ffd800',
              backgroundColor: '#ffd800',
              data: response.data.res[1]
            },{ 
              label: 'Vente',
              borderColor: '#3c9b06',
              backgroundColor: '#3c9b06',
              data: response.data.res[2]
            },{ 
              label: 'Demande de rappel',
              borderColor: '#f78204',
              backgroundColor: '#f78204',
              data: response.data.res[3]
            },{ 
              label: 'Offres faites',
              borderColor: '#f43804',
              backgroundColor: '#f43804',
              data: response.data.res[4]
            } ]
          }
        }
      },
      allAnnonceByMonth: async function () {
        const response = await axios.get(this.baseApiURL() + '/annonces/allByMonth/' + this.user.id, this.getHeaders());
        if(response) {
          // console.log('test')
          for(let data of response.data){
            this.chartData.datasets[0].data.push(data)
            // if(this.user.role.id == 1){
            //   this.chartData.datasets[0].data.push(data)
            // }else{
            //   if(data.agence.id == this.user.agence.id){
            //     this.chartData.datasets[0].data.push(data)
            //   }
            // }
          }
        }
      },
      allBidCount: async function () {
        const response = await axios.get(this.baseApiURL() + '/annonces/isBeginBid', this.getHeaders());
        if(response) {
          if(this.user.role.id == 1){
            this.countBid = response.data.length
          }else{
              for(let annonce of response.data){
                if(annonce.agence.id == this.user.agence.id){
                  this.countBid++;
                }
              }
          }
          
        }
      },
      allUsersCount: async function () {
        const response = await axios.get(this.baseApiURL() + '/users', this.getHeaders());
        if(response) {
          this.countUsers = response.data.length
        }
      },
      allRepriseCount: async function () {
        const response = await axios.get(this.baseApiURL() + '/reprises', this.getHeaders());
        if(response) {
          for(let reprise of response.data){
            if(reprise.agence.id == this.user.agence.id){
              this.countReprises++;
            }
          }
        }
      },
    }
  }
</script>
