<template>
    <v-row class="mt-3">
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Paramètres généraux de la plateforme
                </v-card-title>



                <v-tabs
                v-model="tab"
                align-tabs="center"
                color="blue"
                >
                    <v-tab :value="1" :key="1">Facturations (ventes)</v-tab>
                    <v-tab :value="2" :key="2">Facturations (dépot / vente)</v-tab>
                    <v-tab :value="3" :key="3">Facturations mensuelles</v-tab>
                    <!-- <v-tab :value="4" :key="4">Gestion RDV</v-tab> -->
                    <v-tab :value="4" :key="4">Notifications</v-tab>
                    <v-tab :value="5" :key="5">Formulaires Reprises / Annonces</v-tab>
                    <v-tab :value="6" :key="6">Enchères</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item
                        :value="0"
                    >
                        <v-container fluid>
                            <p class="text-h6">Gestion des facturations des ventes</p>
                            
                            <v-divider class="mb-4"></v-divider>
                            <v-form>
                                <p class="text-h7 font-weight-bold">Forfait de facturation des ventes directes</p>
                                <v-row
                                    v-for="(line, i) in v_forfait"
                                    :key="i"
                                    class="text-fields-row"
                                >
                                    
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="line.from"
                                            label="De" 
                                            placeholder="Début tranche" 
                                            suffix="€ TTC"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="line.to"
                                            label="A" 
                                            placeholder="Fin tranche" 
                                            suffix="€ TTC"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="line.for"
                                            label="Forfait" 
                                            placeholder="Montant forfait" 
                                            suffix="€ HT"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="3">
                                        <v-btn @click="removeLine(i)" class="error">
                                            <v-icon>
                                            mdi-trash-can-outline
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-btn @click="addLine" class="primary">Ajouter</v-btn>
                                <p class="mt-4 text-h7 font-weight-bold">Commission JVMA pour une vente directe</p>
                                <v-row>
                                    <v-col md="6">
                                        <v-text-field 
                                            v-model="form_data.comm_jvma"
                                            label="Commission JVMA" 
                                            placeholder="Commission JVMA" 
                                            suffix="%"
                                            hint="Poucentage touché par JVMA à chaque vente de véhicule"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="12">
                                        
                                        <v-btn class="float-right" color="primary" @click="saveSetting" :loading="saveLoading" :disabled="saveLoading">
                                            Enregister
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                
                            </v-form>
                        </v-container>
                    </v-tab-item>

                    <v-tab-item
                        :value="1"
                    >
                        <v-container fluid>
                            <p class="text-h6">Gestion des facturations des dépôt / vente</p>
                            <v-divider class="mb-4"></v-divider>
                            <v-form>
                                <v-row>
                                    <v-col md="6">
                                        <v-text-field 
                                            v-model="form_data.dv_forfait"
                                            label="Montant forfait" 
                                            placeholder="Montant forfait" 
                                            suffix="€ HT"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="6">
                                        <v-text-field 
                                            v-model="form_data.dv_comm"
                                            label="% commission" 
                                            placeholder="% commission" 
                                            suffix="%"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="12">
                                        <v-btn class="float-right" color="primary" @click="saveSetting" :loading="saveLoading" :disabled="saveLoading">
                                            Enregister
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-tab-item>

                    <v-tab-item
                        :value="2"
                    >
                        <v-container fluid>
                            <p class="text-h6">Gestion des facturations mensuelles</p>
                            <v-divider class="mb-4"></v-divider>
                            <v-form>
                                <v-row>
                                    <v-col md="12">
                                        <p class="text-h7"><b>Potentiel : Nouveau</b></p>
                                    </v-col>
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="form_data.licence_marque_nouveau"
                                            label="Redevance licence de marque" 
                                            placeholder="Montant" 
                                            suffix="€ HT"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="form_data.price_annonce_nouveau"
                                            label="Prix par annonce" 
                                            placeholder="Montant" 
                                            suffix="€ HT"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="form_data.price_rdv_nouveau"
                                            label="Prix par RDV" 
                                            placeholder="Montant" 
                                            suffix="€ HT"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="form_data.price_tel_nouveau"
                                            label="Prix par Demande de rappel" 
                                            placeholder="Montant" 
                                            suffix="€ HT"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col md="12">
                                        <p class="text-h7"><b>Potentiel : Faible</b></p>
                                    </v-col>
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="form_data.licence_marque_faible"
                                            label="Redevance licence de marque" 
                                            placeholder="Montant" 
                                            suffix="€ HT"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="form_data.price_annonce_faible"
                                            label="Prix par annonce" 
                                            placeholder="Montant" 
                                            suffix="€ HT"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="form_data.price_rdv_faible"
                                            label="Prix par RDV" 
                                            placeholder="Montant" 
                                            suffix="€ HT"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="form_data.price_tel_faible"
                                            label="Prix par Demande de rappel" 
                                            placeholder="Montant" 
                                            suffix="€ HT"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col md="12">
                                        <p class="text-h7"><b>Potentiel : Fort</b></p>
                                    </v-col>
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="form_data.licence_marque_fort"
                                            label="Redevance licence de marque" 
                                            placeholder="Montant" 
                                            suffix="€ HT"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="form_data.price_annonce_fort"
                                            label="Prix par annonce" 
                                            placeholder="Montant" 
                                            suffix="€ HT"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="form_data.price_rdv_fort"
                                            label="Prix par RDV" 
                                            placeholder="Montant" 
                                            suffix="€ HT"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="3">
                                        <v-text-field 
                                            v-model="form_data.price_tel_fort"
                                            label="Prix par Demande de rappel" 
                                            placeholder="Montant" 
                                            suffix="€ HT"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col md="12">
                                        <v-btn class="float-right" color="primary" @click="saveSetting" :loading="saveLoading" :disabled="saveLoading">
                                            Enregister
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-tab-item>

                    <!-- <v-tab-item
                        :value="3"
                    >
                        <v-container fluid>
                            <p class="text-h6">Gestion RDV</p>
                            <v-divider class="mb-4"></v-divider>
                            <v-form>
                                <v-row>
                                    <v-col md="12">
                                        <v-text-field 
                                            v-model="form_data.duration_rdv"
                                            type="number"
                                            label="Durée d'un RDV" 
                                            placeholder="Durée d'un RDV" 
                                            suffix="Heure(s)"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col md="6" class="text-center">
                                        <h2>Heure d'ouverture :</h2>
                                        <v-time-picker
                                        v-model="form_data.horaire_open"
                                        :max="form_data.horaire_close"
                                        format="24hr"
                                        ></v-time-picker>
                                    </v-col>
                                    <v-col md="6">
                                        <h2>Heure de fermeture :</h2>
                                        <v-time-picker
                                        v-model="form_data.horaire_close"
                                        :min="form_data.horaire_open"
                                        format="24hr"
                                        ></v-time-picker>
                                    </v-col>
                                    
                                    <v-col md="12">
                                        <v-btn class="float-right" color="primary" @click="saveSetting" :loading="saveLoading" :disabled="saveLoading">
                                            Enregister
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                
                            </v-form>
                        </v-container>
                
                    </v-tab-item> -->

                    <v-tab-item
                        :value="3"
                    >
                        <v-container fluid>
                            <p class="text-h6">Notifications</p>
                            <v-divider class="mb-4"></v-divider>
                            <v-form>
                                <v-row>
                                    <v-col md="12">
                                        <h3>Notifier le client lorsqu'un enchère prend fin ?</h3>
                                        <v-switch
                                            v-model="form_data.bid_cust_notif"
                                        >
                                            <template v-slot:label v-if="form_data.bid_cust_notif">
                                                <div>Activé</div>
                                            </template>
                                            <template v-slot:label v-else>
                                                <div>Désactivé</div>
                                            </template>
                                        </v-switch>
                                        
                                    </v-col>
                                    
                                    <v-col md="12">
                                        <v-btn class="float-right" color="primary" @click="saveSetting" :loading="saveLoading" :disabled="saveLoading">
                                            Enregister
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                
                            </v-form>
                        </v-container>
                
                    </v-tab-item>

                    <v-tab-item
                        :value="4"
                    >
                        <v-container fluid>
                            <p class="text-h6">Formulaires Reprises / Annonces</p>
                            <v-divider class="mb-4"></v-divider>
                            <v-form>
                                <v-row>
                                    <v-col md="12">
                                        <h3>Liste des clients à afficher ? (n'impacte pas les administrateurs)</h3>
                                        <v-switch
                                            v-model="form_data.customer_list"
                                        >
                                            <template v-slot:label v-if="form_data.customer_list">
                                                <div>Tous</div>
                                            </template>
                                            <template v-slot:label v-else>
                                                <div>Clients agence seulement</div>
                                            </template>
                                        </v-switch>
                                        
                                    </v-col>
                                    
                                    <v-col md="12">
                                        <v-btn class="float-right" color="primary" @click="saveSetting" :loading="saveLoading" :disabled="saveLoading">
                                            Enregister
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                
                            </v-form>
                        </v-container>
                
                    </v-tab-item>

                    <v-tab-item
                        :value="5"
                    >
                        <v-container fluid>
                            <p class="text-h6">Enchères</p>
                            <v-divider class="mb-4"></v-divider>
                            <v-form>
                                <v-row>
                                    <v-col md="12">
                                        <h3>Assignation des annonces / reprises si un enchère est remporté ?</h3>
                                        <v-switch
                                            v-model="form_data.assign_bid"
                                        >
                                            <template v-slot:label v-if="form_data.assign_bid">
                                                <div>Automatique</div>
                                            </template>
                                            <template v-slot:label v-else>
                                                <div>Manuelle</div>
                                            </template>
                                        </v-switch>
                                        
                                    </v-col>
                                    
                                    <v-col md="12">
                                        <v-btn class="float-right" color="primary" @click="saveSetting" :loading="saveLoading" :disabled="saveLoading">
                                            Enregister
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                
                            </v-form>
                        </v-container>
                
                    </v-tab-item>

                </v-tabs-items>
                <SnackBar color="success" title="Paramètres enregistrés" v-on:close="snackbarSaveSettings = false" :model="snackbarSaveSettings"></SnackBar>
            </v-card>
        </v-col>
    </v-row>
</template>
  
<script>
    import axios from "axios";
    import SnackBar from "@/components/partials/Snackbar";
  
    export default {
        name: 'SettingsView',
    
        components: {
            SnackBar
        },

        data: () => ({
            tab: null,

            snackbarSaveSettings: false,
            saveLoading: false,

            v_forfait: [],

            form_data: {
                v_forfait: "",
                comm_jvma: 0,
                comm_franchise: 0,
                dv_forfait: 0,
                dv_comm: 0,

                licence_marque_nouveau: 0,
                price_annonce_nouveau: 0,
                price_rdv_nouveau: 0,
                price_tel_nouveau: 0,

                licence_marque_faible: 0,
                price_annonce_faible: 0,
                price_rdv_faible: 0,
                price_tel_faible: 0,

                licence_marque_fort: 0,
                price_annonce_fort: 0,
                price_rdv_fort: 0,
                price_tel_fort: 0,

                horaire_open: "",
                horaire_close: "",
                duration_rdv: "",

                bid_cust_notif: false,

                customer_list: false,

                assign_bid: false,
            }
            
        }),
        async created() {
            await this.getSettings();
        },
        methods: {
            addLine () {
                this.v_forfait.push({ 
                from: "", 
                to: "",
                for: ""
                })
            },

            removeLine (index) {
                this.v_forfait.splice(index, 1)
            },
            getSettings: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/settingValues', this.getHeaders());
                    
                    if (response) {
                        for(let setting of response.data) {
                            this.form_data[setting.name] = setting.value

                            if(setting.name == 'v_forfait'){
                                let dataForf = JSON.parse(setting.value)
                                for(let line of dataForf)
                                this.v_forfait.push({ 
                                    from: line.from, 
                                    to: line.to,
                                    for: line.for
                                })
                            }

                            if(setting.value == 'true') this.form_data[setting.name] = true;
                            if(setting.value == 'false') this.form_data[setting.name] = false;
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },

            
            saveSetting: async function () {
                try {
                    this.saveLoading = true;
                    let forfait = JSON.stringify(this.v_forfait)
                    console.log(this.form_data.v_forfait)
                    this.form_data.v_forfait = forfait;
                    for(let setting in this.form_data) {
                        console.log(setting);
                        let checkName = await axios.get(this.baseApiURL() + '/settingValue/' + setting, this.getHeaders());
                        console.log(checkName);
                        if (checkName.data){
                            await axios.put(this.baseApiURL() + '/settingValue/' + setting, {
                                value: this.form_data[setting],
                            }, this.getHeaders());
                        }else{
                            await axios.post(this.baseApiURL() + '/settingValue', {
                                name: setting,
                                value: this.form_data[setting],
                            }, this.getHeaders());
                        }
                        
                    }
                    this.snackbarSaveSettings = true;
                    this.saveLoading = false;
                } catch (e) {
                    console.log('test')
                    console.error(e);
                }
            },
        }
    }
</script>
  