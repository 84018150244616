import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import ForgotPasswordView from '../views/ForgotPasswordView.vue'
import AccountView from '../views/AccountView.vue'
import SettingsView from '../views/admin/SettingsView.vue'
import FacturesAdminView from '../views/admin/FacturesAdminView.vue'
import UsersView from '../views/admin/UsersView.vue'
import AgencesView from '../views/admin/AgencesView.vue'
import CustomersView from '../views/admin/CustomersView.vue'
import OpenHoursView from '../views/OpenHoursView.vue'
import CalendarView from '../views/CalendarView.vue'
import FacturesUserView from '../views/FacturesUserView.vue'
import VentesView from '../views/admin/VentesView.vue'
import AnnoncesView from '../views/AnnoncesView.vue'
import ReprisesAddView from '../views/ReprisesAddView.vue'
import ReprisesEditView from '../views/ReprisesEditView.vue'
import AnnoncesEditView from '../views/AnnoncesEditView.vue'
import AnnoncesListView from '../views/AnnoncesListView.vue'
import AnnoncesSingleView from '../views/AnnoncesSingleView.vue'
import CustomerValidationView from '../views/CustomerValidationView.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/password',
    name: 'ForgotPasswordView',
    component: ForgotPasswordView
  },
  {
    path: '/settings',
    name: 'SettingsView',
    component: SettingsView
  },
  {
    path: '/factures_admin',
    name: 'FacturesAdminView',
    component: FacturesAdminView
  },
  {
    path: '/account',
    name: 'AccountView',
    component: AccountView
  },
  {
    path: '/users',
    name: 'UsersView',
    component: UsersView
  },
  {
    path: '/agences',
    name: 'AgencesView',
    component: AgencesView
  },
  {
    path: '/customers',
    name: 'CustomersView',
    component: CustomersView
  },
  {
    path: '/open-hours',
    name: 'OpenHoursView',
    component: OpenHoursView
  },
  {
    path: '/calendar',
    name: 'CalendarView',
    component: CalendarView
  },
  {
    path: '/factures_user',
    name: 'FacturesUserView',
    component: FacturesUserView
  },
  {
    path: '/ventes',
    name: 'VentesView',
    component: VentesView
  },
  {
    path: '/annonces',
    name: 'AnnoncesView',
    component: AnnoncesView
  },
  {
    path: '/reprise_add',
    name: 'ReprisesAddView',
    component: ReprisesAddView
  },
  {
    path: '/reprise_edit/:id',
    name: 'ReprisesEditView',
    component: ReprisesEditView
  },
  {
    path: '/annonce_edit/:id',
    name: 'AnnoncesEditView',
    component: AnnoncesEditView
  },
  {
    path: '/annonces_list',
    name: 'AnnoncesListView',
    component: AnnoncesListView
  },
  {
    path: '/annonce/:id',
    name: 'AnnoncesSingleView',
    component: AnnoncesSingleView
  },
  {
    path: '/reprise/:id/:custId',
    name: 'CustomerValidationView',
    component: CustomerValidationView,
    // redirect: to => {
    //   console.log(to.params.id)
    //   console.log(to.params.resp)
    //   console.log(to.params.custId)
    //   return { 
    //     path: '/confirm-reprise', 
    //     //name: 'CustomerValidationView',
    //     //component: CustomerValidationView,
    //     params: { id: to.params.id, resp: to.params.resp, custId: to.params.custId} 
    //   }
    // },
    // children: [
    //   {
    //     path: 'confirm-reprise'
    //   }
    // ]
    
  },
  // {
  //   path: '/confirm-reprise',
  //   name: 'CustomerValidationView',
  //   component: CustomerValidationView,
    
  // },

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
