<template>
    <v-row class="mt-3">
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Nouvelle Reprise
                    
                </v-card-title>
                <v-card style="box-shadow: none;">
                    <v-form ref="form">
                        <v-row class="pl-6">
                            <v-col cols="12" md="12">
                                <div class="text-h6 ">Type de reprise</div>
                                <v-radio-group
                                    v-model="reprise.r_type"
                                    row
                                >
                                <v-radio
                                    label="Rachat direct"
                                    :value="0"
                                ></v-radio>
                                <v-radio
                                    label="Mise en dépôt / vente"
                                    :value="1"
                                ></v-radio>
                            </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row class="pl-6">
                            <v-col cols="12" md="12">
                                <div class="text-h6 ">Souhaitez-vous lier le formulaire de reprise à une annonce existante ?</div>
                                <v-radio-group
                                    v-model="reprise_type"
                                    row
                                >
                                <v-radio
                                    label="Lier à une annonce existante"
                                    value="1"
                                ></v-radio>
                                <v-radio
                                    label="Créer un nouveau formulaire"
                                    value="0"
                                    v-on:change="getReprise();annonce = []"
                                ></v-radio>
                            </v-radio-group>
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-autocomplete
                                v-if="reprise_type == 1"
                                label="Sélectionnez une annonce"
                                v-model="annonce"
                                :items="listeAnnonce"
                                item-value="value"
                                item-text="label"
                                v-on:change="setVehiculeData();setCustomer();"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-stepper
                            v-model="e1"
                            vertical
                            style="box-shadow: none;"
                        >
                            <v-stepper-step
                            :complete="e1 > 1"
                            step="1"
                            >
                            PROPRIÉTAIRE / CO-PROPRIÉTAIRE
                            </v-stepper-step>

                            <v-stepper-content step="1">
                                <v-card class="overflow-hidden">
                                    <v-row>
                                        <v-col cols="12" md="8">
                                            <div class="text-h6">Propriétaire</div>
                                            <v-row v-if="new_customer == false || annonce">
                                                <v-col cols="6" md="6">
                                                    <v-autocomplete
                                                    label="Sélectionnez un client"
                                                    v-model="reprise.customer"
                                                    :items="customers"
                                                    item-value="id"
                                                    item-text="label"
                                                    required
                                                    :rules="rulesRequired"
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-btn
                                                        class="mr-2"
                                                        color="primary"
                                                        @click="new_customer = true"
                                                        >
                                                        Nouveau
                                                    </v-btn>
                                                </v-col>
                                            </v-row>

                                            <v-row v-if="new_customer == true && !annonce">
                                                <v-col cols="12" md="12">
                                                    <v-btn
                                                        class="mr-2"
                                                        color="primary"
                                                        @click="new_customer = false"
                                                        >
                                                        Sélectionner un client
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_firstname"
                                                        label="Prénom"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_lastname"
                                                        label="Nom"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_email"
                                                        label="Adresse email"
                                                        required
                                                        :rules="emailRules"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_contact_from"
                                                        label="De la part de (Email)"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_phone"
                                                        label="Téléphone"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_address"
                                                        label="Adresse"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_cp"
                                                        label="Code postal"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_city"
                                                        label="Ville"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_country"
                                                        label="Pays"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                                
                                            <v-row v-if="reprise.type == 'moral'">
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="reprise.pm_raison"
                                                        label="Raison"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="reprise.pm_siret"
                                                        label="N° Siret"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            
                                            <v-radio-group
                                                v-model="reprise.type"
                                                :rules="rulesRequired"
                                                required
                                                column
                                            >
                                                <v-row>
                                                    <v-col cols="6" md="6">
                                                        <v-radio
                                                            label="Personne physique"
                                                            value="physique"
                                                        ></v-radio>
                                                    </v-col>
                                                    <v-col cols="6" md="6">
                                                        <v-radio
                                                            label="Personne morale"
                                                            value="moral"
                                                        ></v-radio>
                                                    </v-col>
                                                </v-row>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <div class="text-h6">Co-Propriétaire</div>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-text-field
                                                        v-model="reprise.co_firstname"
                                                        label="Prénom"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="12">
                                                    <v-text-field
                                                        v-model="reprise.co_lastname"
                                                        label="Nom"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        class="primary float-right"
                                        @click="verifStep1()"
                                    >
                                        Continuer
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>

                            <v-stepper-step
                            :complete="e1 > 2"
                            step="2"
                            >
                            CARACTÉRISTIQUES DU VÉHICULE
                            </v-stepper-step>

                            <v-stepper-content step="2">
                                <v-card class="overflow-hidden">
                                    <v-row>
                                        <v-col cols="6" md="3">
                                            <v-autocomplete
                                                label="Sélectionnez une marque"
                                                v-model="reprise.vehicule.marque"
                                                :items="marqueList"
                                                item-value="marque"
                                                item-text="marque"
                                                @change="getModelesByMarque(reprise.vehicule.marque)"
                                            ></v-autocomplete>
                                            <!-- <v-text-field
                                                v-model="reprise.vehicule.marque"
                                                label="Marque du véhicule"
                                            ></v-text-field> -->
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-autocomplete
                                                :disabled="!reprise.vehicule.marque"
                                                label="Sélectionnez un modele"
                                                v-model="reprise.vehicule.modele"
                                                :items="modeleList"
                                                item-value="modele"
                                                item-text="modele"
                                                @change="getFinitionsByModele(reprise.vehicule.marque,reprise.vehicule.modele)"
                                            ></v-autocomplete>
                                            <!-- <v-text-field
                                                v-model="reprise.vehicule.modele"
                                                label="Modèle du véhicule"
                                            ></v-text-field> -->
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-autocomplete
                                                :disabled="!reprise.vehicule.modele"
                                                :loading="loadingFinition"
                                                label="Sélectionnez une finition"
                                                v-model="reprise.vehicule.finition"
                                                :items="finitionList"
                                                item-value="version"
                                                item-text="version"
                                                @change="getYearsByFinition"
                                            ></v-autocomplete>
                                            <!-- <v-text-field
                                            v-model="reprise.vehicule.finition"
                                                label="Finition"
                                            ></v-text-field> -->
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="reprise.vehicule.transmission"
                                                label="Transmission"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-autocomplete
                                                :disabled="!reprise.vehicule.modele"
                                                :loading="loadingYear"
                                                label="1ère mise en circulation"
                                                v-model="reprise.vehicule.date_first"
                                                :items="yearList"
                                                @change="getDataByYears"
                                            ></v-autocomplete>
                                            <!-- <v-text-field
                                                v-model="reprise.vehicule.date_first"
                                                label="1ère mise en circulation"
                                            ></v-text-field> -->
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="reprise.vehicule.immat"
                                                label="N° Immatriculation"
                                            ></v-text-field>
                                        </v-col>

                                        <!-- <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="reprise.vehicule.carrosserie"
                                                label="État Carrosserie"
                                            ></v-text-field>
                                        </v-col> -->

                                        <v-col cols="6" md="3">
                                            <v-select
                                            label="Genre véhicule"
                                            v-model="reprise.vehicule.genre"
                                            :items="v_genre_list"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-select
                                            label="Energie"
                                            v-model="reprise.vehicule.energie"
                                            :items="['Essence', 'Diesel', 'Hybride','Hybride rechargeable', 'Électrique']"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <!-- <v-text-field
                                                v-model="reprise.vehicule.nb_porte"
                                                label="Nombre de portes"
                                            ></v-text-field> -->
                                            <v-combobox
                                            label="Nombre de portes"
                                            v-model="reprise.vehicule.nb_porte"
                                            :items="nbPortesList"
                                            ></v-combobox>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-combobox
                                            label="Nombre de places"
                                            v-model="reprise.vehicule.nb_place"
                                            :items="nbPlacesList"
                                            ></v-combobox>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-select
                                            label="Type du vehicule"
                                            v-model="reprise.vehicule.type_vehicule"
                                            :items="['CTTE', 'VP']"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-select
                                            label="Coté du volant"
                                            v-model="reprise.vehicule.cote_volant"
                                            :items="['Droite', 'Gauche']"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="reprise.vehicule.km"
                                                label="Km au compteur"
                                                suffix="Km"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-combobox
                                                v-model="reprise.vehicule.puissance"
                                                label="Puissance véhicule"
                                                suffix="CV"
                                                :items="nbPuissanceCv"
                                            ></v-combobox>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-select
                                            label="Boite de vitesse"
                                            v-model="reprise.vehicule.boite_vitesse"
                                            :items="['Automatique', 'Manuelle']"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="reprise.vehicule.moteur"
                                                label="Cylindrée moteur"
                                            ></v-text-field>
                                        </v-col>

                                        <!-- <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_version"
                                                label="Version"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_nb_portes"
                                                label="Nombre de portes"
                                            ></v-text-field>
                                        </v-col> -->
                                        
                                    </v-row>

                                    <v-btn
                                        class="float-right"
                                        @click="e1 = e1 - 1"
                                    >
                                        Précédent
                                    </v-btn>
                                    <v-btn
                                        class="primary float-right mr-3"
                                        @click="e1 = e1 + 1"
                                    >
                                        Continuer
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>

                            <v-stepper-step
                            :complete="e1 > 3"
                            step="3"
                            >
                            ÉTAT DU VÉHICULE
                            </v-stepper-step>

                            <v-stepper-content step="3">
                                <v-card class="overflow-hidden">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-textarea
                                                v-model="reprise.observations"
                                                filled
                                                label="Observations"
                                                auto-grow
                                            ></v-textarea>
                                            <v-text-field
                                                v-model="reprise.price_repa"
                                                label="Coût total de remise en état estimé"
                                                suffix="€ TTC"
                                            ></v-text-field>
                                            
                                            
                                        </v-col>
                                        <v-col cols="12" md="6" class="bloc_repas">
                                            <v-checkbox
                                                v-for="item in v_repas_list" :key="item" :value="item"
                                                v-model="reprise.repa_list"
                                                class="d-inline-block mt-0 ml-2 mr-2"
                                            >
                                                <template v-slot:label>
                                                    {{ item }}
                                                </template>
                                            </v-checkbox>
                                            <v-text-field
                                                class="ml-2 mr-2"
                                                v-model="new_repa"
                                                label="Ajouter une réparation"
                                                append-outer-icon="mdi-plus-box"
                                                @click:append-outer="addRepa"
                                            ></v-text-field>
                                            
                                        </v-col>
                                        
                                    </v-row>
                                    <v-row>
                                        <v-col md="6">
                                            <h4>Photos Générales</h4>
                                            <v-row class="mt-2 mb-2">
                                                <v-col v-for="photo in photosGeneral" :key="photo.id" cols="12" md="4" style="position:relative">
                                                    <v-img :src="'data:image/png;base64,' + photo.image" :data-caption="photo.description" min-height="100%" data-fancybox="photoReprise" :data-src="'data:image/png;base64,' + photo.image" style="cursor: pointer;"></v-img>
                                                    <div v-if="photo.description" class="pa-1" style="position:absolute;bottom:12px;left:12px;right:12px;color:#fff;background: rgba(0,0,0,.5);">{{ photo.description }}</div>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="red" class="pa-0 mr-3" small text-color="white" style="position:absolute;top:0;right:0;" @click="activePhotoVehicule = photo.id;deletePhotoRepriseDialog = true">
                                                                <v-icon>
                                                                mdi-close
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Supprimer</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="blue" class="pa-0 mr-3" small text-color="white" style="position:absolute;bottom:16px;right:5px;" @click="activePhoto = photo;DescPhotoDialog = true">
                                                                <v-icon>
                                                                mdi-pencil
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Editer</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom v-if="photo.main_photo">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="grey" class="pa-0 ml-4 mt-4" small text-color="yellow" style="position:absolute;top:0;left:0;">
                                                                <v-icon>
                                                                mdi-star
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Photo principale</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom v-if="!photo.main_photo">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="grey" class="pa-0 ml-4 mt-4" small text-color="white" style="position:absolute;top:0;left:0;" @click="setMainPhotoVehicule(photo.id);">
                                                                <v-icon>
                                                                mdi-star-outline
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Definir photo principale</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col md="6">
                                            <h4>Photos Réparations</h4>
                                            <v-row class="mt-2 mb-2">
                                                <v-col v-for="photo in photosDamage" :key="photo.id" cols="12" md="4" style="position:relative">
                                                    <v-img :src="'data:image/png;base64,' + photo.image" :data-caption="photo.description" data-fancybox="photoReprise" :data-src="'data:image/png;base64,' + photo.image" min-height="100%" style="cursor: pointer;"></v-img>
                                                    <div v-if="photo.description" class="pa-1" style="position:absolute;bottom:12px;left:12px;right:12px;color:#fff;background: rgba(0,0,0,.5);">{{ photo.description }}</div>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="red" class="pa-0 mr-3" small text-color="white" style="position:absolute;top:0;right:0;" @click="activePhotoVehicule = photo.id;deletePhotoRepriseDialog = true">
                                                                <v-icon>
                                                                mdi-close
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Supprimer</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="blue" class="pa-0 mr-3" small text-color="white" style="position:absolute;bottom:16px;right:5px;" @click="activePhoto = photo;DescPhotoDialog = true">
                                                                <v-icon>
                                                                mdi-pencil
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Editer</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom v-if="photo.main_photo">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="grey" class="pa-0 ml-4 mt-4" small text-color="yellow" style="position:absolute;top:0;left:0;">
                                                                <v-icon>
                                                                mdi-star
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Photo principale</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom v-if="!photo.main_photo">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="grey" class="pa-0 ml-4 mt-4" small text-color="white" style="position:absolute;top:0;left:0;" @click="setMainPhotoVehicule(photo.id);">
                                                                <v-icon>
                                                                mdi-star-outline
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Definir photo principale</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col md="6">
                                            <v-file-input
                                                multiple
                                                counter
                                                small-chips
                                                v-model="v_photos"
                                                label="Ajouter des photos"
                                            ></v-file-input>
                                            <v-checkbox
                                                v-model="isDamage"
                                                label="Photos des dommages ?"
                                            ></v-checkbox>
                                            <v-btn
                                                color="primary"
                                                @click.stop="onUpload"
                                            >UPLOAD</v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        class="float-right"
                                        @click="e1 = e1 - 1"
                                    >
                                        Précédent
                                    </v-btn>
                                    <v-btn
                                        class="primary float-right mr-3"
                                        @click="e1 = e1 + 1"
                                    >
                                        Continuer
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>

                            <v-stepper-step
                            :complete="e1 > 4"
                            step="4"
                            >
                            MONTANT TOTAL DE RACHAT
                            </v-stepper-step>

                            <v-stepper-content step="4">
                                <v-card v-if="reprise.r_type == 0" class="overflow-hidden">
                                    <v-row>
                                        <v-col md="12">
                                            <v-radio-group v-model="reprise.price_type" row>
                                                <v-radio
                                                    label="TVA sur marge"
                                                    :value="0"
                                                ></v-radio>
                                                <v-radio
                                                    label="TVA récupérable"
                                                    :value="1"
                                                ></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col v-if="reprise.price_type == 0" cols="6" md="3">
                                            <div class="text-h6">TVA sur marge (net vendeur)</div>
                                            <v-text-field
                                                v-model="reprise.price_marge"
                                                label="Si TVA sur marge"
                                                suffix="€ TTC"
                                            ></v-text-field>
                                            <v-divider class="mb-3 mt-3"></v-divider>
                                            <div class="text-h6 text-right" style="font-size:.9rem!important;color:grey;">plus {{ parseInt(comm_jvma_marge) }}€ HT comm. JVMA {{ ((parseInt(comm_jvma_marge)) + (parseInt(comm_jvma_marge)/100*20)).toFixed(2) }}€ TTC</div>
                                        </v-col>
                                        <v-col v-if="reprise.price_type == 1" cols="6" md="3">
                                            <div class="text-h6">TVA récupérable (net vendeur)</div>
                                            <v-text-field
                                                v-model="reprise.price_tva"
                                                label="Si TVA récupérable"
                                                suffix="€ HT"
                                            ></v-text-field>

                                            <div class="text-h6 text-right" style="font-size:1rem;color:grey;">{{ parseInt(reprise.price_tva/100*20)+parseInt(reprise.price_tva) }} <span>€ TTC</span></div>
                                            <div class="text-h6 text-right" style="font-size:1rem;color:grey;">{{ reprise.price_tva/100*20 }} <span>€ TVA 20%</span></div>
                                            <v-divider class="mb-3 mt-3"></v-divider>
                                            <div class="text-h6 text-right" style="font-size:.9rem!important;color:grey;">plus {{ parseInt(comm_jvma_tva) }}€ HT comm. JVMA {{ ((parseInt(comm_jvma_tva)) + (parseInt(comm_jvma_tva)/100*20)).toFixed(2) }}€ TTC</div>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        class="float-right"
                                        @click="e1 = e1 - 1"
                                    >
                                        Précédent
                                    </v-btn>
                                    <v-btn
                                        class="primary float-right mr-3"
                                        :loading="loadingSave"
                                        :disabled="loadingSave"
                                        @click="updateReprise(1); loader = 'loadingSave';"
                                    >
                                        Brouillon
                                    </v-btn>
                                    <v-btn
                                        v-if="reprise.status.id == 1"
                                        class="primary float-right mr-3"
                                        :loading="loadingSave"
                                        :disabled="loadingSave"
                                        @click="updateReprise(2); loader = 'loadingSave';"
                                    >
                                        Envoyer au client
                                    </v-btn>
                                    <v-btn
                                        v-if="reprise.status.id == 2"
                                        class="primary float-right mr-3"
                                        :loading="loadingSave"
                                        :disabled="loadingSave"
                                        @click="updateReprise(2); loader = 'loadingSave';"
                                    >
                                        Renvoyer au client
                                    </v-btn>
                                </v-card>

                                <v-card v-if="reprise.r_type == 1" class="overflow-hidden">
                                    <v-row>
                                        <v-col cols="6" md="3">
                                            <div class="text-h6">Commission</div>
                                            <v-text-field
                                                v-model="reprise.dv_comm"
                                                label="Commission agence"
                                                suffix="€ TTC"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <div class="text-h6">Prix net vendeur</div>
                                            <v-text-field
                                                v-model="reprise.dv_price"
                                                label="Prix net vendeur"
                                                suffix="€ HT"
                                            ></v-text-field>

                                            <div class="text-h6 text-right" style="font-size:1rem;color:grey;">{{ parseInt(reprise.dv_price/100*20)+parseInt(reprise.dv_price) }} <span>€ TTC</span></div>
                                            <div class="text-h6 text-right" style="font-size:1rem;color:grey;">{{ reprise.dv_price/100*20 }} <span>€ TVA 20%</span></div>
                                            <v-divider class="mb-3 mt-3"></v-divider>
                                            <div class="text-h6 text-right" style="font-size:1rem;color:black;">Prix de vente total : {{ (parseInt(reprise.dv_price/100*20)+parseInt(reprise.dv_price))+parseInt(reprise.dv_comm) }} <span>€ TTC</span></div>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        class="float-right"
                                        @click="e1 = e1 - 1"
                                    >
                                        Précédent
                                    </v-btn>
                                    <v-btn
                                        class="primary float-right mr-3"
                                        :loading="loadingSave"
                                        :disabled="loadingSave"
                                        @click="updateReprise(1); loader = 'loadingSave';"
                                    >
                                        Brouillon
                                    </v-btn>
                                    <v-btn
                                        v-if="reprise.status.id == 1"
                                        class="primary float-right mr-3"
                                        :loading="loadingSave"
                                        :disabled="loadingSave"
                                        @click="updateReprise(2); loader = 'loadingSave';"
                                    >
                                        Envoyer au client
                                    </v-btn>
                                    <v-btn
                                        v-if="reprise.status.id == 2"
                                        class="primary float-right mr-3"
                                        :loading="loadingSave"
                                        :disabled="loadingSave"
                                        @click="updateReprise(2); loader = 'loadingSave';"
                                    >
                                        Renvoyer au client
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>
                        
                        </v-stepper>
                    </v-form>
                    <v-dialog v-model="deletePhotoRepriseDialog" max-width="600px">
                        <DeletePhotoRepriseDialog v-on:valid="deletePhotoReprises" v-on:close="deletePhotoRepriseDialog = false"></DeletePhotoRepriseDialog>
                    </v-dialog>

                    <v-dialog v-model="DescPhotoDialog" max-width="800px">
                        <DescPhotoDialog :activePhoto="activePhoto" v-on:close="DescPhotoDialog = false" v-on:valid="DescPhotoDialog = false;getPhotoVehicule;"></DescPhotoDialog>
                    </v-dialog>
                </v-card>
            </v-card>
        </v-col>
    </v-row>
</template>

<style>
    .bloc_repas .v-input--selection-controls__input {
        display: none;
    }
    .bloc_repas .v-input:not(.v-text-field) .v-input__slot {
        margin: 0;
        padding: 15px;
        border-radius: 5px;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
        transition-duration: .2;
    }
    .bloc_repas .v-input:not(.v-text-field) .v-input__slot:hover {
        margin: 0;
        padding: 15px;
        border-radius: 5px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .bloc_repas .v-input--is-label-active:not(.v-text-field) .v-input__slot {
        background: rgb(2, 125, 176);
        color: #fff;
    }
    .bloc_repas .v-input--is-label-active:not(.v-text-field) .v-input__slot label {
        color: #fff;
    }
    .select_color .v-input--radio-group__input .v-item--active > label > div {
        border: 2px solid #000;
    }
    .v-input.v-select {
        flex: 0 1 auto;
    }
</style>

<script>
    import axios from "axios";
    import { Fancybox } from "@fancyapps/ui";
    import "@fancyapps/ui/dist/fancybox/fancybox.css";

    import DeletePhotoRepriseDialog from "@/components/reprises/DeletePhotoRepriseDialog";
    import DescPhotoDialog from "@/components/photos/DescPhotoDialog";

    export default {
        name: 'ReprisesEditView',

        components: {
            DeletePhotoRepriseDialog,
            DescPhotoDialog,
        },

        data() {
            return {
                deletePhotoRepriseDialog: false,
                activePhotoVehicule: [],
                DescPhotoDialog: false,

                activePhoto: null,
                loadingSave: false,

                e1: 1,

                new_repa: "",

                customers: [],
                new_customer: false,
                reprise: [],
                photos: null,
                createdVehicule: null,

                marqueList: [],
                modeleList: [],
                finitionList: [],
                loadingFinition: false,
                yearList: [],
                nbPortesList: [],
                nbPlacesList: [],
                nbPuissanceCv: [],
                loadingYear: false,

                annonces: [],
                listeAnnonce: [],

                reprise_type: "0",
                format_reprise: "0",
                annonce: '',
                customer: '',
                c_firstname: '',
                c_lastname: '',
                c_email: '',
                c_contact_from: '',
                c_phone: '',
                c_address: '',
                c_cp: '',
                c_city: '',
                c_country: '',
                c_type: '',

                co_c_firstname: '',
                co_c_lastname: '',
                
                //Data véhicule
                v_marque: '',
                v_modele: '',
                v_finition: '',
                v_transmission: '',
                v_date_first: '',
                v_immat: '',
                v_carroserie: '',
                v_genre_list: [
                    "Cabriolet",
                    "Coupé",
                    "Break",
                    "Berline",
                    "SUV",
                    "Pick Up",
                    "Utilitaire",
                    "Citadine",
                ],
                v_genre: '',
                v_energie: '',
                v_nb_portes: '',
                v_nb_km: '',
                v_puissance: '',
                v_boite_vitesse: '',
                v_moteur: '',

                // v_version: '',
                v_observations: '',
                v_repas: [],
                v_photos: [],
                photosGeneral: [],
                photosDamage: [],
                isDamage: false,
                v_repa_price: 0,
                v_repas_list: [
                    "Disques freins avant", 
                    "Disques freins arriere", 
                    "Plaquettes freins avant", 
                    "Plaquettes freins arriere", 
                    "2 pneus avant",
                    "2 pneus arrière",
                    "Révision / vidange",
                    "Courroie de distribution",
                    "Vidange boite auto",
                    "Embrayage"
                ],

                price_type: null,

                v_price_marge: 0,
                v_price_tva: 0,

                comm_jvma_tva: 0,
                comm_jvma_marge: 0,

                listeEmail: [],

                rulesRequired: [
                    //v => (v || '').length > 0 || 'Veuillez renseigner ce champs',
                    v => !!v || 'Veuillez renseigner ce champs',
                ],
                emailRules: [ 
                    v => /^[^\s@]+@[^\s@]+$/.test(v) || 'Adresse email non valide !',
                    v => !this.listeEmail.includes(v) || 'L\'Email existe déjà'
                ],

                saveForm: false

            }
        },
        watch: {
            'reprise.price_marge': function () {
                this.getComJvma();
            },
            'reprise.price_tva': function () {
                this.getComJvma();
            }
        },
        async created() {

            // if(this.$route.query.annonce){
            //     this.reprise_type = 1;
            //     console.log(this.$route.query.annonce);
            //     this.annonce = this.$route.query.annonce;
            //     this.setVehiculeData();
            //     this.setCustomer();
            // }
            await this.getCustomers();
            await this.getReprise();
            this.createdVehicule = this.reprise.vehicule.id;
            await this.setTypeReprise();
            if(this.user.role.id != 1){
                await this.getAnnoncesByAgence();
            }else{
                await this.getAnnonces();
            }
            await this.getRepas();
            
            await this.getMarques();
            await this.getPhotoVehicule();
            await this.getComJvma();
            Fancybox.bind("[data-fancybox='photoReprise']", {});
        },
        methods: {
            validate () {
                this.$refs.form.validate()
            },
            verifStep1: function() {
                
                if(!this.new_customer){
                    if(!this.reprise.customer || !this.reprise.type) {
                        console.log("manque client")
                        this.validate();
                        //this.snackbarUncompleted = true;
                    } else {
                        this.e1 = this.e1 + 1;
                        //this.snackbarUncompleted = false;
                    }
                } else {
                    if( !this.c_firstname
                        || !this.c_lastname
                        || !this.c_email
                        || !this.c_phone
                        || !this.reprise.type
                        || this.listeEmail.includes(this.c_email)
                    ) {
                        console.log("manque client")
                        this.validate();
                        //this.snackbarUncompleted = true;
                    } else {
                        this.e1 = this.e1 + 1;
                        //this.snackbarUncompleted = false;
                    }
                }
            },
            // addRepa: async function () {
            //     try {
            //         console.log("test")
            //         const response = await axios.post(this.baseApiURL() + '/reparation', {
            //             label: this.new_repa,
                        
            //         }, this.getHeaders());
            //         if (response) {
            //             await this.getRepas();
            //             this.new_repa = "";
            //         }
            //     } catch (e) {
            //         console.log(e);
            //     }
            // },
            // getRepas: async function () {
            //     try {
            //         const response = await axios.get(this.baseApiURL() + '/reparations', this.getHeaders());
            //         if(response){
            //             this.v_repas_list = response.data;
            //             console.log(this.v_repas_list);
            //         }
            //     } catch (e) {
            //         console.log(e);
            //     }
            // },
            addRepa: async function () {
                try {
                    this.v_repas_list.push(this.new_repa);
                    this.new_repa = "";
                } catch (e) {
                    console.log(e);
                }
            },
            getRepas: async function () {
                try {
                    let currentRepa = this.reprise.repa_list;

                    for(let item of currentRepa){
                        console.log()
                        if(!this.v_repas_list.includes(item)) {
                            this.v_repas_list.push(item);
                        }
                    }
                    
                } catch (e) {
                    console.log(e);
                }
            },
            
            getAnnonces: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/annonces', this.getHeaders());
                    if(response) {
                        this.annonces = response.data;
                        for(let annonce of this.annonces) {
                            this.listeAnnonce.push({
                                label: annonce.vehicule.marque + " " + annonce.vehicule.modele + " (" + annonce.reference + ")",
                                value: annonce.id
                            })
                        }
                        console.log(this.listeAnnonce)
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getAnnoncesByAgence: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/annonces/' + this.user.agence.id, this.getHeaders());
                    if(response) {
                        this.annonces = response.data;
                        for(let annonce of this.annonces) {
                            this.listeAnnonce.push({
                                label: annonce.vehicule.marque + " " + annonce.vehicule.modele,
                                value: annonce.id
                            })
                        }
                        console.log(this.listeAnnonce)
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getMarques: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/car_data/marques', this.getHeaders());
                    if(response) {
                        //console.log(response.data.rows);
                        this.marqueList = response.data;
                        if(this.reprise.vehicule.marque){
                            this.getModelesByMarque(this.reprise.vehicule.marque);

                            if(this.reprise.vehicule.modele){
                                this.getFinitionsByModele(this.reprise.vehicule.marque,this.reprise.vehicule.modele)

                                // if(this.reprise.vehicule.finition && this.finitionList.length > 0){
                                //     this.getYearsByFinition();
                                //     console.log(this.yearList)
                                // }
                            }
                        }
                        
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getModelesByMarque: async function (marque) {
                try {
                    const response = await axios.post(this.baseApiURL() + '/car_data/modeles_by_marque', {
                        marque: marque
                    },this.getHeaders());
                    if(response) {
                        // console.log(response.data);
                        this.modeleList = response.data;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getFinitionsByModele: async function (marque, modele) {
                try {
                    this.loadingFinition = true;
                    this.loadingYear = true;
                    const response = await axios.post(this.baseApiURL() + '/car_data/finitions_by_modele', {
                        marque: marque,
                        modele: modele
                    },this.getHeaders());
                    if(response) {
                        // console.log(response.data);
                        this.finitionList = response.data;
                        //console.log(this.finitionList)
                        for(let line of this.finitionList) {
                            if(!this.yearList.includes(line.annee)){
                                this.yearList.push(line.annee);
                            }
                        }
                        this.loadingFinition = false;
                        this.loadingYear = false;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getYearsByFinition: async function () {
                try {
                    this.loadingYear = true;
                    this.yearList = [];
                    for(let line of this.finitionList) {
                        if(!this.yearList.includes(line.annee) && line.version == this.reprise.vehicule.finition){
                            this.yearList.push(line.annee);
                        }
                    }
                    this.loadingYear = false;
                } catch (e) {
                    console.error(e);
                }
            },
            getDataByYears: async function () {
                try {
                    
                    this.nbPortesList = [];
                    this.nbPlacesList = [];
                    this.nbPuissanceCv = [];
                    for(let line of this.finitionList) {
                        if(!this.nbPortesList.includes(line.nombre_de_portes) && line.version == this.reprise.vehicule.finition && line.annee == this.reprise.vehicule.date_first){
                            this.nbPortesList.push(line.nombre_de_portes);
                        }
                        if(!this.nbPlacesList.includes(line.nombre_de_places_assises) && line.version == this.reprise.vehicule.finition && line.annee == this.reprise.vehicule.date_first){
                            this.nbPlacesList.push(line.nombre_de_places_assises);
                        }
                        if(!this.nbPuissanceCv.includes(line.puissance_fiscale_cv) && line.version == this.reprise.vehicule.finition && line.annee == this.reprise.vehicule.date_first){
                            this.nbPuissanceCv.push(line.puissance_fiscale_cv);
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            setVehiculeData: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/annonce/' + this.annonce + '/vehicule', this.getHeaders());
                    if(response){
                        this.reprise.vehicule = response.data;
                        await this.getPhotoVehicule();
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            setCustomer: async function () {
                try {
                    console.log(this.annonce)
                    const response = await axios.get(this.baseApiURL() + '/annonce/' + this.annonce, this.getHeaders());
                    if(response){
                        this.reprise.customer = response.data.customer.id
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            getReprise: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/reprise/' + this.$route.params.id, this.getHeaders());
                    if(response){
                        this.reprise = response.data;

                        // const vehicule = await axios.get(this.baseApiURL() + '/vehicule/' + this.reprise.vehicule.id, this.getHeaders());
                        // console.log(vehicule.data);
                        if(this.reprise.repa_list){
                            this.reprise.repa_list = JSON.parse(this.reprise.repa_list)
                        }else{
                            this.reprise.repa_list = [];
                        }
                        // if(this.reprise.annonce){
                        //     this.reprise_type = 1;
                        // }
                        console.log(this.reprise.customer);
                        // console.log(JSON.parse(this.reprise.repa_list));
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            setTypeReprise: async function () {
                try {
                    if(this.reprise.annonce){
                        this.reprise_type = "1";
                        this.annonce = this.reprise.annonce.id
                    }
                    console.log(this.reprise_type);
                } catch (e) {
                    console.log(e);
                }
            },
            getCustomers: async function () {
                try {
                    const custType = await axios.get(this.baseApiURL() + '/settingValue/customer_list', this.getHeaders());
                    const response = await axios.get(this.baseApiURL() + '/customers', this.getHeaders());
                    if(response) {
                        this.customers = response.data;
                        for(const customer in this.customers){
                            this.customers[customer].label = this.customers[customer].firstname + " " + this.customers[customer].lastname + " (" + this.customers[customer].email + ")";
                            this.listeEmail.push(this.customers[customer].email)
                        }
                    }

                    if (custType.data.value == 'false' && this.user.agence.id != 1){
                        const response2 = await axios.get(this.baseApiURL() + '/customersByAgence/' + this.user.agence.id, this.getHeaders());
                        if(response2) {
                            this.customers = response2.data;
                            for(const customer in this.customers){
                                this.customers[customer].label = this.customers[customer].firstname + " " + this.customers[customer].lastname + " (" + this.customers[customer].email + ")";
                                this.listeEmail.push(this.customers[customer].email)
                            }
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getComJvma: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/settingValue/v_forfait', this.getHeaders());
                    if(response) {
                        let tranches = JSON.parse(response.data.value);
                        for(let tranche of tranches) {
                            if(parseFloat(this.reprise.price_marge) >= parseFloat(tranche.from) && parseFloat(this.reprise.price_marge) <= parseFloat(tranche.to)) {
                                this.comm_jvma_marge = tranche.for;
                            }else if (parseFloat(this.reprise.price_marge) >= parseFloat(tranche.from) && !tranche.to) {
                                this.comm_jvma_marge = tranche.for;
                            }

                            let ttc_price = parseFloat(this.reprise.price_tva) + parseFloat((.2*this.reprise.price_tva));
                            console.log(ttc_price)
                            if(ttc_price >= parseFloat(tranche.from) && ttc_price <= parseFloat(tranche.to)) {
                                this.comm_jvma_tva = tranche.for;
                            }else if (ttc_price >= parseFloat(tranche.from) && !tranche.to) {
                                this.comm_jvma_tva = tranche.for;
                            }
                        }
                        //this.comm_jvma = response.data.value;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getPhotoVehicule: async function () {
                try {
                    //console.log(this.reprise.vehicule.id);
                    const response = await axios.get(this.baseApiURL() + '/vehicule/'+this.reprise.vehicule.id+'/photo', this.getHeaders());
                    if(response) {
                        this.photos = response.data;
                        this.photosGeneral = [];
                        this.photosDamage = [];
                        //console.log(this.photos);
                        for(let photo of this.photos){
                            console.log(photo)
                           if(photo.isDamage){
                                this.photosDamage.push(photo)
                           }else{
                                this.photosGeneral.push(photo)
                           }
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            setMainPhotoVehicule: async function (photoId) {
                try {
                    const response = await axios.put(this.baseApiURL() + '/vehicule/'+this.reprise.vehicule.id+'/main_photo/'+photoId, this.getHeaders());
                    if(response) {
                        this.getPhotoVehicule();
                    }
                } catch (e) {
                    console.error(e);
                }
            },

            // addVehicule: async function () {
            //     try {
            //         const response = await axios.post(this.baseApiURL() + '/vehicule', {
            //             marque: this.v_marque,
            //             modele: this.v_modele,
            //             finition: this.v_finition,
            //             transmission: this.v_transmission,
            //             date_first: this.v_date_first,
            //             immat: this.v_immat,
            //             carrosserie: this.v_carroserie,
            //             genre: this.v_genre,
            //             energie: this.v_energie,
            //             nb_porte: this.v_nb_portes,
            //             km: this.v_nb_km,
            //             puissance: this.v_puissance,
            //             boite_vitesse: this.v_boite_vitesse,
            //             moteur: this.v_moteur,
                        
            //         }, this.getHeaders());
            //         if (response) {
            //             //console.log(response.data.id);
            //             return response.data.id;
            
            //         }
            //     } catch (e) {
            //         console.error(e);
            //     }
            // },

            addCustomer: async function () {
                try {

                    this.success = false;
                    const response = await axios.post(this.baseApiURL() + '/customer', {
                        firstname: this.c_firstname,
                        lastname: this.c_lastname,
                        address: this.c_address,
                        cp: this.c_cp,
                        city: this.c_city,
                        country: this.c_country,
                        email: this.c_email,
                        contact_from: this.c_contact_from,
                        phone: this.c_phone,
                        note: '',
                        
                    }, this.getHeaders());
                    if (response) {
                        return response.data.id;
                    }
                } catch (e) {
                    console.error(e);
                }
            },

            updateReprise: async function (statut) {
                try {
                    this.loadingSave = true;
                    var customer_id = null;

                    if(this.new_customer){
                        customer_id = await this.addCustomer();
                    }else if(this.reprise.customer.id){
                        customer_id = this.reprise.customer.id;
                    }else if(this.reprise.customer){
                        customer_id = this.reprise.customer;
                    }

                    var vehicule_id = this.reprise.vehicule.id;

                    //console.log(JSON.parse(JSON.stringify(this.v_repas)));
                    await axios.put(this.baseApiURL() + '/vehicule/'+vehicule_id, {
                        marque: this.reprise.vehicule.marque,
                        modele: this.reprise.vehicule.modele,
                        finition: this.reprise.vehicule.finition,
                        transmission: this.reprise.vehicule.transmission,
                        date_first: this.reprise.vehicule.date_first,
                        immat: this.reprise.vehicule.immat,
                        carrosserie: this.reprise.vehicule.carrosserie,
                        genre: this.reprise.vehicule.genre,
                        energie: this.reprise.vehicule.energie,
                        nb_porte: this.reprise.vehicule.nb_porte,
                        nb_place: this.reprise.vehicule.nb_place,
                        type_vehicule: this.reprise.vehicule.type_vehicule,
                        cote_volant: this.reprise.vehicule.cote_volant,
                        km: this.reprise.vehicule.km,
                        puissance: this.reprise.vehicule.puissance,
                        boite_vitesse: this.reprise.vehicule.boite_vitesse,
                        moteur: this.reprise.vehicule.moteur,
                        
                    }, this.getHeaders());
                    
                    
                    if(vehicule_id && customer_id) {
                        var selectAnnonce = null;
                        if(this.annonce){
                            selectAnnonce = this.annonce;
                        }
                        

                        const response = await axios.put(this.baseApiURL() + '/reprise/'+this.reprise.id, {
                            //user: this.user.id,
                            customer: customer_id,
                            vehicule: vehicule_id,
                            agence: this.user.agence.id,
                            annonce: selectAnnonce,
                            r_type: this.reprise.r_type,
                            pm_raison: this.reprise.pm_raison,
                            pm_siret: this.reprise.pm_siret,
                            co_firstname: this.reprise.co_firstname,
                            co_lastname: this.reprise.co_lastname,
                            type: this.reprise.type,
                            observations: this.reprise.observations,
                            repa_list: JSON.stringify(this.reprise.repa_list),
                            photos: '',
                            price_repa: this.reprise.price_repa,
                            price_type: this.reprise.price_type,
                            price_marge: this.reprise.price_marge,
                            price_tva: this.reprise.price_tva,
                            dv_comm: this.reprise.dv_comm,
                            dv_price: this.reprise.dv_price,
                            status: statut,
                            
                        }, this.getHeaders());
                        
                        if (response) {
                            console.log(this.reprise_type);
                            if(this.reprise_type != "0"){
                                //this.deleteVehicule(this.createdVehicule)
                                await axios.put(this.baseApiURL() + '/annonce/'+this.annonce+'/reprise/'+this.reprise.id, {}, this.getHeaders())
                            }
                            this.saveForm = true;
                            this.loadingSave = false;
                            this.$router.push({ path: '/annonces?isUpdate=1'});
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            onUpload: async function () {
                try {
                    // const FormData = require("form-data");
                    const formdata = new FormData();
                    // formdata.append('photo',this.v_photos);
                    formdata.append("photos", "");
                    formdata.append("name", "");
                    console.log(this.reprise.vehicule.id);
                    for (let photo of this.v_photos) {
                        formdata.set("photos", photo);
                        formdata.set("name", photo.name);
                        var isDamage = "";
                        if(this.isDamage){
                            isDamage = 1;
                        }else{
                            isDamage = 0;
                        }
                        // console.log(formdata.get("photos"));
                        await axios.post(this.baseApiURL() + '/vehicule/'+this.reprise.vehicule.id+'/photo/' + isDamage, formdata, {
                            headers: {
                                'Authorization': this.token,
                                "Content-Type": "multipart/form-data"
                            },
                        });
                        
                        this.getPhotoVehicule();
                        this.v_photos = null;
                        this.isDamage = false;
                        //return true;
                    }
                    

                    //formdata.append("photos", this.v_photos);

                    //console.log(this.getHeaders());
                    
                    // return await axios.post(this.baseApiURL() + '/reprise/'+this.reprise.id+'/photo', formdata, {
                    //     headers: {
                    //         "Content-Type": "multipart/form-data"
                    //     },
                    // });
                    
                } catch (e) {
                    console.error(e);
                }
            },
            deletePhotoReprises: async function () {
                console.log(this.activePhotoVehicule)
                try {
                    await axios.delete(this.baseApiURL() + '/photo/'+ this.activePhotoVehicule, this.getHeaders());
                    this.deletePhotoRepriseDialog = false;
                    this.getPhotoVehicule();
                } catch (e) {
                    console.error(e);
                }
            },
            deleteVehicule: async function (id) {
                try {
                    await axios.delete(this.baseApiURL() + '/vehicule/'+ id, this.getHeaders());
                } catch (e) {
                    console.error(e);
                }
            },
            deleteReprises: async function () {
                try {
                    const response = await axios.delete(this.baseApiURL() + '/reprise/'+ this.reprise.id, this.getHeaders());
                    if(response) {
                        if(!this.annonce){
                            await this.deleteVehicule(this.reprise.vehicule.id);
                        }
                        
                        return true;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
        },
        beforeRouteLeave: async function (to, from , next) {
            // console.log(this.saveForm)
            const response = await axios.get(this.baseApiURL() + '/reprise/' + this.$route.params.id, this.getHeaders());
            if(!response.data.customer){
                //if(!this.saveForm) {
                    const answer = window.confirm('Voulez-vous vraiement annuler cette reprise ?')
                    if (answer) {
                        if (await this.deleteReprises()){
                            next()
                        }
                        
                    } else {
                        next(false)
                    }
                // }else{
                //     next()
                // }
            }else{
                next()
            }
        }
    }
</script>