<template>
    <div class="bg-login fill-height">
        <v-container>
        <v-row justify="center" class="mt-16">
            <v-col cols="3" md="5" sm="8" class="text-center white rounded-lg" style="position:relative;">
            <v-alert
                v-if="error"
                border="right"
                colored-border
                type="error"
                elevation="2"
                class="ma-auto alert-login"
            >
                Identifiants incorrects
            </v-alert>
            <v-img src="../assets/logo-monoto.png" height="120px" contain class="my-4"></v-img>
            <h1>Se connecter</h1>
            <v-row justify="center">
                <v-col cols="6" md="8" sm="10">
                <form>
                    <v-text-field
                        v-model="email"
                        label="Adresse Email"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="password"
                        label="Mot de passe"
                        required
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                    ></v-text-field>
                    <p class="mt-3">
                    <router-link to="/password">Mot de passe oublié</router-link>
                    </p>
                    <v-btn class="mb-6 mt-2" color="#9CCAE2" @click="authenticate" :disabled="!password || !email">Se
                    connecter
                    </v-btn>
                </form>
                </v-col>
            </v-row>
            </v-col>
        </v-row>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'LoginView',
  data() {
    return {
      error: false,
      email: null,
      password: null,
      showPassword: null
    }
  },
  created() {
    document.title = 'Se connecter | MONOTO';
  },
  methods: {
    authenticate: async function () {
      try {
        this.error = false;
        console.log(this.baseApiURL());
        console.log(this.$data);
        const response = await axios.post(this.baseApiURL() + '/auth', {
          email: this.email,
          password: this.password
        });

        if(response.data.auth === false) {
          this.error = true
        } else {
          this.$data.token = response.data.token;
          this.$data.user = response.data.user;
          setTimeout(() => {
            if(this.$data.user.role.label === 'Administrateur' 
            || this.$data.user.role.label === 'Franchisé (Gestionnaire)' 
            || this.$data.user.role.label === 'Franchisé (Commercial)' 
            || this.$data.user.role.label === 'Marchand') {
              this.$router.push({name: 'HomeView'});
            }

          }, 200);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
}
</script>
<style>
.bg-login {
  background-image: linear-gradient(rgba(19, 147, 216, 0.3), rgba(19, 147, 216, 0.6)), url("../assets/bg-monoto.jpg");
  background-size: cover;
}
.alert-login {
  position: absolute!important;
  top: 20px;
  left: 20px;
  z-index: 3;
}
</style>