<template>
    <v-row class="mt-3"> 
        <v-col md="7">
            <v-carousel
                v-model="slides"
                height="500"
                hide-delimiter-background
                show-arrows-on-hover
            >
                <v-carousel-item
                v-for="(photo, i) in photosGeneral"
                :key="i"
                eager
                >
                    <v-sheet
                        height="100%"
                    >
                        <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                        >
                        <v-img v-if="photo" :src="'data:image/png;base64,' + photo.image" :data-src="'data:image/png;base64,' + photo.image" :data-fancybox="'photo-'+annonce.vehicule.id" height="500"  style="cursor: pointer;"></v-img>
                        </v-row>
                    </v-sheet>
                </v-carousel-item>
            </v-carousel>

            <v-sheet
                class="mx-auto"
            >
                <v-slide-group
                show-arrows
                active-class="selected"
                >
                    <v-slide-item
                        v-for="(photo, i) in photosGeneral"
                        :key="i"
                        v-slot="{ active, toggle }"
                    >
                        <div class="mr-2 mb-4" :style="active ? {border: '2px solid blue'} : {border: '2px solid transparent'}" @click="toggle">
                            <v-img @click="slides=i" v-if="photo" :src="'data:image/png;base64,' + photo.image" :data-src="'data:image/png;base64,' + photo.image" width="100"  style="cursor: pointer;"></v-img>
                        </div>
                    </v-slide-item>
                </v-slide-group>
            </v-sheet>

            <v-card elevation="1">
                <v-container class="d-flex flex-row align-center justify-space-between" style="position: relative;">
                    <v-row>
                        <v-col v-if="(annonce.agence && user.agence.id == annonce.agence.id) || user.role.id == 1" md="6">
                            <p class="text-h5">Informations du client</p>
                            <p><v-icon color="blue darken-2">mdi-account</v-icon> {{ annonce.customer.lastname }} {{ annonce.customer.firstname }}</p>
                            <p><v-icon color="blue darken-2">mdi-map-marker</v-icon> {{ annonce.customer.address ? annonce.customer.address + ',' : '' }} {{ annonce.customer.cp }} {{ annonce.customer.city }}</p>
                            <p><v-icon color="blue darken-2">mdi-email-outline</v-icon> {{ annonce.customer.email }}</p>
                            <p><v-icon color="blue darken-2">mdi-phone</v-icon>  {{ annonce.customer.phone }}</p>
                        </v-col>
                        <v-col v-else-if="!annonce.agence" md="6">
                            <p class="text-h5">Annonce non attribuée</p>
                        </v-col>
                        <v-col v-else md="6">
                            <p class="text-h5">Informations de l'agence</p>
                            <p><v-icon color="blue darken-2">mdi-domain</v-icon> {{ annonce.agence.label }}</p>
                            <p><v-icon color="blue darken-2">mdi-map-marker</v-icon> {{ annonce.agence.address }}, {{ annonce.agence.cp }} {{ annonce.agence.city }}</p>
                            <p><v-icon color="blue darken-2">mdi-email-outline</v-icon> {{ annonce.user.email }} ({{ annonce.user.firstname }} {{ annonce.user.lastname }})</p>
                            <p><v-icon color="blue darken-2">mdi-phone</v-icon>  {{ annonce.agence.phone }}</p>
                        </v-col>
                        <v-col md="6">
                            <p><b>Lieu du véhicule :</b> {{ annonce.customer.cp }} {{ annonce.customer.city }}</p>
                            <v-btn v-if="!isFav" color="ml-2 mt-4" @click="addFavorite(annonce.id)"><v-icon>mdi-star-outline</v-icon>Mes favoris</v-btn>
                            <v-btn v-if="isFav" color="ml-2 mt-4 error" @click="removeFavorite(isFav)"><v-icon>mdi-star-off-outline</v-icon>Supprimer des favoris</v-btn>
                        </v-col>
                    </v-row>
                    
                </v-container>

            </v-card>
        </v-col>
        <v-col md="5">
            <v-container v-if="!annonce.sellPrice && annonce.status.id != 3 && annonce.a_type != 1" class="d-flex flex-row align-center justify-space-between" style="position: relative;">
                <span class="mb-2 mt-2 text d-inline-flex flex-row" style="color:orange"><v-icon class="mr-2" color="orange">mdi-gavel</v-icon> Fin d'enchère dans</span>
                <span style="color:white;background-color: orange;" class="pa-2 ml-2 font-weight-bold">{{ timer }}</span>
            </v-container>

            <v-container v-if="annonce.sellPrice && annonce.status.id == 3 && annonce.a_type != 1" class="d-flex flex-row align-center justify-space-between" style="position: relative;">
                <span class="mb-2 mt-2 text d-inline-flex flex-row blue--text text--darken-2" style="color:orange"><v-icon class="mr-2" color="blue darken-2">mdi-cash</v-icon> Achat immédiat</span>
            </v-container>

            <v-divider v-if="annonce.a_type != 1" class="mb-4" style="border-width: 3px;border-color: orange;"></v-divider>
            <v-container>
                <span class="text-h5 d-block mb-5">{{ annonce.vehicule.marque }} {{ annonce.vehicule.modele }} {{ annonce.vehicule.finition }}</span>

                <p><v-chip v-if="annonce.a_type === 0" color="light-green lighten-1" style="font-size:12px;">Vente direct</v-chip></p>
                <p><v-chip v-if="annonce.a_type === 1" color="light-blue lighten-1" style="font-size:12px;">Dépôt / Vente</v-chip></p>

                <v-divider class="mb-4" style="border-width: 2px;"></v-divider>
                <span v-if="annonce.beginPrice" class="d-block mb-4">Prix de départ : <span class="text-h5">{{ annonce.beginPrice }}€</span></span>
                <v-divider v-if="annonce.beginPrice" class="mb-4" style="border-width: 2px;"></v-divider>
                <span v-if="annonce.reservePrice" class="d-block mb-4">Mettez fin à l'enchère pour : <span class="text-h5">{{ annonce.reservePrice }}€</span></span>
                <v-divider v-if="annonce.reservePrice" class="mb-4" style="border-width: 2px;"></v-divider>
                <span v-if="last_offre" class="d-block">Offre en cours : <span class="text-h5">{{ last_offre.montant }}€</span><span v-if="last_offre.agence.id == user.agence.id">(Vous)</span></span>
                <v-divider v-if="last_offre" class="mb-4" style="border-width: 2px;"></v-divider>

                <v-container v-if="annonce.sellPrice && annonce.status.id == 3" class="pa-0" style="position: relative;">
                    <span class="mr-4">Achat immédiat : <span class="text-h5">{{ annonce.sellPrice }}€</span></span>
                    <v-btn
                        class="primary float-right mr-3"
                    >
                        Achat immédiat
                    </v-btn>
                </v-container>

                <v-container v-if="(!annonce.sellPrice && annonce.status.id != 3) && (new Date(annonce.bidEnd).getTime() >= new Date().getTime()) && annonce.bidEnd != null" class="pa-0" style="position: relative;">
                    <v-form class="pa-0 d-flex flex-column align-center justify-space-between" width="100%" ref="form" @submit.prevent="addOffer">
                        <v-text-field
                            v-if="last_offre"
                            class="mr-5"
                            type="number"
                            style="width:100%"
                            v-model="newOffer"
                            :rules="[rulesOffre.required(), rulesOffre.min(last_offre.montant), rulesOffre.current(annonce.beginPrice)]"
                            label="Indiquez votre enchère max."
                            suffix="€"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-else
                            class="mr-5"
                            type="number"
                            style="width:100%"
                            v-model="newOffer"
                            :rules="[rulesOffre.required(), rulesOffre.current(annonce.beginPrice)]"
                            label="Indiquez votre enchère max."
                            suffix="€"
                            required
                        ></v-text-field>
                        <v-btn
                            class="primary float-right mr-3"
                            type="submit"
                        >
                            Enchérir maintenant (Engageant)
                        </v-btn>
                    </v-form>
                </v-container>
            </v-container>
            <v-divider class="mb-4" style="border-width: 2px;"></v-divider>
            <v-card elevation="1" class="pa-3">
                <span v-if="annonce.vehicule.date_first" class="text-body-2 d-block mb-2"><b>1ère mise en circulation :</b> {{ annonce.vehicule.date_first }}</span>
                <span v-if="annonce.vehicule.km" class="text-body-2 d-block mb-2"><b>Kilométrage :</b> {{ annonce.vehicule.km }} km</span>
                <span v-if="annonce.vehicule.energie" class="text-body-2 d-block mb-2"><b>Carburant :</b> {{ annonce.vehicule.energie }}</span>
                <span v-if="annonce.vehicule.pissance" class="text-body-2 d-block mb-2"><b>Puissance :</b> {{ annonce.vehicule.pissance }} CV</span>
                <span v-if="annonce.vehicule.moteur" class="text-body-2 d-block mb-2"><b>Cylindrée :</b> {{ annonce.vehicule.moteur }}</span>
                <span v-if="annonce.vehicule.boite_vitesse" class="text-body-2 d-block mb-2"><b>Boite de vitesse :</b> {{ annonce.vehicule.boite_vitesse }}</span>
                <span v-if="annonce.vehicule.carrosserie" class="text-body-2 d-block mb-2"><b>Carrosserie :</b> {{ annonce.vehicule.carrosserie }}</span>
                <span v-if="annonce.pays_immat" class="text-body-2 d-block mb-2"><b>Pays d'origine :</b> {{ annonce.pays_immat }}</span>
                <span v-if="annonce.color_ext" class="text-body-2 d-block mb-2"><b>Couleur extérieur :</b> {{ annonce.color_ext }}</span>
                <span v-if="annonce.color_int" class="text-body-2 d-block mb-2"><b>Couleur intérieur :</b> {{ annonce.color_int }}</span>
                <span v-if="annonce.type_siege" class="text-body-2 d-block mb-2"><b>Sellerie :</b> {{ annonce.type_siege }}</span>
                <span v-if="annonce.vehicule.nb_porte" class="text-body-2 d-block mb-2"><b>Nombre de portes :</b> {{ annonce.vehicule.nb_porte }}</span>
                <span v-if="annonce.vehicule.nb_place" class="text-body-2 d-block mb-2"><b>Nombre de places :</b> {{ annonce.vehicule.nb_place }}</span>
                <span v-if="annonce.vehicule.type_vehicule" class="text-body-2 d-block mb-2"><b>Type de véhicule :</b> {{ annonce.vehicule.type_vehicule }}</span>
                <span v-if="annonce.vehicule.cote_volant" class="text-body-2 d-block mb-2"><b>Coté du volant :</b> {{ annonce.vehicule.cote_volant }}</span>
            </v-card>
        </v-col>
        <v-divider class="mb-4" style="border-width: 2px;"></v-divider>
        <v-col md="12">
            
            <v-row>
                <v-col md="6" v-if="annonce.repa_list">
                    <p class="text-h5">Liste des Réparations à prévoir</p>
                    <v-list>
                        <v-list-item-group>
                            <v-list-item
                            v-for="(item, i) in JSON.parse(annonce.repa_list)"
                            :key="i"
                            >
                            <v-list-item-icon>
                                <v-icon>mdi-car-wrench</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item"></v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-col>
                <v-col md="6">
                    <div v-if="annonce.observations">
                        <p class="text-h5">Observations supplémentaires</p>
                        <p>{{ annonce.observations }}</p>
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-divider class="mb-4" style="border-width: 2px;"></v-divider>
        <v-col md="12">
            <p class="text-h5">Photos des dommages</p>
        </v-col>
        <v-row class="justify-center" style="width:100%">
            <v-col md="6">
                
                <v-carousel
                    height="500"
                    hide-delimiter-background
                    show-arrows-on-hover
                >
                    <v-carousel-item
                    v-for="(photo, i) in photosDamage"
                    :key="i"
                    eager
                    >
                        <v-sheet
                            height="100%"
                        >
                            <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                            >
                            <v-img v-if="photo" :src="'data:image/png;base64,' + photo.image" :data-src="'data:image/png;base64,' + photo.image" :data-caption="photo.description" :data-fancybox="'photo-'+annonce.vehicule.id" height="500"  style="cursor: pointer;"></v-img>
                            <div v-if="photo.description" class="pa-2" style="position:absolute;top:0;left:0;right:0;color:#fff;background: rgba(0,0,0,.5);">{{ photo.description }}</div>    
                        </v-row>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>
    </v-row>
</template>

<script>

    import axios from "axios";
    import { Fancybox } from "@fancyapps/ui";
    import "@fancyapps/ui/dist/fancybox/fancybox.css";

    export default {
        name: 'AnnoncesSingleView',

        components: {

        },

        data: () => ({
            slides: 0,
            annonce: [],
            photos: [],

            timer: "",
            last_offre: "",
            isFav: false,

            newOffer: "",

            photosGeneral: [],
            photosDamage: [],

            rulesOffre: {
                required(){
                    return v => (v || '').length > 0 || 'Veuillez renseigner ce champs'
                },
                
                min(min){
                    if(min){
                        return v => v > min || 'Votre offre doit être supérieur à ' + min + '€'
                    }
                    
                },

                current(current){
                    if(current){
                        return v => v > current || 'Votre offre doit être supérieur au prix de départ'
                    }
                    
                }
                
            },
        }),

        async created() {
            await this.getData();

            await this.checkBidEnd();

            Fancybox.bind("[data-fancybox]", {});
        },

        mounted: function () {

            this.timer = setInterval(() => {
                if(this.annonce) {
                    this.updateTimer();
                }
                
            }, 1000)
        },

        methods: {
            validate () {
                this.$refs.form.validate()
            },
            checkFavorite: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/favoris/' + this.$route.params.id + '/' + this.user.id, this.getHeaders());
                    if(response.data) {
                        this.isFav = response.data.id;
                    }else{
                        this.isFav = false;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            addFavorite: async function (annonce) {
                try {
                    const response = await axios.post(this.baseApiURL() + '/favoris', {
                        user: this.user.id,
                        annonce: annonce
                    }, this.getHeaders());
                    if(response) {
                        await this.getData();
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            removeFavorite: async function (favoris) {
                try {
                    const response = await axios.delete(this.baseApiURL() + '/favoris/'+ favoris, this.getHeaders());
                    if(response) {
                        await this.getData();
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getData: async function () {
                try {
                    await this.getAnnonce();
                    await this.getPhotoVehicule();
                    await this.updateTimer();
                    await this.getLastOffre();
                    await this.checkFavorite();
                } catch (e) {
                    console.log(e);
                }
            },
            getAnnonce: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/annonce/' + this.$route.params.id, this.getHeaders());
                    if(response){
                        this.annonce = response.data;

                        this.timer = "";
                        await this.updateTimer();

                        // const bResponse = await axios.get(this.baseApiURL() + '/vehicule/'+this.annonce.vehicule.id+'/photo', this.getHeaders());
                        // if(bResponse){
                        //     this.annonce.vehicule.allPhotos = bResponse.data;
                        //     console.log(this.annonce.vehicule)
                        // }
                    }
                    console.log(this.annonce)
                } catch (e) {
                    console.log(e);
                }
            },
            getLastOffre: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/last_offre/' + this.$route.params.id, this.getHeaders());
                    if(response) {
                        console.log(response.data)
                        if(response.data){
                            this.last_offre = response.data;
                        }else{
                            this.last_offre = null;
                        }
                       
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getPhotoVehicule: async function () {
                try {
                    //console.log(this.reprise.vehicule.id);
                    const response = await axios.get(this.baseApiURL() + '/vehicule/'+this.annonce.vehicule.id+'/photo', this.getHeaders());
                    if(response) {
                        this.photos = response.data;

                        this.photosGeneral = [];
                        this.photosDamage = [];
                        //console.log(this.photos)
                        //console.log(this.photos);
                        for(let photo of this.photos){
                            console.log(photo)
                           if(photo.isDamage){
                                this.photosDamage.push(photo)
                           }else{
                                this.photosGeneral.push(photo)
                           }
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            updateTimer: async function () {
                try {
                    this.timer = await this.enchereTimer(this.annonce)
                } catch (e) {
                    console.error(e);
                }
            },
            enchereTimer: async function (annonce) {
                try {

                    var dateFuture = new Date(annonce.bidEnd);
                    var dateNow = new Date();

                    var seconds = Math.floor((dateFuture - (dateNow))/1000);
                    var minutes = Math.floor(seconds/60);
                    var hours = Math.floor(minutes/60);
                    var days = Math.floor(hours/24);

                    hours = ((hours-(days*24)).toString().length == 1) ? "0" + (hours-(days*24)) : (hours-(days*24)) ;
                    minutes = ((minutes-(days*24*60)-(hours*60)).toString().length == 1) ? "0" + (minutes-(days*24*60)-(hours*60)) : (minutes-(days*24*60)-(hours*60));
                    seconds = ((seconds-(days*24*60*60)-(hours*60*60)-(minutes*60)).toString().length == 1) ? "0" + (seconds-(days*24*60*60)-(hours*60*60)-(minutes*60)) : (seconds-(days*24*60*60)-(hours*60*60)-(minutes*60));
                    //console.log(days + "J " + hours + ":" + minutes + ":" + seconds);
                    if(!annonce.bidEnd && annonce.status == 3){
                        return "Achat immédiat";
                    }else if(!annonce.bidEnd && annonce.status != 3){
                        return "Faite la première offre";
                    }else if(dateFuture > dateNow){
                        if(days <= 0){
                            return hours + ":" + minutes + ":" + seconds;
                        }else{
                            return days + "J " + hours + ":" + minutes + ":" + seconds;
                        }
                    }else{
                        if(annonce.status.id == 4){
                            // const response = await axios.put(this.baseApiURL() + '/annonce/'+ annonce.id, {
                            //     status: 5,
                            // }, this.getHeaders());

        

                            // console.log(response)
                        }
                        if(annonce.bidDuration){
                            return "Fini";
                        }else{
                            return "Masqué";
                        }
                    }

                    
                    
                    
                } catch (e) {
                    console.error(e);
                }
            },
            checkBidEnd: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/annonceUpd', this.getHeaders());
                    console.log('update')
                    if(response.data) {
                        return response.data;
                    }
                } catch (e) {
                    console.error(e);
                }
            },

            addOffer: async function () {
                try {
                    if(!this.newOffer){
                        this.validate();
                        return false;
                    }

                    if(!this.last_offre){
                        var duration = this.annonce.bidDuration;

                        var date = new Date();

                        date.setDate(date.getDate() + duration); 

                        const day = String(date.getDate()).padStart(2,"0");
                        const month = String(date.getMonth() + 1).padStart(2,"0"); // The month index starts from 0
                        const year = date.getFullYear();
                        var hours = String(date.getHours()).padStart(2,"0");
                        var minutes = String(date.getMinutes()).padStart(2,"0");
                        var seconds = String(date.getSeconds()).padStart(2,"0");

                        var endDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
                        console.log(endDate)
                        const response = await axios.put(this.baseApiURL() + '/annonce/' + this.annonce.id + '/end_enchere', {
                            bidEnd: endDate,
                            status: 4
                        }, this.getHeaders());

                        if(response){
                            const response2 = await axios.post(this.baseApiURL() + '/offre', {
                                agence: this.user.agence.id,
                                annonce: this.$route.params.id,
                                montant: this.newOffer,
                            }, this.getHeaders());

                            if(response2){
                                this.newOffer = '';
                                await this.getData();
                            }
                        }

                        
                    }else{
                        const response = await axios.post(this.baseApiURL() + '/offre', {
                            agence: this.user.agence.id,
                            annonce: this.$route.params.id,
                            montant: this.newOffer,
                        }, this.getHeaders());

                        if(response){
                            this.newOffer = '';
                            await this.getData();
                        }
                    }

                    
                    
                    //console.log(this.user)
                } catch (e) {
                    console.error(e);
                }
            },
        }
    }
</script>