import Vue from 'vue'

export default Vue.mixin({
    data: () => ({
        token: null,
        user: null
    }),
    watch: {
        token: 'setToken',
        user: 'setUser'
    },
    created() {
        this.restoreToken();
    },
    methods: {
        restoreToken: function () {
            if (localStorage.token) {
                this.token = localStorage.token
            }
            if (localStorage.user) {
                this.user = JSON.parse(localStorage.user)
            }
        },
        setToken: function () {
            localStorage.setItem('token', this.token);
        },
        setUser: function () {
            localStorage.setItem('user', JSON.stringify(this.user))
        },
        baseApiURL: function () {
            //return 'http://localhost:1337';
            return 'https://api.jevendsmonauto.com:49160';
        },
        emailSmtp: function () {
            return 'simon.chauchet@infoconception.fr';
        },
        mdpSmtp: function () {
            return "info2009Simon";
        },
        senderEmailAdmin: function () {
            return 'simon.chauchet@infoconception.fr';
        },
        selfURL: function () {
            //return 'http://localhost:8080';
            return 'https://plateforme.jevendsmonauto.com';
        },
        getHeaders: function () {
            return {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': this.token
                }
            }
        },
		getHeadersFront: function () {
            return {
                headers: {
                    'Content-type': 'application/json',
                }
            }
        },
        getFormHeaders: function () {
            return {
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'Authorization': this.token
                }
            }
        },
        getFormDataHeaders: function () {
            return {
                headers: {
                    'Content-type': 'multipart/form-data',
                    'Authorization': this.token
                }
            }
        }
    }
})
