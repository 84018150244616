<template>
    <v-card elevation="1">
      <v-card-title class="mb-3">
        Modifier l'agence
      </v-card-title>
      <v-divider class="mb-5"></v-divider>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="6" md="6">
              <v-switch v-model="currentAgence.status" label="Activer l'agence ?"></v-switch>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="currentAgence.label"
                  label="Libellé"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="currentAgence.raison"
                  label="Raison"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="currentAgence.address"
                  label="Adresse"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentAgence.cp"
                  label="Code postal"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentAgence.city"
                  label="Ville"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentAgence.country"
                  label="Pays"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentAgence.phone"
                  label="Téléphone"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentAgence.siret"
                  label="Siret"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentAgence.zone"
                  label="Zone de chalandise (km)"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-select
                  v-model="currentAgence.potentiel"
                  :items="potentielList"
                  item-value="value"
                  item-text="label"
                  label="Potentiel Agence"
                  required
              ></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          
          <v-row class="d-flex pa-4">
            <v-col>
              <v-btn text color="primary" @click="$emit('close')">Annuler</v-btn>
              <v-btn class="float-right" @click="editAgence"
                     :disabled="!currentAgence.label || !currentAgence.phone || !currentAgence.siret || !currentAgence.zone">
                     Modifier l'agence
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: 'EditAgenceDialog',
    props: ['activeAgence'],
    
    data() {
      return {
        currentAgence: [],
        potentielList :[
          {
            label: 'Nouveau',
            value: 'nouveau'
          },
          {
            label: 'Faible',
            value: 'faible'
          },
          {
            label: 'Fort',
            value: 'fort'
          },
        ],
        rules: [
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Adresse email invalide.'
          }
        ],
        rulesPw: [
          value => {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*.*[0-9]).+$/
            return pattern.test(value) && value.length >= 8 || 'Minimum 8 caractères avec 1 majuscule, 1 minuscule, 1 chiffre'
          }
        ],
        rulesCpw: [
          value => {
            return value == this.password || 'Vérifiez votre saisie'
          }
        ],
      }
    },
    watch: {
        activeAgence(val) {
            // Be sure to validate default values
            if(val !== '') {
                this.currentAgence = val
            }
        }
    },
    created() {
        
        this.currentAgence = this.activeAgence;
    },
    methods: {
        editAgence: async function () {
            if (this.currentAgence.status == null){
              this.currentAgence.status = 0;
            }else{
              this.currentAgence.status = 1;
            }
            
            try {
                const response = await axios.put(this.baseApiURL() + '/agence/'+ this.currentAgence.id + '/update', {
                  label: this.currentAgence.label,
                  raison: this.currentAgence.raison,
                  address: this.currentAgence.address,
                  cp: this.currentAgence.cp,
                  city: this.currentAgence.city,
                  country: this.currentAgence.country,
                  phone: this.currentAgence.phone,
                  siret: this.currentAgence.siret,
                  status: this.currentAgence.status,
                  zone: this.currentAgence.zone,
                  potentiel: this.currentAgence.potentiel,
                },this.getHeaders());
                
                if(response) {
                    this.$emit('valid');
                }
            } catch (e) {
                console.error(e);
            }
        },
    }
  }
  </script>
  