<template>
    <v-row class="mt-3">
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Liste des Clients
                    <v-spacer></v-spacer>
                    <v-btn @click="AddCustomerDialog = true">Ajouter un clients</v-btn>
                    <v-dialog v-model="AddCustomerDialog" max-width="800px">
                        <AddCustomerDialog v-on:close="closeAddCustomerDialog" v-on:abort="AddCustomerDialog = false"></AddCustomerDialog>
                    </v-dialog>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table 
                    :headers="headers"
                    :items="customers"
                    :search="search"
                >
                    <template v-slot:[`item.nbAnnonces`]="{ item }">
                        <v-chip color="#0091c6" text-color="white">{{item.nbAnnonces}}</v-chip>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="blue" class="mr-3" label text-color="white" v-bind="attrs" v-on="on" @click="activeCustomer = item;EditCustomerDialog = true">
                                    <v-icon>
                                    mdi-pencil
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Editer</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="red" label text-color="white" v-bind="attrs" v-on="on" @click="activeCustomer = item; DeleteCustomerDialog = true">
                                    <v-icon>
                                    mdi-trash-can-outline
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Supprimer</span>
                        </v-tooltip>
                    </template>
            
                </v-data-table>
                <v-dialog v-model="DeleteCustomerDialog" max-width="600px">
                    <DeleteCustomerDialog :email="activeCustomer.email" v-on:valid="deleteUser" v-on:close="DeleteCustomerDialog = false"></DeleteCustomerDialog>
                </v-dialog>

                <v-dialog v-model="EditCustomerDialog" max-width="800px">
                    <EditCustomerDialog :activeCustomer="activeCustomer" v-on:close="EditCustomerDialog = false" v-on:valid="refresh"></EditCustomerDialog>
                </v-dialog>

                <!-- <v-dialog v-model="EditCustomerDialog" max-width="800px">
                    <EditCustomerDialog :user="activeCustomer.id" v-on:close="EditCustomerDialog = false" v-on:validEmail="refreshEmail" v-on:valid="refresh"></EditCustomerDialog>
                </v-dialog> -->
                <SnackBar color="success" title="Client ajouté !" v-on:close="snackbarAddCustomer = false" :model="snackbarAddCustomer"></SnackBar>
                <SnackBar color="success" title="Client supprimé !" v-on:close="snackbarDeleteCustomer = false" :model="snackbarDeleteCustomer"></SnackBar>
                <SnackBar color="success" title="Client modifié !" v-on:close="snackbarEditCustomer = false" :model="snackbarEditCustomer"></SnackBar>

            </v-card>
        </v-col>
    </v-row>
</template>
  
<script>
    import axios from "axios";
    import DeleteCustomerDialog from "@/components/customers/DeleteCustomerDialog";
    import AddCustomerDialog from "@/components/customers/AddCustomerDialog";
    import EditCustomerDialog from "@/components/customers/EditCustomerDialog";
    import SnackBar from "@/components/partials/Snackbar";
  
    export default {
        name: 'CustomersView',
    
        components: {
            DeleteCustomerDialog,
            AddCustomerDialog,
            EditCustomerDialog,
            SnackBar,
        },

        data: () => ({
            // Components
            activeCustomer: [],
            DeleteCustomerDialog: false,
            snackbarDeleteCustomer: false,
            AddCustomerDialog: false,
            snackbarAddCustomer: false,
            EditCustomerDialog: false,
            snackbarEditCustomer: false,
            // Data table
            search: null,
            customers: [],
            headers: [
                { text: 'Prénom', value: 'firstname' },
                { text: 'Nom', value: 'lastname' },
                { text: 'Email', value: 'email' },
                { text: 'Téléphone', value: 'phone' },
                { text: 'Code postal', value: 'cp' },
                { text: 'Ville', value: 'city' },
                { text: 'Nb annones', value: 'nbAnnonces' },
                { text: 'Actions', value: 'actions' },
            ],
        }),
        async created() {
            document.title = 'Gestion des utilisateurs';
            await this.getCustomers();
            console.log(this.customers)
            if(this.$route.query.idCustomer){
                const response = await axios.get(this.baseApiURL() + '/customer/' + this.$route.query.idCustomer, this.getHeaders());
                this.activeCustomer = response.data;
                console.log(this.activeCustomer);
                this.EditCustomerDialog = true;
            }
        },
        methods: {
            getAnnoncesByCustomer: async function (id_customer) {
                try {
                    const response = await axios.get(this.baseApiURL() + '/annoncesByCustomer/' + id_customer, this.getHeaders());
                    
                    if(response) {
                        console.log(response.data)
                        return response.data;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getCustomers: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/customersWithCount', this.getHeaders());
                    this.AddCustomerDialog = false;
                    if(response) {
                        this.customers = response.data.rows;
                    }
                } catch (e) {
                    console.error(e);
                }
            },

            deleteUser: async function () {
                try {
                    const response = await axios.delete(this.baseApiURL() + '/customer/'+ this.activeCustomer.id, this.getHeaders());
                    if(response) {
                        await this.getCustomers();
                        this.DeleteCustomerDialog = false;
                        this.snackbarDeleteCustomer = true;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            closeAddCustomerDialog: async function () {
                await this.getCustomers();
                this.AddCustomerDialog = false;
                this.snackbarAddCustomer = true;
            },
            refresh: async function () {
                try {
                    await this.getCustomers();
                    this.EditCustomerDialog = false;
                    this.snackbarEditCustomer = true;
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }
</script>
  