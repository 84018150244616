<template>
    <v-card elevation="1">
      <v-card-title class="mb-3">
        Modifier le client
      </v-card-title>
      <v-divider class="mb-5"></v-divider>
      <v-card-text>
        <v-form>
            <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="currentCustomer.firstname"
                  label="Prénom"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="currentCustomer.lastname"
                  label="Nom"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentCustomer.email"
                  label="Adresse email"
                  :rules="rules"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentCustomer.contact_from"
                  label="De la part de (Email)"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentCustomer.phone"
                  label="Téléphone"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentCustomer.address"
                  label="Adresse postale"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentCustomer.cp"
                  label="Code postal"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentCustomer.city"
                  label="Ville"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentCustomer.country"
                  label="Pays"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea 
                  v-model="currentCustomer.note"
                  label="Note"
              ></v-textarea >
            </v-col>
          </v-row>
          <v-divider></v-divider>
          
          <v-row class="d-flex pa-4">
            <v-col>
              <v-btn text color="primary" @click="$emit('close')">Annuler</v-btn>
            
              <v-btn class="float-right" @click="editCustomer" 
                    :disabled="!currentCustomer.email || !currentCustomer.cp || !currentCustomer.firstname || !currentCustomer.lastname">
                     Modifier le compte
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: 'EditCustomerDialog',
    props: ['activeCustomer'],
    
    data() {
      return {
        currentCustomer: [],
        rules: [
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Adresse email invalide.'
          }
        ],
      }
    },
    watch: {
        activeCustomer(val) {
            // Be sure to validate default values
            if(val !== '') {
                this.currentCustomer = val
            }
        }
    },
    created() {
        
        this.currentCustomer = this.activeCustomer;
    },
    methods: {
        editCustomer: async function () {
            try {
                const response = await axios.put(this.baseApiURL() + '/customer/'+ this.currentCustomer.id + '/update', {
                  firstname: this.currentCustomer.firstname,
                  lastname: this.currentCustomer.lastname,
                  address: this.currentCustomer.address,
                  cp: this.currentCustomer.cp,
                  city: this.currentCustomer.city,
                  country: this.currentCustomer.country,
                  email: this.currentCustomer.email,
                  contact_from: this.currentCustomer.contact_from,
                  phone: this.currentCustomer.phone,
                  note: this.currentCustomer.note,
                },this.getHeaders());
                
                if(response) {
                    this.$emit('valid');
                }
            } catch (e) {
                console.error(e);
            }
        },
    }
  }
  </script>
  