<template>
    <v-card elevation="1">
      <v-card-title class="mb-3">
        Ajouter une agence
      </v-card-title>
      <v-divider class="mb-5"></v-divider>
      <v-card-text>
        <v-form>
            <v-row>
            <v-col cols="6" md="6">
              <v-switch v-model="status" label="Activer l'agence ?"></v-switch>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="label"
                  label="Libellé"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="raison"
                  label="Raison"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="address"
                  label="Adresse"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="cp"
                  label="Code postal"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="city"
                  label="Ville"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="country"
                  label="Pays"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="phone"
                  label="Téléphone"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="siret"
                  label="Siret"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="zone"
                  label="Zone de chalandise (km)"
                  required
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-select
                  v-model="potentiel"
                  :items="potentielList"
                  item-value="value"
                  item-text="label"
                  label="Potentiel Agence"
                  required
              ></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          
          <v-row class="d-flex pa-4">
            <v-col>
              <v-btn text color="primary" @click="$emit('abort')">Annuler</v-btn>
              <v-btn class="float-right" @click="addAgence"
                :disabled="!label || !raison || !phone || !siret || !zone" >
                Créer l'agence
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: 'AddUserDialog',
    data() {
      return {
        label: null,
        raison: null,
        address: null,
        cp: null,
        city: null,
        country: null,
        phone: null,
        siret: null,
        status: null,
        zone: null,
        potentiel: null,
        potentielList :[
          {
            label: 'Nouveau',
            value: 'nouveau'
          },
          {
            label: 'Faible',
            value: 'faible'
          },
          {
            label: 'Fort',
            value: 'fort'
          },
        ],
        rules: [
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Adresse email invalide.'
          }
        ],
        rulesPw: [
          value => {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*.*[0-9]).+$/
            return pattern.test(value) && value.length >= 8 || 'Minimum 8 caractères avec 1 majuscule, 1 minuscule, 1 chiffre'
          }
        ],
        rulesCpw: [
          value => {
            return value == this.password || 'Vérifiez votre saisie'
          }
        ],
      }
    },
    created() {
    },
    methods: {
        addAgence: async function () {
          try {
          this.success = false;
          if (this.status == null){
            this.status = 0;
          }else{
            this.status = 1;
          }
          const response = await axios.post(this.baseApiURL() + '/agence', {
              label: this.label,
              raison: this.raison,
              address: this.address,
              cp: this.cp,
              city: this.city,
              country: this.country,
              phone: this.phone,
              siret: this.siret,
              status: this.status,
              zone: this.zone,
              potentiel: this.potentiel,
              
          }, this.getHeaders());
          if (response) {
              if (!response.data.register) {
                  this.message = response.data.message;
                  this.snackbarError = true;
              } else {
              //   const response2 = await axios.post(this.baseApiURL() + '/email/send', {
              //     to: this.email,
              //     subject: 'Un compte vous à été créé sur la plateforme de l\'École de Formations Maritimes',
              //     html: '<p>Bonjour,</p><br>' +
              //         '<p> Un administrateur de la plateforme de l\'École de formations maritimes vous a créé un compte pour que vous puissiez remplir votre dossier d\'inscription !</p>' +
              //         '<p>Rendez vous sur <a href="' + this.selfURL() + '">' + this.selfURL() +'</a> pour vous connecter avec les identifiants suivants : </p><br>' +
              //         '<p>Nom d\'utilisateur : ' + this.email + '</p>' +
              //         '<p>Mot de passe : ' + this.password + '</p><br>' +
              //         '<p>A bientôt</p>' +
              //         '<p>Administration Ecole des Formations Maritimes</p>' +
              //         '<img width="250px" height="200px" src="' + this.selfURL() + '/images/logo-efm-2021.png" alt="Logo">'
              //   }, this.getHeaders());
              //   if(response2) {
              //    this.success = true;
              //   }
              }
              this.label = null;
              this.raison = null;
              this.address = null;
              this.cp = null;
              this.city = null;
              this.country = null;
              this.phone = null;
              this.siret = null;
              this.status = null;
              this.zone = null;
              this.potentiel = null;
              this.$emit('close');
  
          }
          } catch (e) {
            console.error(e);
          }
      },
    }
  }
  </script>
  