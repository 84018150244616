<template>
    <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir archiver cette annonce ?</v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">Annuler</v-btn>
            <v-btn color="orange darken-1" text @click="$emit('valid')">Archiver</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
  name: 'ArchiveAnnonceDialog',
  props: [],
}
</script>
