<template>
    <v-row class="mt-3"> 
        <v-btn v-if="!filters" elevation="2" rigth icon fab color="primary" style="margin-bottom:-75px;margin-top:15px;background:white;position: sticky;top:75px;left: 95%;z-index: 999;transition-duration:.2s;" @click="filters = !filters">
            <v-icon>mdi-filter</v-icon>
        </v-btn>
        <v-navigation-drawer
            v-model="filters"
            fixed
            right
            app
            :width="350"
        >
        <!-- <v-btn elevation="2" icon fab color="primary" style="background:white;position: fixed;left: -100px;z-index: 999;transition-duration:.2s;" @click="filters = !filters"><v-icon>mdi-filter</v-icon></v-btn> -->
        <v-card>
            <v-card-title>
                <v-btn elevation="2" rigth icon color="error" style="position: absolute;right: 20px;" @click="filters = !filters"><v-icon>mdi-close</v-icon></v-btn><br>
                Filtres
            </v-card-title>
            <v-divider></v-divider>
            <v-container>
                <v-btn class="mb-2" color="primary" @click="addSavedSearchDialog = true" ><v-icon>mdi-content-save-outline</v-icon> Enregistrer la recherche</v-btn>
                <v-btn color="primary" @click="savedSearchDialog = true" ><v-icon>mdi-format-list-bulleted</v-icon> Voir les recherches</v-btn>
            </v-container>
            <v-divider></v-divider>
            <v-container>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in selectedFilters.status_annonce" :key="filter" @click="removeFilters(selectedFilters.status_annonce,filter)">
                    <span v-if="filter == 1">À approuver</span>
                    <span v-if="filter == 2">Annonce approuvée</span>
                    <span v-if="filter == 3">Achat immédiat</span>
                    <span v-if="filter == 4">Enchère en cours</span>
                    <span v-if="filter == 5">Validation client</span>
                    <span v-if="filter == 6">Vendu</span>
                    <v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in selectedFilters.etats" :key="filter" @click="removeFilters(selectedFilters.etats,filter)"><span v-if="filter">{{ filter }}</span><span v-else>États non renseigné</span> <v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in selectedFilters.marques" :key="filter" @click="removeFilters(selectedFilters.marques,filter)">{{ filter }} <v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" @click="selectedFilters.years = [min, max]; updateFilters()">Entre {{ selectedFilters.years[0] }} et  {{ selectedFilters.years[1] }}<v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" @click="selectedFilters.km = [minKm, maxKm]; updateFilters()">Entre {{ selectedFilters.km[0] }} et  {{ selectedFilters.km[1] }} Km<v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in selectedFilters.carburants" :key="filter" @click="removeFilters(selectedFilters.carburants,filter)"><span v-if="filter">{{ filter }}</span><span v-else>Carburant non renseigné</span> <v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in selectedFilters.cps" :key="filter" @click="removeFilters(selectedFilters.cps,filter)">{{ filter }} <v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in selectedFilters.carrosseries" :key="filter" @click="removeFilters(selectedFilters.carrosseries,filter)"><span v-if="filter">{{ filter }}</span><span v-else>Carrosserie non renseigné</span> <v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in selectedFilters.boites" :key="filter" @click="removeFilters(selectedFilters.boites,filter)"><span v-if="filter">{{ filter }}</span><span v-else>Boite non renseigné</span> <v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" @click="selectedFilters.puissances = [minPuissances, maxPuissances]; updateFilters()">Entre {{ selectedFilters.puissances[0] }} et  {{ selectedFilters.puissances[1] }} CV<v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in selectedFilters.portes" :key="filter" @click="removeFilters(selectedFilters.portes,filter)"><span v-if="filter">{{ filter }} Portes</span><span v-else>Nb portes non renseigné</span> <v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in selectedFilters.places" :key="filter" @click="removeFilters(selectedFilters.places,filter)"><span v-if="filter">{{ filter }} Places</span><span v-else>Nb places non renseigné</span> <v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in selectedFilters.type_vehicule" :key="filter" @click="removeFilters(selectedFilters.type_vehicule,filter)"><span v-if="filter">{{ filter }} Type véhicule</span><span v-else>Type véhicule non renseigné</span> <v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in selectedFilters.cote_volant" :key="filter" @click="removeFilters(selectedFilters.cote_volant,filter)"><span v-if="filter">Volant à {{ filter }}</span><span v-else>Coté volant non renseigné</span> <v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in selectedFilters.colors" :key="filter" @click="removeFilters(selectedFilters.colors,filter)"><span v-if="filter">{{ filter }}</span><span v-else>Nb portes non renseigné</span> <v-icon class="ml-1" color="red">mdi-close-circle-outline</v-icon></v-chip>
                
            </v-container>

            <v-divider></v-divider>

            <v-container>
                <span v-if="switchAnnonce" class="ml-2"><v-checkbox class="pt-0 mt-0 ml-4 mr-4 d-flex flex-row align-center" hide-details v-on:change="updateFilters" v-model="hideAnnonces" label="Cacher mes annonces"></v-checkbox></span>
                
                <span class="ml-2"><v-checkbox class="pt-0 mt-0 ml-4 mr-4 d-flex flex-row align-center" hide-details v-on:change="updateFilters" v-model="seeFav">
                    <template v-slot:label>
                        <span v-if="!seeFav" class="d-inline-flex align-center"><v-icon>mdi-star-outline</v-icon> Voir mes favoris</span>
                        <span v-else ><v-icon color="yellow" class="d-inline-flex align-center">mdi-star</v-icon> Voir mes favoris</span>
                        </template>
                </v-checkbox></span>
            </v-container>

            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>Status annonces</v-list-item-title>
                    </template>
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-model="selectedFilters.status_annonce" label="À approuver" value="1"></v-checkbox>
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-model="selectedFilters.status_annonce" label="Annonce approuvée" value="2"></v-checkbox>
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-model="selectedFilters.status_annonce" label="Achat immédiat" value="3"></v-checkbox>
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-model="selectedFilters.status_annonce" label="Enchère en cours" value="4"></v-checkbox>
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-model="selectedFilters.status_annonce" label="Validation client" value="5"></v-checkbox>
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-model="selectedFilters.status_annonce" label="Vendu" value="6"></v-checkbox>
                </v-list-group>
            </v-list>
            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>Code postal</v-list-item-title>
                    </template>
                    <!-- <span>{{ selectedMarques }}</span> -->
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-for="cp in cps" :key="cp[0]" v-model="selectedFilters.cps" :label="cp[0] + ' ('+cp[1]+')'" :value="cp[0]"></v-checkbox>
                </v-list-group>
            </v-list>
            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>États</v-list-item-title>
                    </template>
                    <!-- {{ etats }}<br>
                    {{ c_etats }} -->
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-for="etat in etats" :key="etat[0]" v-model="selectedFilters.etats" :label="etat[0] ? etat[0] + ' ('+etat[1]+')' : 'Non renseigné'" :value="etat[0]"></v-checkbox>
                </v-list-group>
            </v-list>
            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>Marques</v-list-item-title>
                    </template>
                    <!-- <span class="d-block">Marques</span> -->
                    <!-- <span>{{ selectedMarques }}</span> -->
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-for="marque in marques" :key="marque[0]" v-model="selectedFilters.marques" :label="marque[0] + ' ('+marque[1]+')'" :value="marque[0]"></v-checkbox>
                </v-list-group>
            </v-list>
            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>Années</v-list-item-title>
                    </template>
                    <!-- <span>{{ selectedMarques }}</span> -->
                    <v-range-slider
                        v-model="selectedFilters.years"
                        :max="new Date().getFullYear()"
                        :min="1900"
                        hide-details
                        class="years_range align-center ml-4 mr-4 d-block"
                        @change="updateFilters"
                    >
                        <template v-slot:prepend>
                            <v-container class="d-flex flex-row align-center justify-space-between pa-0">
                                <v-text-field
                                    :value="selectedFilters.years[0]"
                                    class="mt-0 pt-0 mr-4"
                                    hide-details
                                    single-line
                                    type="number"
                                    style="width: 60px"
                                    @change="$set(selectedFilters.years, 0, $event);updateFilters();"
                                ></v-text-field>
                                à
                                <v-text-field
                                    :value="selectedFilters.years[1]"
                                    class="mt-0 pt-0 ml-4"
                                    hide-details
                                    single-line
                                    type="number"
                                    style="width: 60px"
                                    @change="$set(selectedFilters.years, 1, $event);updateFilters();"
                                ></v-text-field>
                            </v-container>
                        </template>
                        <template v-slot:append>
                        
                        </template>
                    </v-range-slider>
                    <!-- <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-for="year in years" :key="year[0]" v-model="selectedFilters.years" :label="year[0] + ' ('+year[1]+')'" :value="year[0]"></v-checkbox> -->
                </v-list-group>
            </v-list>
            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>Kilométrage</v-list-item-title>
                    </template>
                    <!-- <span>{{ selectedMarques }}</span> -->
                    <v-range-slider
                        v-model="selectedFilters.km"
                        :max="maxKm"
                        :min="minKm"
                        hide-details
                        class="years_range align-center ml-4 mr-4 d-block"
                        @change="updateFilters"
                    >
                        <template v-slot:prepend>
                            <v-container class="d-flex flex-row align-center justify-space-between pa-0">
                                <v-text-field
                                    :value="selectedFilters.km[0]"
                                    class="mt-0 pt-0 mr-4"
                                    hide-details
                                    single-line
                                    type="number"
                                    style="width: 60px"
                                    @change="$set(selectedFilters.km, 0, $event);updateFilters();"
                                    suffix="Km"
                                ></v-text-field>
                                à
                                <v-text-field
                                    :value="selectedFilters.km[1]"
                                    class="mt-0 pt-0 ml-4"
                                    hide-details
                                    single-line
                                    type="number"
                                    style="width: 60px"
                                    @change="$set(selectedFilters.km, 1, $event);updateFilters();"
                                    suffix="Km"
                                ></v-text-field>
                            </v-container>
                        </template>
                        <template v-slot:append>
                        
                        </template>
                    </v-range-slider>
                    <!-- <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-for="year in years" :key="year[0]" v-model="selectedFilters.years" :label="year[0] + ' ('+year[1]+')'" :value="year[0]"></v-checkbox> -->
                </v-list-group>
            </v-list>
            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>Carburant</v-list-item-title>
                    </template>
                    <!-- <span>{{ selectedMarques }}</span> -->
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-for="carburant in carburants" :key="carburant[0]" v-model="selectedFilters.carburants" :label="carburant[0] ? carburant[0] + ' ('+carburant[1]+')' : 'Non renseigné ('+carburant[1]+')'" :value="carburant[0]"></v-checkbox>
                </v-list-group>
            </v-list>
            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>Boite de vitesse</v-list-item-title>
                    </template>
                    <!-- <span>{{ selectedMarques }}</span> -->
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-for="boite in boites" :key="boite[0]" v-model="selectedFilters.boites" :label="boite[0] ? boite[0] + ' ('+boite[1]+')' : 'Non renseigné ('+boite[1]+')'" :value="boite[0]"></v-checkbox>
                </v-list-group>
            </v-list>
            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>Carrosserie</v-list-item-title>
                    </template>
                    <!-- <span>{{ selectedMarques }}</span> -->
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-for="carrosserie in carrosseries" :key="carrosserie[0]" v-model="selectedFilters.carrosseries" :label="carrosserie[0] ? carrosserie[0] + ' ('+carrosserie[1]+')' : 'Non renseigné ('+carrosserie[1]+')'" :value="carrosserie[0]"></v-checkbox>
                </v-list-group>
            </v-list>
            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>Puissance</v-list-item-title>
                    </template>
                    <!-- <span>{{ selectedMarques }}</span> -->
                    <v-range-slider
                        v-model="selectedFilters.puissances"
                        :max="maxPuissances"
                        :min="minPuissances"
                        hide-details
                        class="years_range align-center ml-4 mr-4 d-block"
                        @change="updateFilters"
                    >
                        <template v-slot:prepend>
                            <v-container class="d-flex flex-row align-center justify-space-between pa-0">
                                <v-text-field
                                    :value="selectedFilters.puissances[0]"
                                    class="mt-0 pt-0 mr-4"
                                    hide-details
                                    single-line
                                    type="number"
                                    style="width: 60px"
                                    @change="$set(selectedFilters.puissances, 0, $event);updateFilters();"
                                    suffix="CV"
                                ></v-text-field>
                                à
                                <v-text-field
                                    :value="selectedFilters.puissances[1]"
                                    class="mt-0 pt-0 ml-4"
                                    hide-details
                                    single-line
                                    type="number"
                                    style="width: 60px"
                                    @change="$set(selectedFilters.puissances, 1, $event);updateFilters();"
                                    suffix="CV"
                                ></v-text-field>
                            </v-container>
                        </template>
                        <template v-slot:append>
                        
                        </template>
                    </v-range-slider>
                    <!-- <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-for="year in years" :key="year[0]" v-model="selectedFilters.years" :label="year[0] + ' ('+year[1]+')'" :value="year[0]"></v-checkbox> -->
                </v-list-group>
            </v-list>
            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>Portes</v-list-item-title>
                    </template>
                    <!-- <span>{{ selectedMarques }}</span> -->
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-for="porte in portes" :key="porte[0]" v-model="selectedFilters.portes" :label="porte[0] ? porte[0] + ' ('+porte[1]+')' : 'Non renseigné ('+porte[1]+')'" :value="porte[0]"></v-checkbox>
                </v-list-group>
            </v-list>
            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>Places</v-list-item-title>
                    </template>
                    <!-- <span>{{ selectedMarques }}</span> -->
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-for="place in places" :key="place[0]" v-model="selectedFilters.places" :label="place[0] ? place[0] + ' ('+place[1]+')' : 'Non renseigné ('+place[1]+')'" :value="place[0]"></v-checkbox>
                </v-list-group>
            </v-list>
            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>Type véhicule</v-list-item-title>
                    </template>
                    <!-- <span>{{ selectedMarques }}</span> -->
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-for="type_v in type_vehicule" :key="type_v[0]" v-model="selectedFilters.type_vehicule" :label="type_v[0] ? type_v[0] + ' ('+type_v[1]+')' : 'Non renseigné ('+type_v[1]+')'" :value="type_v[0]"></v-checkbox>
                </v-list-group>
            </v-list>
            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>Coté volant</v-list-item-title>
                    </template>
                    <!-- <span>{{ selectedMarques }}</span> -->
                    <v-checkbox class="mt-0 ml-4 mr-4" v-on:change="updateFilters" v-for="cote_v in cote_volant" :key="cote_v[0]" v-model="selectedFilters.cote_volant" :label="cote_v[0] ? cote_v[0] + ' ('+cote_v[1]+')' : 'Non renseigné ('+cote_v[1]+')'" :value="cote_v[0]"></v-checkbox>
                </v-list-group>
            </v-list>
            <v-list>
                <v-list-group style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
                    <template v-slot:activator>
                        <v-list-item-title>Couleur</v-list-item-title>
                    </template>
                    <!-- <span>{{ selectedMarques }}</span> -->
                    <v-checkbox class="mt-0 ml-4 mr-4 d-flex flex-row align-center" v-on:change="updateFilters" v-for="color in colors" :key="color[0]" v-model="selectedFilters.colors" :label="color[0] ? color[0] + ' ('+color[1]+')' : 'Non renseigné ('+color[1]+')'" :value="color[0]">
                        <template v-slot:label>
                            <span v-if="color[2] == 'white'" class="mr-2" style="height: 15px;width: 15px;border-radius: 50%;border:1px solid black" :style="{background: color[2]}"></span>
                            <span v-else class="mr-2" style="height: 15px;width: 15px;border-radius: 50%;" :style="{background: color[2]}"></span>
                            {{ color[0] ? color[0] + ' ('+color[1]+')' : 'Non renseigné ('+color[1]+')' }}
                        </template>
                    </v-checkbox>
                </v-list-group>
            </v-list>
        </v-card>
        </v-navigation-drawer>
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title class="d-inline-block">
                    Liste des Annonces
                </v-card-title>
                <p class="d-inline-flex flex-row align-center">
                    <span v-if="!switchAnnonce" class="mr-2"><b>Mes annonces</b></span><span v-else class="mr-2">Mes annonces</span>
                    <v-switch
                    class="d-inline-block mt-0 pt-0"
                    v-model="switchAnnonce"
                    @change="updateFilters();!switchAnnonce ? hideAnnonces = false : hideAnnonces = hideAnnonces"
                    hide-details
                    ></v-switch>
                    <span v-if="switchAnnonce" class="ml-2"><b>Toutes les annonces</b></span><span v-else class="ml-2">Toutes les annonces</span>

                    
                </p>
                <v-divider></v-divider>
                <v-row class="pa-4">
                    <v-skeleton-loader
                        :loading="loading"
                        transition="scale-transition"
                        width="100%"
                        height="500"
                        type="table-tbody"
                         style="width:100%"
                    >
                        <v-data-table 
                            :items="annonces"
                            :footer-props="{itemsPerPageText:'Véhicules par page :'}"
                            
                        >
                        
                            <template v-slot:top="{ pagination, options, updateOptions }">
                                <v-data-footer 
                                :pagination="pagination" 
                                :options="options"
                                @update:options="updateOptions"
                                items-per-page-text="Véhicules par page :"/>
                            </template>
                            <template v-slot:[`item`]="{ item }">
                                <v-row :style="(!item.bidDuration && !item.beginPrice && !item.reservePrice && item.user.role != user.role.id) ? 'opacity:.4;pointer-events: none;' : ''">
                                    <v-col class="mt-4" md="12"  style="width:100%">
                                        <v-card class="pl-3">
                                            <v-row>
                                                <v-col md="4">
                                                    <!-- <v-img v-if="annonce.vehicule.mainPhoto" :src="'data:image/png;base64,' + annonce.vehicule.mainPhoto" :data-src="'data:image/png;base64,' + annonce.vehicule.mainPhoto" height="200"></v-img> -->
                                                
                                                    <v-carousel
                                                        height="250"
                                                        hide-delimiter-background
                                                        hide-delimiters 
                                                        show-arrows-on-hover
                                                    >
                                                        <v-carousel-item
                                                        v-for="(photo, i) in item.vehicule.allPhotos"
                                                        :key="i"
                                                        eager
                                                        >
                                                            <v-sheet
                                                                height="100%"
                                                            >
                                                                <v-row
                                                                class="fill-height"
                                                                align="center"
                                                                justify="center"
                                                                >
                                                                <v-img v-if="photo" :src="'data:image/png;base64,' + photo.image" :data-src="'data:image/png;base64,' + photo.image" :data-caption="photo.description" :data-fancybox="'photo-'+item.vehicule.id" height="250" style="cursor: pointer;width:250px"></v-img>
                                                                </v-row>
                                                            </v-sheet>
                                                        </v-carousel-item>
                                                    </v-carousel>
                                                </v-col>
                                                <v-col md="8">
                                                    <v-container style="position: relative;">
                                                        <span class="text-h6 d-block mb-5">{{ item.vehicule.marque }} {{ item.vehicule.modele }} {{ item.vehicule.finition }} <v-btn :href="'/annonce_edit/' + item.id" small class="primary ml-2" v-if="user.agence.id == item.user.agence"><v-icon small>mdi-pencil</v-icon></v-btn></span>
                                                        <p><v-chip v-if="item.a_type === 0" color="light-green lighten-1" style="font-size:12px;">Vente direct</v-chip></p>
                                                        <p><v-chip v-if="item.a_type === 1" color="light-blue lighten-1" style="font-size:12px;">Dépôt / Vente</v-chip></p>
                                                        <v-row class="d-flex flex-row align-end">
                                                            <v-col md="7" class="">

                                                                <span v-if="item.status.id == 3 && item.sellPrice" class="mb-2 mt-2 text d-inline-flex flex-row blue--text text--darken-2"><v-icon class="mr-2" color="blue darken-2">mdi-cash</v-icon> Achat immédiat pour <span style="color:black" class="ml-2 font-weight-bold">{{ item.sellPrice }}€</span></span>
                                                                <span v-else-if="item.a_type == 1" class="mb-2 mt-2 text d-inline-flex flex-row blue--text text--darken-2"></span>
                                                                <span v-else eclass="mb-2 mt-2 text d-inline-flex flex-row" style="color:orange"><v-icon class="mr-2" color="orange">mdi-gavel</v-icon> Fin d'enchère dans <span style="color:black" class="ml-2 font-weight-bold">{{ item.timer }}</span></span>
                                                                
                                                            </v-col>
                                                            <v-col md="5" class="text-right">
                                                                <span v-if="item.last_offre" class="text-body-2 d-block">Offre en cours : <b>{{ item.last_offre }}€</b></span>
                                                                <span v-if="item.beginPrice" class="mb-2 text-body-2 d-block">Prix de départ : <b>{{ item.beginPrice }}€</b></span>
                                                            </v-col>
                                                        </v-row>
                                                        
                                                        <v-divider></v-divider>
                                                        <v-container style="">
                                                            <v-row>
                                                                <v-col md="7">
                                                                    <span v-if="item.reference" class="text-body-2 d-block"><b>Référence :</b> {{ item.reference }}</span>
                                                                    <span v-if="item.vehicule.km" class="text-body-2 d-block"><b>Kilométrage :</b> {{ item.vehicule.km }} km</span>
                                                                    <span v-if="item.vehicule.date_first" class="text-body-2 d-block"><b>1ère mise en circulation :</b> {{ item.vehicule.date_first }}</span>
                                                                    <span v-if="item.vehicule.pissance" class="text-body-2 d-block"><b>Puissance :</b> {{ item.vehicule.pissance }} CV</span>
                                                                    <span v-if="item.vehicule.energie" class="text-body-2 d-block"><b>Carburant :</b> {{ item.vehicule.energie }}</span>
                                                                    <span v-if="item.vehicule.boite_vitesse" class="text-body-2 d-block"><b>Boite de vitesse :</b> {{ item.vehicule.boite_vitesse }}</span>
                                                                    <span v-if="item.vehicule.puissance" class="text-body-2 d-block"><b>Puissance :</b> {{ item.vehicule.puissance }} CV</span>
                                                                    <!-- <span class="text d-block">{{ annonce.customer.cp }}</span>
                                                                    <span class="text d-block">{{ annonce.etat_vehicule }}</span> -->
                                                                    <!-- <span>{{ annonce.user.agence }}</span> -->
                                                                </v-col>
                                                                <v-col md="5" class="d-flex justify-end align-end" v-if="((item.bidDuration && item.beginPrice && item.reservePrice) || item.user.role == user.role.id)">
                                                                    <v-btn color="primary" :href="'/annonce/' + item.id">Plus d'info</v-btn>
                                                                    <v-btn v-if="!item.isFav" color="ml-2" @click="addFavorite(item.id)"><v-icon>mdi-star-outline</v-icon>Mes favoris</v-btn>
                                                                    <v-btn v-if="item.isFav" color="ml-2 error" @click="removeFavorite(item.isFav)"><v-icon>mdi-star-off-outline</v-icon>Supprimer des favoris</v-btn>
                                                                </v-col>
                                                            </v-row>
                                                            
                                                            <!-- Etat annonce -->
                                                            <v-chip v-if="item.status.id === 1" color="yellow" style="position: absolute;top:12px;right: 12px;">{{item.status.label}}</v-chip>
                                                            <v-chip v-if="item.status.id === 2" color="light-blue lighten-1" style="position: absolute;top:12px;right: 12px;">{{item.status.label}}</v-chip>
                                                            <v-chip v-if="item.status.id === 3" color="light-green lighten-1" style="position: absolute;top:12px;right: 12px;">{{item.status.label}}</v-chip>
                                                            <v-chip v-if="item.status.id === 4" color="pink accent-2" style="position: absolute;top:12px;right: 12px;">{{item.status.label}}</v-chip>
                                                            <v-chip v-if="item.status.id === 5" color="orange darken-1" style="position: absolute;top:12px;right: 12px;">{{item.status.label}}</v-chip>
                                                            <v-chip v-if="item.status.id === 6" color="blue-grey lighten-1" style="position: absolute;top:12px;right: 12px;">{{item.status.label}}</v-chip>
                                                            <v-chip v-if="item.status.id === 7" color="deep-purple lighten-2" style="position: absolute;top:12px;right: 12px;">{{item.status.label}}</v-chip>
                                                        </v-container>
                                                    </v-container>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-skeleton-loader>
                </v-row>

                <v-dialog v-model="savedSearchDialog" max-width="800px">
                    <SavedSearchDialog @searchData="selectedFilters = $event;updateFilters();savedSearchDialog = false" v-on:close="savedSearchDialog = false"></SavedSearchDialog>
                </v-dialog>

                <v-dialog v-model="addSavedSearchDialog" max-width="800px">
                    <AddSavedSearchDialog @saveSearch="addSavedSearchDialog = false;snackbarSaveSearchSnack = true" :selectedFilters="selectedFilters" v-on:close="addSavedSearchDialog = false"></AddSavedSearchDialog>
                </v-dialog>

            </v-card>
        </v-col>
        <SnackBar style="position:fixed;top:70px" color="success" title="Recherche mise à jour !" v-on:close="snackbarSaveSearchSnack = false" :model="snackbarSaveSearchSnack"></SnackBar>
    </v-row>
</template>

<style>
    .years_range .v-input__prepend-outer {
        display: block;
    }
</style>

<script>
    import axios from "axios";
    import { Fancybox } from "@fancyapps/ui";
    import "@fancyapps/ui/dist/fancybox/fancybox.css";
    import SavedSearchDialog from "@/components/annonces/SavedSearchDialog";
    import AddSavedSearchDialog from "@/components/annonces/AddSavedSearchDialog";
    import SnackBar from "@/components/partials/Snackbar";

    export default {
        name: 'AnnoncesListView',

        components: {
            SavedSearchDialog,
            AddSavedSearchDialog,
            SnackBar,

            
        },

        data: () => ({
            loading: true,
            filters: true,

            savedSearchDialog: false,
            addSavedSearchDialog: false,
            snackbarSaveSearchSnack: false,

            annonces: [],
            mainPhoto: [],

            switchAnnonce: true,
            hideAnnonces: false,
            seeFav: false,

            timer: "",

            newAnnonces: [],

            // filters list
            
            cps: [],
            allCps: [],
            c_cps: [],

            etats: [],
            allEtats: [],
            c_etats: [],

            marques: [],
            allMarques: [],
            c_marques: [],

            min: 2000,
            max: 2025,
            years: [],
            allYears: [],
            c_years: [],

            minKm: 0,
            maxKm: null,
            km: [],
            allKm: [],
            c_km: [],

            carburants: [],
            allCarburants: [],
            c_carburants: [],

            boites: [],
            allBoites: [],
            c_boites: [],

            carrosseries: [],
            allCarrosseries: [],
            c_carrosseries: [],

            minPuissances: 0,
            maxPuissances: null,
            puissances: [],
            allPuissances: [],
            c_puissances: [],

            portes: [],
            allPortes: [],
            c_portes: [],

            places: [],
            allPlaces: [],
            c_places: [],

            type_vehicule: [],
            allType_vehicule: [],
            c_type_vehicule: [],

            cote_volant: [],
            allCote_volant: [],
            c_cote_volant: [],

            colors: [],
            allColors: [],
            c_colors: [],
            color_ext_list: [
                    {
                        label: "Noir",
                        color: "black"
                    },
                    {
                        label: "Gris",
                        color: "grey"
                    },
                    {
                        label: "Argent",
                        color: "silver"
                    },
                    {
                        label: "Violet",
                        color: "violet"
                    },
                    {
                        label: "Bleu",
                        color: "blue"
                    },
                    {
                        label: "Vert",
                        color: "green"
                    },
                    {
                        label: "Beige",
                        color: "beige"
                    },
                    {
                        label: "Or",
                        color: "gold"
                    },
                    {
                        label: "Jaune",
                        color: "yellow"
                    },
                    {
                        label: "Orange",
                        color: "orange"
                    },
                    {
                        label: "Rouge",
                        color: "red"
                    },
                    {
                        label: "Marron",
                        color: "brown"
                    },
                    {
                        label: "Blanc",
                        color: "white"
                    }
                ],

            
            // range: [2000, 2025],
            range: [],

            // filters data
            selectedFilters: {
                status_annonce: [],
                etats: [],
                marques: [],
                years: [],
                km: [],
                carburants: [],
                cps: [],
                boites: [],
                carrosseries: [],
                puissances: [],
                portes: [],
                places: [],
                type_vehicule: [],
                cote_volant: [],
                colors: [],
            },
            
        }),
        async created() {
            // if(this.user.role.id != 1){
            //     await this.getAnnoncesByAgence();
            // }else{
                await this.getAnnonces();
                console.log(this.annonces)
            // }

            await this.checkBidEnd();

            Fancybox.bind("[data-fancybox]", {});
            //await this.filtersData();
            //console.log(this.filters);
        },
        mounted: function () {
            this.timer = setInterval(() => {
                if(this.annonces) {
                this.updateTimer();
                    
                }
                
            }, 1000)
        },
        methods: {

            findColor: async function(list, label) {
                //console.log(label)
                if(label){
                    //console.log(label)
                    //console.log(list.find((obj) => obj.label === label).color)
                    return await list.find((obj) => obj.label === label).color;
                }
                
            },
            checkBidEnd: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/annonceUpd', this.getHeaders());
                    console.log('update')
                    if(response.data) {
                        return response.data;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            checkFavorite: async function (annonce) {
                try {
                    const response = await axios.get(this.baseApiURL() + '/favoris/' + annonce + '/' + this.user.id, this.getHeaders());
                    if(response.data) {
                        return response.data.id;
                    }else{
                        return false;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            addFavorite: async function (annonce) {
                try {
                    const response = await axios.post(this.baseApiURL() + '/favoris', {
                        user: this.user.id,
                        annonce: annonce
                    }, this.getHeaders());
                    if(response) {
                        await this.updateFilters();
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            removeFavorite: async function (favoris) {
                try {
                    const response = await axios.delete(this.baseApiURL() + '/favoris/'+ favoris, this.getHeaders());
                    if(response) {
                        await this.updateFilters();
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getAnnoncesByAgence: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/annonces/' + this.user.agence.id, this.getHeaders());
                    if(response) {
                        this.annonces = response.data;
                        for(let annonce of this.annonces) {
                            let agence_id = annonce.user.agence;
                            const aResponse = await axios.get(this.baseApiURL() + '/agence/' + agence_id, this.getHeaders());
                            //console.log(aResponse.data);
                            annonce.user.agence = aResponse.data.label;

                            // Set filter marque
                            if(!this.marques.includes(annonce.vehicule.marque)) {
                                this.marques.push(annonce.vehicule.marque);
                            }
                        }

                        //console.log(this.marques)
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getLastOffre: async function (annonce) {
                try {
                    const response = await axios.get(this.baseApiURL() + '/last_offre/' + annonce, this.getHeaders());
                    if(response) {
                        console.log(response.data)
                        if(response.data){
                            return response.data.montant;
                        }else{
                            return null;
                        }
                       
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getAnnonces: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/annonces', this.getHeaders());
                    if(response) {
                        //this.annonces = response.data;
                        for(let annonce of response.data) {
                            let agence_id = annonce.user.agence;
                            const aResponse = await axios.get(this.baseApiURL() + '/agence/' + agence_id, this.getHeaders());
                            //console.log(aResponse.data);
                            annonce.user.agenceLabel = aResponse.data.label;

                            annonce.timer = "";

                            const bResponse = await axios.get(this.baseApiURL() + '/vehicule/'+annonce.vehicule.id+'/mainPhoto', this.getHeaders());
                            if(bResponse){
                                annonce.vehicule.mainPhoto = bResponse.data.image;
                            }

                            const cResponse = await axios.get(this.baseApiURL() + '/vehicule/'+annonce.vehicule.id+'/photo', this.getHeaders());
                            if(cResponse){
                                annonce.vehicule.allPhotos = cResponse.data;
                                //console.log(annonce.vehicule)
                            }

                            var last_offre = await this.getLastOffre(annonce.id);
                            annonce.last_offre = last_offre;

                            var fav = await this.checkFavorite(annonce.id)
                            annonce.isFav = fav;

                            // Set filter état
                            // if(!this.etats.includes(annonce.etat_vehicule)) {
                            //     this.etats.push(annonce.etat_vehicule);
                            //     this.c_etats.push(1);
                            // }else{
                            //     var k_etats = this.etats.indexOf(annonce.etat_vehicule)
                            //     this.c_etats[k_etats] += 1;
                            // }
                            this.setFilters(this.etats, this.c_etats, annonce.etat_vehicule)
                            this.allEtats = this.etats;

                            this.setFilters(this.marques, this.c_marques, annonce.vehicule.marque)
                            this.allMarques = this.marques;

                            this.setFilters(this.years, this.c_years, annonce.vehicule.date_first)
                            this.allYears = this.years;
                            
                            this.setFilters(this.km, this.c_km, parseInt(annonce.vehicule.km))
                            this.allKm = this.km;

                            this.setFilters(this.carburants, this.c_carburants, annonce.vehicule.energie)
                            this.allCarburants = this.carburants;

                            this.setFilters(this.cps, this.c_cps, annonce.customer.cp)
                            this.allCps = this.cps;

                            this.setFilters(this.boites, this.c_boites, annonce.vehicule.boite_vitesse)
                            this.allBoites = this.boites;

                            this.setFilters(this.carrosseries, this.c_carrosseries, annonce.vehicule.genre)
                            this.allCarrosseries = this.carrosseries;

                            this.setFilters(this.puissances, this.c_puissances, parseInt(annonce.vehicule.puissance))
                            this.allPuissances = this.puissances;

                            this.setFilters(this.portes, this.c_portes, annonce.vehicule.nb_porte)
                            this.allPortes = this.portes;

                            this.setFilters(this.places, this.c_places, annonce.vehicule.nb_place)
                            this.allPlaces = this.places;

                            this.setFilters(this.type_vehicule, this.c_type_vehicule, annonce.vehicule.type_vehicule)
                            this.allType_vehicule = this.type_vehicule;

                            this.setFilters(this.cote_volant, this.c_cote_volant, annonce.vehicule.cote_volant)
                            this.allCote_volant = this.cote_volant;

                            this.setFilters(this.colors, this.colors, annonce.color_ext)
                            this.allColors = this.colors;
                        }
                        this.annonces = response.data;
                        
                        this.marques.sort();
                        this.years.sort((a, b) => a[0] - b[0]);
                        this.km.sort((a, b) => a[0] - b[0]);
                        this.cps.sort();
                        this.carrosseries.sort();
                        this.puissances.sort((a, b) => a[0] - b[0]);
                        this.portes.sort();
                        this.places.sort();
                        this.type_vehicule.sort();
                        this.cote_volant.sort();


                        //Range Years
                        // this.max = parseInt(this.years[this.years.length - 1][0]);
                        // this.min = parseInt(this.years[0][0])

                        this.max = new Date().getFullYear();
                        this.min = 1900

                        this.selectedFilters.years.push(this.min);
                        this.selectedFilters.years.push(this.max);
                        

                        //Range Km
                        this.maxKm = parseInt(this.km[this.km.length - 1][0]);
                        
                        if(!this.km[0][0]){
                            this.minKm = 0
                        }else{
                            this.minKm = parseInt(this.km[0][0])
                        }
                        
                        this.selectedFilters.km.push(this.minKm);
                        this.selectedFilters.km.push(this.maxKm);

                        //Range Puissance
                        this.maxPuissances = parseInt(this.puissances[this.puissances.length - 1][0]);
                        // this.minPuissances = parseInt(this.puissances[0][0])
                        this.minPuissances = 0
                        this.selectedFilters.puissances.push(this.minPuissances);
                        this.selectedFilters.puissances.push(this.maxPuissances);

                        for(let color of this.colors){
                            color[2] = await this.findColor(this.color_ext_list, color[0]);
                        }

                        console.log(this.colors)

                        this.loading = false
                        
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            updateTimer: async function () {
                try {
                    for(let annonce of this.annonces) {
                        annonce.timer = await this.enchereTimer(annonce)
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            // getPhotoVehicule: async function () {
            //     try {
            //         //console.log(this.reprise.vehicule.id);
            //         const response = await axios.get(this.baseApiURL() + '/vehicule/'+this.annonce.vehicule.id+'/photo', this.getHeaders());
            //         if(response) {
            //             this.allPhotos = response.data;
            //             //console.log(this.photos)
            //             //console.log(this.photos);
            //             // for(const photo in this.photos){
            //             //     this.customers[customer].label = this.customers[customer].firstname + " " + this.customers[customer].lastname + " (" + this.customers[customer].email + ")";
            //             // }
            //         }
            //     } catch (e) {
            //         console.error(e);
            //     }
            // },
            setFilters: async function (filters, count, data) {
                try {
                    // Set filter cp
                    // if(!filters.includes(data)) {
                    //     filters.push(data);
                    //     count.push(1);
                    // }else{
                    //     var k = filters.indexOf(data)
                    //     count[k] += 1;
                    // }

                    //console.log(filters)
                    var filterExist = false;

                    for(let filter of filters) {
                        if(data ==  filter[0]){
                            filterExist = true;
                            filter[1] += 1;
                        }
                    }

                    if(!filterExist){
                        filters.push([data, 1]);
                    }
                    
                } catch (e) {
                    console.error(e);
                }
            },
            // setRangeFilter: async function (filters) {
            //     try {


            //         //var yearsRange = [];

            //         for(let filter of filters) {
            //             if(data ==  filter[0]){
            //                 filterExist = true;
            //                 filter[1] += 1;
            //             }
            //         }

            //         if(!filterExist){
            //             filters.push([data, 1]);
            //         }
                    
            //     } catch (e) {
            //         console.error(e);
            //     }
            // },
            checkFilters: async function () {
                try {
                    //console.log(this.selectedFilters)
                    for(let filter in this.selectedFilters) {
                        if(this.selectedFilters[filter].length){
                            //console.log(this.selectedFilters[filter])
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            removeFilters: async function (list, val) {
                try {
                    console.log(list);
                    console.log(val);
                    var index = list.indexOf(val);
                    if (index !== -1) {
                        list.splice(index, 1);
                    }
                    this.updateFilters()
                } catch (e) {
                    console.error(e);
                }
            },
            
            updateFilters: async function () {
                try {
                    //if(this.selectedFilters.length){
                        this.newAnnonces = [];
                        //console.log(this.selectedFilters.status_annonce)
                            
                        var filterStatus = this.selectedFilters.status_annonce.length ? this.selectedFilters.status_annonce : ["1","2","3","4","5","6"];
                        var filterMarques = this.selectedFilters.marques.length ? this.selectedFilters.marques : this.allMarques.map(arr => arr[0]);
                        //var filterYears = this.selectedFilters.years.length ? this.selectedFilters.years : this.allYears.map(arr => arr[0]);
                        var filterYears = this.selectedFilters.years;
                        var filterKm = this.selectedFilters.km;
                        var filterCarburants = this.selectedFilters.carburants.length ? this.selectedFilters.carburants : this.allCarburants.map(arr => arr[0]);
                        var filterCps = this.selectedFilters.cps.length ? this.selectedFilters.cps : this.allCps.map(arr => arr[0]);
                        var filterEtats = this.selectedFilters.etats.length ? this.selectedFilters.etats : this.allEtats.map(arr => arr[0]);
                        var filterBoites = this.selectedFilters.boites.length ? this.selectedFilters.boites : this.allBoites.map(arr => arr[0]);
                        var filterCarrosserie = this.selectedFilters.carrosseries.length ? this.selectedFilters.carrosseries : this.allCarrosseries.map(arr => arr[0]);
                        var filterPuissances = this.selectedFilters.puissances;
                        var filterPortes = this.selectedFilters.portes.length ? this.selectedFilters.portes : this.allPortes.map(arr => arr[0]);
                        var filterPlaces = this.selectedFilters.places.length ? this.selectedFilters.places : this.allPlaces.map(arr => arr[0]);
                        var filterType_vehicule = this.selectedFilters.type_vehicule.length ? this.selectedFilters.type_vehicule : this.allType_vehicule.map(arr => arr[0]);
                        var filterCote_volant = this.selectedFilters.cote_volant.length ? this.selectedFilters.cote_volant : this.allCote_volant.map(arr => arr[0]);
                        var filterColors = this.selectedFilters.colors.length ? this.selectedFilters.colors : this.allColors.map(arr => arr[0]);

                        var myAnnonces = this.switchAnnonce;
                        var hideMyAnnonces = this.hideAnnonces;
                        var seeFav = this.seeFav;
                        
                        const response = await axios.post(this.baseApiURL() + '/filters/annonces', 
                        {
                            filterStatus: filterStatus,
                            filterMarques: filterMarques,
                            filterYears: filterYears,
                            filterKm: filterKm,
                            filterCarburants: filterCarburants,
                            filterCps: filterCps,
                            filterEtats: filterEtats,
                            filterBoites: filterBoites,
                            filterCarrosserie: filterCarrosserie,
                            filterPuissances: filterPuissances,
                            filterPortes: filterPortes,
                            filterPlaces: filterPlaces,
                            filterType_vehicule: filterType_vehicule,
                            filterCote_volant: filterCote_volant,
                            filterColors: filterColors,
                            agence: this.user.agence.id,
                            user: this.user.id,
                            myAnnonces: myAnnonces,
                            hideMyAnnonces: hideMyAnnonces,
                            seeFav: seeFav
                        }
                        ,this.getHeaders());
                        if(response) {
                            // this.etats = [];
                            // this.marques = [];
                            // this.years = [];
                            // this.cps = [];
                            
                            for(let annonce of response.data) {

                                let agence_id = annonce.user.agence;
                                const aResponse = await axios.get(this.baseApiURL() + '/agence/' + agence_id, this.getHeaders());
                                //console.log(aResponse.data);
                                annonce.user.agenceLabel = aResponse.data.label;

                                // annonce.timer = await this.enchereTimer(annonce.updatedAt)
                                annonce.timer = "";

                                const bResponse = await axios.get(this.baseApiURL() + '/vehicule/'+annonce.vehicule.id+'/mainPhoto', this.getHeaders());
                                if(bResponse){
                                    annonce.vehicule.mainPhoto = bResponse.data.image;
                                }

                                const cResponse = await axios.get(this.baseApiURL() + '/vehicule/'+annonce.vehicule.id+'/photo', this.getHeaders());
                                if(cResponse){
                                    annonce.vehicule.allPhotos = cResponse.data;
                                    //console.log(annonce.vehicule)
                                }

                                var last_offre = await this.getLastOffre(annonce.id);
                                annonce.last_offre = last_offre;

                                var fav = await this.checkFavorite(annonce.id)
                                annonce.isFav = fav;

                                // this.setFilters(this.etats, this.c_etats, annonce.etat_vehicule)

                                // this.setFilters(this.marques, this.c_marques, annonce.vehicule.marque)

                                // this.setFilters(this.years, this.c_years, annonce.vehicule.date_first)

                                // this.setFilters(this.cps, this.c_cps, annonce.customer.cp)
                                
                            }
                            this.annonces = response.data

                            //console.log(this.marques)

                            this.marques.sort();
                            this.years.sort();
                            this.km.sort((a, b) => a[0] - b[0]);
                            this.cps.sort();
                            this.carrosseries.sort();
                            this.puissances.sort((a, b) => a[0] - b[0]);
                            this.portes.sort();
                            this.type_vehicule.sort();

                            for(let color of this.colors){
                                color[2] = await this.findColor(this.color_ext_list, color[0]);
                            }
                        }
                    // }else{
                    //     await this.getAnnonces();
                    // }
                    this.checkFilters();
                } catch (e) {
                    console.error(e);
                }
            },
            getMainPhotoVehicule: async function () {
                try {
                    //console.log(this.reprise.vehicule.id);
                    const response = await axios.get(this.baseApiURL() + '/vehicule/'+this.reprise.vehicule.id+'/mainPhoto', this.getHeaders());
                    if(response) {
                        this.mainPhoto = response.data;
                        //console.log(this.photos);
                        // for(const photo in this.photos){
                        //     this.customers[customer].label = this.customers[customer].firstname + " " + this.customers[customer].lastname + " (" + this.customers[customer].email + ")";
                        // }
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            enchereTimer: async function (annonce) {
                try {
                    
                    // this.timer = setInterval(() => {
                    //     var dateFuture = new Date(date);
                    //     var dateNow = new Date();

                    //     var seconds = Math.floor((dateFuture - (dateNow))/1000);
                    //     var minutes = Math.floor(seconds/60);
                    //     var hours = Math.floor(minutes/60);
                    //     var days = Math.floor(hours/24);

                    //     hours = hours-(days*24);
                    //     minutes = minutes-(days*24*60)-(hours*60);
                    //     seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
                    //     console.log(days + "J " + hours + ":" + minutes + ":" + seconds);
                    //     this.timer = days + "J " + hours + ":" + minutes + ":" + seconds;
                    // }, 1000)
                    // // var dateFuture = new Date("2024-06-20 09:00:0");
                    // return this.timer;

                    var dateFuture = new Date(annonce.bidEnd);
                    var dateNow = new Date();

                    var seconds = Math.floor((dateFuture - (dateNow))/1000);
                    var minutes = Math.floor(seconds/60);
                    var hours = Math.floor(minutes/60);
                    var days = Math.floor(hours/24);

                    hours = ((hours-(days*24)).toString().length == 1) ? "0" + (hours-(days*24)) : (hours-(days*24)) ;
                    minutes = ((minutes-(days*24*60)-(hours*60)).toString().length == 1) ? "0" + (minutes-(days*24*60)-(hours*60)) : (minutes-(days*24*60)-(hours*60));
                    seconds = ((seconds-(days*24*60*60)-(hours*60*60)-(minutes*60)).toString().length == 1) ? "0" + (seconds-(days*24*60*60)-(hours*60*60)-(minutes*60)) : (seconds-(days*24*60*60)-(hours*60*60)-(minutes*60));
                    //console.log(days + "J " + hours + ":" + minutes + ":" + seconds);

                    if(!annonce.bidEnd && annonce.status.id == 3){
                        return "Achat immédiat";
                    }else if(!annonce.bidEnd && annonce.status.id != 3){
                        return "Faite la première offre";
                    }else if(dateFuture > dateNow){
                        if(days <= 0){
                            return hours + ":" + minutes + ":" + seconds;
                        }else{
                            return days + "J " + hours + ":" + minutes + ":" + seconds;
                        }
                    }else{
                        // if(annonce.status.id == 4){
                        //     const response = await axios.put(this.baseApiURL() + '/annonce/'+ annonce.id, {
                        //         status: 5,
                        //     }, this.getHeaders());

                        //     console.log(response)
                        // }
                        if(annonce.bidDuration){
                            return "Fini";
                        }else{
                            return "Masqué";
                        }

                        
                    }

                    
                    
                    
                } catch (e) {
                    console.error(e);
                }
            },
            // filtersData: async function () {
            //     try {
            //         const response = await axios.get(this.baseApiURL() + '/filters/marques', this.getHeaders());
            //         if(response) {
            //             this.marques = response.data
            //         }
            //         console.log(this.marques)
            //     } catch (e) {
            //         console.error(e);
            //     }
            // },
        }
    }
</script>