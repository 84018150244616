<template>
    <v-card elevation="1" style="position:static">
      <SnackBar style="position:absolute;top:40px" color="success" title="Recherche mise à jour !" v-on:close="snackbarUpdateAlert = false" :model="snackbarUpdateAlert"></SnackBar>
      <SnackBar style="position:absolute;top:40px" color="success" title="Recherche supprimé !" v-on:close="snackbarDeleteSearch = false" :model="snackbarDeleteSearch"></SnackBar>
      <v-card-title class="mb-3">
        Liste des recherches enregistrées
      </v-card-title>
      <v-divider class="mb-5"></v-divider>
      <v-card-text>
        <v-list>
            <v-list-item v-for="(savedSearch, i) in savedSearchs" :key="i">
                <v-list-item-content>
                  
                    <v-list-item-title class="pa-2" v-text="savedSearch.label"></v-list-item-title>
                    <v-container>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in JSON.parse(savedSearch.data).status_annonce" :key="filter">
                        <span v-if="filter == 1">À approuver</span>
                        <span v-if="filter == 2">Annonce approuvée</span>
                        <span v-if="filter == 3">Achat immédiat</span>
                        <span v-if="filter == 4">Enchère en cours</span>
                        <span v-if="filter == 5">Validation client</span>
                        <span v-if="filter == 6">Vendu</span>
                      </v-chip>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in JSON.parse(savedSearch.data).etats" :key="filter"><span v-if="filter">{{ filter }}</span><span v-else>États non renseigné</span></v-chip>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in JSON.parse(savedSearch.data).marques" :key="filter">{{ filter }}</v-chip>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" >Entre {{ JSON.parse(savedSearch.data).years[0] }} et  {{ JSON.parse(savedSearch.data).years[1] }}</v-chip>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" >Entre {{ JSON.parse(savedSearch.data).km[0] }} et  {{ JSON.parse(savedSearch.data).km[1] }} Km</v-chip>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in JSON.parse(savedSearch.data).carburants" :key="filter"><span v-if="filter">{{ filter }}</span><span v-else>Carburant non renseigné</span></v-chip>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in JSON.parse(savedSearch.data).cps" :key="filter">{{ filter }}</v-chip>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in JSON.parse(savedSearch.data).carrosseries" :key="filter"><span v-if="filter">{{ filter }}</span><span v-else>Carrosseries non renseigné</span></v-chip>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in JSON.parse(savedSearch.data).boites" :key="filter"><span v-if="filter">{{ filter }}</span><span v-else>Boite non renseigné</span></v-chip>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" >Entre {{ JSON.parse(savedSearch.data).puissances[0] }} et  {{ JSON.parse(savedSearch.data).puissances[1] }} CV</v-chip>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in JSON.parse(savedSearch.data).portes" :key="filter"><span v-if="filter">{{ filter }} Portes</span><span v-else>Nb portes non renseigné</span></v-chip>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in JSON.parse(savedSearch.data).places" :key="filter" ><span v-if="filter">{{ filter }} Places</span><span v-else>Nb places non renseigné</span></v-chip>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in JSON.parse(savedSearch.data).type_vehicule" :key="filter"><span v-if="filter">{{ filter }} Type véhicule</span><span v-else>Type véhicule non renseigné</span></v-chip>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in JSON.parse(savedSearch.data).cote_volant" :key="filter"><span v-if="filter">Volant à {{ filter }}</span><span v-else>Coté volant non renseigné</span></v-chip>
                      <v-chip class="mb-2 mr-2 d-inline-flex align-center" v-for="filter in JSON.parse(savedSearch.data).colors" :key="filter"><span v-if="filter">{{ filter }}</span><span v-else>Nb portes non renseigné</span></v-chip>
                      
                    </v-container>
                    <v-checkbox
                      v-model="savedSearch.activeAlert"
                      :label="`Recevoir une alerte ?`"
                      @click="updateAlert(savedSearch.id, savedSearch.activeAlert)"
                    ></v-checkbox>
                    <v-divider></v-divider>
                </v-list-item-content>
                <v-list-item-action class="d-flex flex-row align-start">
                  <v-btn icon color="primary" label text-color="white" @click="seeSavedSearch(JSON.parse(savedSearch.data))">
                    <v-icon>
                      mdi-eye-outline
                    </v-icon>
                  </v-btn>
                  <v-btn icon color="red" label text-color="white" @click="deleteSavedSearch(savedSearch.id)">
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
      </v-card-text>
      
    </v-card>
  </template>
  
  <script>
  import axios from "axios";
  import SnackBar from "@/components/partials/Snackbar";
  
  export default {
    name: 'SavedSearchDialog',

    components: {
      SnackBar,
    },

    data() {
      return {
        savedSearchs: [],
        snackbarUpdateAlert: false,
        snackbarDeleteSearch: false,
      }
    },
    created() {
      this.getSavedSearch();
    },
    methods: {
      getSavedSearch: async function () {
        try {
          console.log(this.user.id)
          const response = await axios.get(this.baseApiURL() + '/saved_search/' + this.user.id, this.getHeaders());
          if (response) {
              this.savedSearchs = response.data;
          }
        } catch (e) {
          console.error(e);
        }
      },
      seeSavedSearch: async function (data) {
        try {
          this.$emit('searchData', data)
          //console.log("test")
        } catch (e) {
          console.error(e);
        }
      },
      updateAlert: async function (id, activeAlert) {
        try {
          const response = await axios.put(this.baseApiURL() + '/saved_search/' + id, 
          {
            activeAlert: activeAlert,
          }
          ,this.getHeaders());

          if(response) {
            this.snackbarUpdateAlert = true;
            console.log("Saved !")
          }

        } catch (e) {
          console.error(e);
        }
      }, 
      deleteSavedSearch: async function (id) {
        try {
          const response = await axios.delete(this.baseApiURL() + '/saved_search/'+ id, this.getHeaders());
          if(response) {
            this.snackbarDeleteSearch = true;
            await this.getSavedSearch();
          }
        } catch (e) {
          console.error(e);
        }
      },  
    }
  }
  </script>