<template>
    <v-row class="mt-3">
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Liste des agences
                    <v-spacer></v-spacer>
                    <v-btn @click="AddAgenceDialog = true">Ajouter une agence</v-btn>
                    <v-dialog v-model="AddAgenceDialog" max-width="800px">
                        <AddAgenceDialog v-on:close="closeAddAgenceDialog" v-on:abort="AddAgenceDialog = false"></AddAgenceDialog>
                    </v-dialog>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table 
                    :headers="headers"
                    :items="agences"
                    :search="search"
                >
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip v-if="item.status === 1" color="success">Activée</v-chip>
                        <v-chip v-if="item.status === 0" color="error">Désactivée</v-chip>
                    </template>
                    <template v-slot:[`item.zone`]="{ item }">
                        {{item.zone}} km
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="blue" class="mr-3" label text-color="white" v-bind="attrs" v-on="on" @click="activeAgence = item;EditAgenceDialog = true">
                                    <v-icon>
                                    mdi-pencil
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Editer</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="blue" class="mr-3" label text-color="white" v-bind="attrs" v-on="on" @click="activeAgence = item;UserAgenceDialog = true">
                                    <v-icon>
                                    mdi-account
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Utilisateurs liés</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="red" label text-color="white" v-bind="attrs" v-on="on" @click="activeAgence = item; DeleteAgenceDialog = true">
                                    <v-icon>
                                    mdi-trash-can-outline
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Supprimer</span>
                        </v-tooltip>
                    </template>
            
                </v-data-table>

                <v-dialog v-model="UserAgenceDialog">
                    <UserAgenceDialog :activeAgence="activeAgence" v-on:valid="refresh" v-on:close="UserAgenceDialog = false"></UserAgenceDialog>
                </v-dialog>

                <v-dialog v-model="DeleteAgenceDialog" max-width="600px">
                    <DeleteAgenceDialog :label="activeAgence.label" v-on:valid="deleteAgence" v-on:close="DeleteAgenceDialog = false"></DeleteAgenceDialog>
                </v-dialog>

                <v-dialog v-model="EditAgenceDialog" max-width="800px">
                    <EditAgenceDialog :activeAgence="activeAgence" v-on:close="EditAgenceDialog = false" v-on:valid="refresh"></EditAgenceDialog>
                </v-dialog>

                <SnackBar color="success" title="Agence ajouté !" v-on:close="snackbarAddAgence = false" :model="snackbarAddAgence"></SnackBar>
                <SnackBar color="success" title="Agence supprimé !" v-on:close="snackbarDeleteAgence = false" :model="snackbarDeleteAgence"></SnackBar>
                <SnackBar color="success" title="Agence modifié !" v-on:close="snackbarEditAgence = false" :model="snackbarEditAgence"></SnackBar>

            </v-card>
        </v-col>
    </v-row>
</template>
  
<script>
    import axios from "axios";
    import DeleteAgenceDialog from "@/components/agences/DeleteAgenceDialog";
    import AddAgenceDialog from "@/components/agences/AddAgenceDialog";
    import EditAgenceDialog from "@/components/agences/EditAgenceDialog";
    import UserAgenceDialog from "@/components/agences/UserAgenceDialog";
    import SnackBar from "@/components/partials/Snackbar";
  
    export default {
        name: 'AgencesView',
    
        components: {
            DeleteAgenceDialog,
            AddAgenceDialog,
            EditAgenceDialog,
            UserAgenceDialog,
            SnackBar,
        },

        data: () => ({
            // Components
            activeAgence: [],
            DeleteAgenceDialog: false,
            snackbarDeleteAgence: false,
            AddAgenceDialog: false,
            snackbarAddAgence: false,
            EditAgenceDialog: false,
            UserAgenceDialog: false,
            snackbarEditAgence: false,
            // Data table
            search: null,
            agences: [],
            headers: [
                { text: 'Libellé', value: 'label' },
                { text: 'Raison', value: 'raison' },
                { text: 'Adresse', value: 'address' },
                { text: 'CP', value: 'cp' },
                { text: 'Ville', value: 'city' },
                { text: 'Téléphone', value: 'phone' },
                { text: 'Status', value: 'status' },
                { text: 'Zone chalandise', value: 'zone' },
                { text: 'Actions', value: 'actions' },
            ],
        }),
        async created() {
            document.title = 'Gestion des agences';
            await this.getAgences();
        },
        methods: {
            getAgences: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/agences', this.getHeaders());
                    this.AddAgenceDialog = false;
                    if(response) {
                        this.agences = response.data;
                    }
                } catch (e) {
                    console.error(e);
                }
            },

            deleteAgence: async function () {
                try {
                    const response = await axios.delete(this.baseApiURL() + '/agence/'+ this.activeAgence.id, this.getHeaders());
                    if(response) {
                        await this.getAgences();
                        this.DeleteAgenceDialog = false;
                        this.snackbarDeleteAgence = true;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            closeAddAgenceDialog: async function () {
                await this.getAgences();
                this.AddAgenceDialog = false;
                this.snackbarAddAgence = true;
            },
            refresh: async function () {
                try {
                    await this.getAgences();
                    this.EditAgenceDialog = false;
                    this.snackbarEditAgence = true;
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }
</script>
  