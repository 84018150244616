<template>
    <v-row class="mt-3">
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Gestion des Factures
                </v-card-title>
                <v-btn class="ml-3 mb-3" :loading="generateFactsLoading" @click="generateFactures">Générer les factures du mois dernier</v-btn>
            </v-card>
        </v-col>

        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Liste des Factures (Cotisations)
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="searchFact"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>

                <v-data-table 
                    :headers="headersFactures"
                    :items="facturesCot"
                    :search="searchFact"
                >

                    <template v-slot:[`item.createdAt`]="{ item }">
                        <div>{{ formatDate(item.createdAt) }}</div>
                    </template>

                    <template v-slot:[`item.montant_ttc`]="{ item }">
                        <div>{{ item.montant_ttc }} €</div>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <!-- <v-tooltip bottom v-if="!item.url_fiche">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="orange" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeFacture = item;generatePDF();">
                                    <v-icon>
                                    mdi-file-pdf-box
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Générer Pdf</span>
                        </v-tooltip> -->

                        <v-tooltip bottom v-if="item.url_facture">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="orange" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeFacture = item;getPDF();">
                                    <v-icon>
                                    mdi-download
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Télécharger Pdf</span>
                        </v-tooltip>

                        <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="blue" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" :to="{ name: 'ReprisesEditView', params: { id: item.id }}">
                                    <v-icon>
                                    mdi-pencil
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Editer</span>
                        </v-tooltip> -->

                        <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="red" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeFacture = item;deleteFactureDialog = true">
                                    <v-icon>
                                    mdi-trash-can-outline
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Supprimer</span>
                        </v-tooltip> -->

                    </template>
            
                </v-data-table>
                <SnackBar color="success" title="La fiche de reprise à été généré au format PDF" v-on:close="snackbarCreatePdf = false" :model="snackbarCreatePdf"></SnackBar>
            </v-card>
        </v-col>


        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Liste des Factures (Commissions)
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="searchFact"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>

                <v-data-table 
                    :headers="headersFacturesComm"
                    :items="facturesComm"
                    :search="searchFact"
                >

                    <template v-slot:[`item.createdAt`]="{ item }">
                        <div>{{ formatDate(item.createdAt) }}</div>
                    </template>

                    <template v-slot:[`item.montant_ttc`]="{ item }">
                        <div>{{ item.montant_ttc }} €</div>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <!-- <v-tooltip bottom v-if="!item.url_fiche">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="orange" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeFacture = item;generatePDF();">
                                    <v-icon>
                                    mdi-file-pdf-box
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Générer Pdf</span>
                        </v-tooltip> -->

                        <v-tooltip bottom v-if="item.url_facture">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="orange" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeFacture = item;getPDF();">
                                    <v-icon>
                                    mdi-download
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Télécharger Pdf</span>
                        </v-tooltip>

                        <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="blue" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" :to="{ name: 'ReprisesEditView', params: { id: item.id }}">
                                    <v-icon>
                                    mdi-pencil
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Editer</span>
                        </v-tooltip> -->

                        <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="red" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeFacture = item;deleteFactureDialog = true">
                                    <v-icon>
                                    mdi-trash-can-outline
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Supprimer</span>
                        </v-tooltip> -->

                    </template>
            
                </v-data-table>
                <SnackBar color="success" title="La fiche de reprise à été généré au format PDF" v-on:close="snackbarCreatePdf = false" :model="snackbarCreatePdf"></SnackBar>
            </v-card>
        </v-col>



        <!-- <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Liste des Ventes
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>

                <v-data-table 
                    :headers="headersReprise"
                    :items="reprises"
                    :search="search"
                >
                    <template v-slot:[`item.customer`]="{ item }">
                        <div><a :href="'/customers/?idCustomer=' + item.customer.id">{{item.customer.firstname}} {{item.customer.lastname}}</a></div>
                    </template>

                    <template v-slot:[`item.user`]="{ item }">
                        <div>{{item.user.firstname}} {{item.user.lastname}} ({{item.user.agence}})</div>
                    </template>

                    <template v-slot:[`item.vehicule`]="{ item }">
                        <div>{{item.vehicule.marque}} {{item.vehicule.modele}}</div>
                    </template>

                    <template v-slot:[`item.price_marge`]="{ item }">
                        <div>{{item.price_marge}} €</div>
                    </template>

                    <template v-slot:[`item.price_tva`]="{ item }">
                        <div>{{item.price_tva}} €</div>
                    </template>

                    <template v-slot:[`item.status.label`]="{ item }">
                        <v-chip v-if="item.status.id === 1" color="grey-darken-1">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 2" color="warning">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 3" color="success">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 4" color="error">{{item.status.label}}</v-chip>
                    </template>

                    <template v-slot:[`item.repa_list`]="{ item }">
                        
                        <v-chip x-small v-for="repa in JSON.parse(item.repa_list)" :key="repa">{{ repa }}</v-chip>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom v-if="!item.url_fiche">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="orange" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeReprise = item;generatePDF();">
                                    <v-icon>
                                    mdi-file-pdf-box
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Générer Pdf</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="item.url_fiche">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="orange" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeReprise = item;getPDF();">
                                    <v-icon>
                                    mdi-download
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Télécharger Pdf</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="blue" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" :to="{ name: 'ReprisesEditView', params: { id: item.id }}">
                                    <v-icon>
                                    mdi-pencil
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Editer</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="red" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeReprise = item;deleteRepriseDialog = true">
                                    <v-icon>
                                    mdi-trash-can-outline
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Supprimer</span>
                        </v-tooltip>

                    </template>
            
                </v-data-table>
                <SnackBar color="success" title="La fiche de reprise à été généré au format PDF" v-on:close="snackbarCreatePdf = false" :model="snackbarCreatePdf"></SnackBar>
            </v-card>
        </v-col> -->
    </v-row>
</template>

<script>
    import axios from "axios";
    import SnackBar from "@/components/partials/Snackbar";
    //import puppeteer from "puppeteer";

    export default {
        name: 'FacturesAdminView',

        components: {
            SnackBar,
        },

        data: () => ({
            
            search: null,
            searchFact: null,

            snackbarCreatePdf: false,

            generateFactsLoading: false,

            facturesCot: [],
            facturesComm: [],
            activeFacture: [],

            reprises: [],
            activeReprise: [],

            headersReprise: [
                { text: 'N°', value: 'reference'},
                { text: 'Client', value: 'customer'},
                { text: 'Utilisateur', value: 'user' },
                { text: 'Véhicule', value: 'vehicule' },
                { text: 'Prix TVA sur marge', value: 'price_marge' },
                { text: 'Prix TVA récupérable', value: 'price_tva' },
                // { text: 'Liste Réparations', value: 'repa_list' },
                // { text: 'Role', value: 'role.label' },
                { text: 'Status', value: 'status.label' },
                { text: 'Actions', value: 'actions' },
            ],

            headersFactures: [
                { text: 'N°', value: 'reference'},
                { text: 'Agence', value: 'agence.label'},
                { text: 'Raison', value: 'agence.raison' },
                { text: 'Date édition facture', value: 'createdAt' },
                { text: 'Total TTC', value: 'montant_ttc' },
                { text: 'Actions', value: 'actions' },
            ],

            headersFacturesComm: [
                { text: 'N°', value: 'reference'},
                { text: 'Ref. Reprise', value: 'reprise.reference'},
                { text: 'Agence', value: 'agence.label'},
                { text: 'Raison', value: 'agence.raison' },
                { text: 'Date édition facture', value: 'createdAt' },
                { text: 'Total TTC', value: 'montant_ttc' },
                { text: 'Actions', value: 'actions' },
            ],
        }),
        async created() {
            await this.getReprises();
            await this.getFacturesCot();
            await this.getFacturesComm();
           
        },
        methods: {
            formatDate(date){
                date = new Date(date);
                let day = String(date.getDate());
                if(day.length == 1){
                    day = '0' + day
                }
                let month = String(date.getMonth() + 1);
                console.log(month)
                console.log(month.length)
                if(month.length == 1){
                    month = '0' + month
                }
                let year = date.getFullYear();

                let hour = date.getHours();
                if(hour.length == 1){
                    hour = '0' + hour
                }
                let minute = date.getMinutes();
                if(minute.length == 1){
                    minute = '0' + minute
                }

                // return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':00';
                return day + '/' + month + '/' + year;

            },
            getFacturesCot: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/factures/cotisations', this.getHeaders());
                    if(response){
                        this.facturesCot = response.data;

                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getFacturesComm: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/factures/commissions', this.getHeaders());
                    if(response){
                        this.facturesComm = response.data;

                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getReprises: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/reprises_vendus', this.getHeaders());
                    if(response) {
                        this.reprises = response.data;
                        for(let reprise of this.reprises) {
                            let agence_id = reprise.user.agence;
                            const aResponse = await axios.get(this.baseApiURL() + '/agence/' + agence_id, this.getHeaders());
                            //console.log(aResponse.data);
                            reprise.user.agence = aResponse.data.label;
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getPDF: async function () {
                // const response = await axios.get(this.baseApiURL() + '/reprise/pdf/' + this.activeReprise.id, this.getHeaders());
                // if(response){
                //     console.log(response)
                // }

                const response = await axios.get(this.baseApiURL() + '/facture/pdf/' + this.activeFacture.id, {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf'
                    }
                });
                let fileURL = window.URL.createObjectURL(new Blob([response.data], {
                type: 'application/pdf'
                }));
                
                window.open(fileURL, '_blank');
                
            },
            generateFactures: async function () {
                this.generateFactsLoading = true;
                let response = await axios.post(this.baseApiURL() + '/factures/generate', {},this.getHeaders());
                console.log('test')
                if(response) console.log('test');this.generateFactsLoading = false;
                await this.getFacturesCot();
                
            }
        }
    }
</script>
  