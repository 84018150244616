<template>
  <v-app>
    <NavAdmin v-if="['HomeView', 'UsersView', 'SettingsView', 'FacturesAdminView', 'AccountView', 'AgencesView', 'CustomersView', 'OpenHoursView', 'CalendarView', 'FacturesUserView', 'VentesView', 'AnnoncesView', 'ReprisesAddView', 'ReprisesEditView', 'AnnoncesEditView', 'AnnoncesListView', 'AnnoncesSingleView'].includes($route.name)"></NavAdmin>
    <v-main class="ml-4">
      <router-view  class="pa-3"/>
    </v-main>
  </v-app>
</template>

<script>
import NavAdmin from "@/components/partials/NavAdmin";

export default {
  name: 'App',

  components: {
    NavAdmin,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
  /* main .v-main {
    padding: 65px 0px 0px 315px;
  }

  @media screen and (max-width: 1251px) {
    main .v-main {
      padding: 65px 0px 0px 30px;
    }
  } */

</style>
