<template>
    <v-row style="height:100%" class="mt-3 d-flex align-center justify-center">
        <v-card width="700px" class="ma-auto pa-3 text-center">
            <v-img src="../assets/logo-monoto.png" height="50px" contain class="mb-4"></v-img>

            <!-- <div v-if="accepted == true && customerOk == true">Vous avez accepté notre offre de rachat !</div>
            <div v-if="refused == true && customerOk == true">Vous avez refusé notre offre de rachat !</div> -->
            <div v-if="!customerOk">Erreur</div>
            <div v-if="!dateOk">Le délai de réponse à été dépassé</div>
            <div v-if="customerOk && statusOk && dateOk" class="text-left pa-4">
                <p v-if="reprise.r_type == 0">Veuillez prendre connaissance des détails de la reprise de votre véhicule ci-dessous :</p>
                <p v-if="reprise.r_type == 1">Veuillez prendre connaissance des détails de la mise en dépôt / vente de votre véhicule ci-dessous :</p>
                <v-divider class="mb-4"></v-divider>
                <div v-if="reprise.r_type == 0">
                    <p class="text-h5 font-weight-bold pt-8">Acheteur</p>
                    <p>Siret : {{ reprise.agence.siret }}</p>
                    <p>Agence agréé : {{ reprise.agence.raison }}, {{ reprise.agence.address }}, {{ reprise.agence.cp }} {{ reprise.agence.city }}</p>
                </div>

                <div v-if="reprise.r_type == 1">
                    <p class="text-h5 font-weight-bold pt-8">Dépositaire</p>
                    <p>{{ reprise.agence.raison }}</p>
                    <p>{{ reprise.user.firstname }} {{ reprise.user.lastname }}</p>
                    <p>{{ reprise.agence.address }}</p>
                    <p>{{ reprise.agence.cp }} {{ reprise.agence.city }}</p>
                    <p>Tél. : {{ reprise.agence.phone }}</p>
                    <p>Mail : {{ reprise.user.email }}</p>
                    <p>Siret : {{ reprise.agence.siret }}</p>
                </div>

                <v-row class="mt-3 v-sheet--outlined theme--light rounded">
                    <v-col md="6">
                        <div v-if="reprise.type == 'moral'">
                            <p><span class="text-h6">Vendeur</span> (personne morale) :</p>
                            <p>Nom Prénom : {{ reprise.customer.lastname }} {{ reprise.customer.firstname }}</p>
                            <p>Email : {{ reprise.customer.email }}</p>
                            <p>Téléphone : {{ reprise.customer.phone }}</p>
                            <p>Raison social : {{ reprise.pm_raison }}</p>
                            <p>Siret : {{ reprise.pm_siret }}</p>
                        </div>
                        <div v-if="reprise.type == 'physique'">
                            <p><span class="text-h6">Vendeur</span> (personne physique) :</p>
                            <p>Nom Prénom : {{ reprise.customer.lastname }} {{ reprise.customer.firstname }}</p>
                            <p>Email : {{ reprise.customer.email }}</p>
                            <p>Téléphone : {{ reprise.customer.phone }}</p>
                        </div>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col md="6">
                        <div>
                            <p class="text-h6">Si Co-titulaire :</p>
                            <p>Nom Prénom : {{ reprise.co_lastname }} {{ reprise.co_firstname }}</p>
                        </div>
                    </v-col>
                </v-row>

                <!-- <v-divider class="mb-4 mt-6"></v-divider> -->

                <p class="text-h5 font-weight-bold pt-8">CARACTÉRISTIQUES DU VÉHICULE</p>
                <v-row>
                    <v-col md="4">
                        <p><b>Marque :</b> {{ reprise.vehicule.marque }}</p>
                    </v-col>
                    <v-col md="4">
                        <p><b>Modèle :</b> {{ reprise.vehicule.modele }}</p>
                    </v-col>
                    <v-col md="4">
                        <p><b>Finition :</b> {{ reprise.vehicule.finition }}</p>
                    </v-col>

                    <v-col md="4">
                        <p><b>Carrosserie :</b> {{ reprise.vehicule.genre }}</p>
                    </v-col>
                    <v-col md="4">
                        <p><b>1ère Mise en circ. :</b> {{ reprise.vehicule.date_first }}</p>
                    </v-col>
                    <v-col md="4">
                        <p><b>N° immat. :</b> {{ reprise.vehicule.immat }}</p>
                    </v-col>

                    <v-col md="4">
                        <p><b>N° série :</b> ?</p>
                    </v-col>
                    <v-col md="4">
                        <p><b>Kilométrage au compteur :</b> {{ reprise.vehicule.km }} Km</p>
                    </v-col>
                    <v-col md="4">
                        <p><b>Boite de vitesse :</b> {{ reprise.vehicule.boite_vitesse }}</p>
                    </v-col>
                    <v-col md="4">
                        <p><b>Genre :</b> ?</p>
                    </v-col>
                    <v-col md="4">
                        <p><b>Transmission :</b> {{ reprise.vehicule.transmission }}</p>
                    </v-col>

                    <v-col md="4">
                        <p><b>Énergie :</b> {{ reprise.vehicule.energie }}</p>
                    </v-col>
                    <v-col md="4">
                        <p><b>Puissance :</b> {{ reprise.vehicule.puissance }} CV</p>
                    </v-col>
                    <v-col md="4">
                        <p><b>Cylindrée :</b> {{ reprise.vehicule.moteur }}</p>
                    </v-col>
                </v-row>

                <p class="text-h5 font-weight-bold pt-4">ÉTAT DU VÉHICULE</p>
                <p class="text-h6 font-weight-bold pt-4">Frais à prévoir : </p>
                <v-list>
                    <v-list-item v-for="(item, i) in JSON.parse(reprise.repa_list)" :key="i">
                        <v-list-item-icon>
                            <v-icon>mdi-alert-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <p class="text-h6 font-weight-bold pt-4">Observations : </p>
                <p class="rounded-lg pa-3" style="background-color: #f8f8f8;">{{ reprise.observations }}</p>

                <p class="text-h6 font-weight-bold pt-4" style="text-decoration: underline;">COÛT TOTAL de remise en état estimé TTC : {{ reprise.price_repa }}€</p>

                <v-divider class="mt-5 mb-5"></v-divider>

                <p v-if="reprise.r_type == 0" class="text-h5 font-weight-bold pt-4">MONTANT TOTAL DU RACHAT frais de remise en état déduis :</p>
                <p v-if="reprise.r_type == 1" class="text-h5 font-weight-bold pt-4">MONTANT TOTAL DE LA VENTE frais de remise en état déduis :</p>
                <v-row>
                    <v-col v-if="reprise.price_type == 0 && reprise.r_type == 0" md="6">
                        <p class="font-weight-bold">SI TVA sur marge :</p>
                        <p class="pa-3 font-weight-bold text-h6 .rounded-lg" style="background-color: #f8f8f8;">{{ reprise.price_marge }}€ TTC</p>
                    </v-col>
                    <v-col v-if="reprise.price_type == 1 && reprise.r_type == 0" md="6">
                        <p class="font-weight-bold">SI TVA récupérable :</p>
                        <div class="pa-3 .rounded-lg" style="background-color: #f8f8f8;">
                            <p class="font-weight-bold text-h6">{{ reprise.price_tva }}€ HT</p>
                            <v-divider></v-divider>
                            <p class="font-weight-bold text-h6">{{ parseInt((reprise.price_tva)/100*20)+parseInt((reprise.price_tva)) }}€ TTC</p>
                            <v-divider></v-divider>
                            <p class="font-weight-bold text-h6">{{ (reprise.price_tva)/100*20 }}€ TVA 20%</p>
                        </div>
                    </v-col>
                    <v-col v-if="reprise.r_type == 1" md="6">
                        <p class="font-weight-bold">SI TVA sur marge :</p>
                        <p class="pa-3 font-weight-bold text-h6 .rounded-lg" style="background-color: #f8f8f8;">{{ ((parseInt((reprise.dv_price)))/100*20)+parseInt((reprise.dv_price))+parseInt((reprise.dv_comm)) }}€ TTC</p>
                        <v-divider></v-divider>
                        <p class="font-weight-bold text-h6">dont {{ (parseInt(reprise.dv_price)/100*20) + parseInt(reprise.dv_comm) }}€ TVA 20%</p>
                        <p class="font-weight-bold text-h6">dont {{ parseInt(reprise.dv_comm) }}€ TTC de commission à l'agence</p>
                    </v-col>
                </v-row>
                <v-divider class="mt-5 mb-5"></v-divider>
                <div class="d-flex align-center justify-center">
                    <v-btn class="mr-2" color="success" @click="choise = 'accepter'; RepriseConfirmationDialog = true">Accepter l'offre</v-btn>
                    <v-btn color="error" @click="choise = 'refuser';RepriseConfirmationDialog = true">Refuser</v-btn>
                </div>
            </div>
            <div v-if="!statusOk">Vous avez déjà répondu à cette offre !</div>
        </v-card>

        <SnackBar color="success" title="Votre réponse a bien été prise en compte !" v-on:close="snackbarConfirm = false" :model="snackbarConfirm"></SnackBar>

        <v-dialog v-model="RepriseConfirmationDialog" max-width="600px">
            <RepriseConfirmationDialog :choise="choise" v-on:valid="updateReprise" v-on:close="RepriseConfirmationDialog = false"></RepriseConfirmationDialog>
        </v-dialog>
    </v-row>
</template>

<script>
//import HelloWorld from '../components/HelloWorld'
import axios from "axios";
import SnackBar from "@/components/partials/Snackbar";
import RepriseConfirmationDialog from "@/components/customers/RepriseConfirmationDialog";

export default {
  name: 'CustomerValidationView',

  components: {
    SnackBar,
    RepriseConfirmationDialog
  },
  data: () => ({
    snackbarConfirm: false,
    RepriseConfirmationDialog: false,
    reprise: [],
    customer: [],
    customerOk: false,
    dateOk: false,
    statusOk: false,
    newStatus: 2,
    choise: null,
    // accepted: false,
    // refused: false
  }),
  async created() {
    await this.getReprise();
    await this.getCustomer();
    await this.checkCustomer();
    await this.checkDate();
    if(this.$route.query.isConfirm == 1){
        this.snackbarConfirm = true;
    }
  },
  methods: {
    getReprise: async function () {
        try {
            const response = await axios.get(this.baseApiURL() + '/reprise/' + this.$route.params.id, this.getHeadersFront());
            if(response){
                this.reprise = response.data;
            }
        } catch (e) {
            console.log(e);
        }
    },
    getCustomer: async function () {
        try {
            const response = await axios.get(this.baseApiURL() + '/customer/' + this.$route.params.custId, this.getHeadersFront());
            if(response){
                this.customer = response.data;
            }
        } catch (e) {
            console.log(e);
        }
    },
    checkCustomer: async function () {
        try {
            // Si l'id du client correspond à celui de la reprise
            if (this.reprise.customer.id == this.customer.id) {
                this.customerOk = true;
                // Si la reprise n'a pas encore reçu de réponse
                if(this.reprise.status.id == 2) {
                    this.statusOk = true;
                    // if(this.$route.params.resp == 'accept') {
                    //     this.newStatus = 3;
                    //     this.accepted = true;

                    //     if(this.reprise.annonce != null) {
                    //         await axios.put(this.baseApiURL() + '/annonce/'+this.reprise.annonce.id, {
                    //         status: 2,
                            
                    //     }, this.getHeaders());
                    //     }
                    // }else if(this.$route.params.resp == 'refuse') {
                    //     this.newStatus = 4;
                    //     this.refused = true;
                    // }

                    // await axios.put(this.baseApiURL() + '/reprise/'+this.reprise.id, {
                    //     status: this.newStatus,
                    // }, this.getHeaders());
                }
            }
        } catch (e) {
            console.log(e);
        }
    },

    checkDate: async function () {
        try {

            var currentDate = new Date();
            var repriseLimitDate = new Date(this.reprise.updatedAt);
            var diff = Math.round((currentDate.getTime() - repriseLimitDate.getTime()) / (1000 * 3600 * 24));
            // Si l'id du client correspond à celui de la reprise
            console.log(diff)
            if (diff <= 7) {
                this.dateOk = true;
            }
        } catch (e) {
            console.log(e);
        }
    },
    updateReprise: async function () {
        try {
            console.log(this.choise)
            
            if(this.choise == "accepter"){
                this.newStatus = 3;
                if(this.reprise.annonce != null) {
                    await axios.put(this.baseApiURL() + '/annonce/'+this.reprise.annonce.id, {
                        status: 2,
                    }, this.getHeaders());
                }
            } else if(this.choise == "refuser") {
                this.newStatus = 4;
            }

            await axios.put(this.baseApiURL() + '/reprise/'+this.reprise.id, {
                status: this.newStatus,
            }, this.getHeaders());
            this.RepriseConfirmationDialog = false;
            window.location.reload()
            //this.$router.push({ path: '/reprise/'+this.reprise.id+'/'+this.reprise.customer.id+'?isConfirm=1'});
        } catch (e) {
            console.log(e);
        }
    },
  }
}
</script>