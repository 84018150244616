<template>
    <v-card>
        <v-card-title class="headline">Êtes-vous sûr de vouloir supprimer le RDV?</v-card-title>
        <v-card-text>
            <v-alert outlined
                    type="warning"
                    border="left">
                Cet action est irréversible
            </v-alert>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">Annuler</v-btn>
            <v-btn color="red darken-1" text @click="$emit('valid')">Supprimer</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
  name: 'DeleteRdvDialog',
  props: ['rdv'],

  methods: {
    
  }
}
</script>
