<template>
    <v-row class="mt-3">
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Nouvelle Annonce 
                    <v-btn class="ml-3" v-if="!annonce.reprise" @click="addReprise">Créer Reprise</v-btn>
                    <v-btn class="ml-3" v-if="annonce.reprise" :to="{ name: 'ReprisesEditView', params: { id: annonce.reprise.id }}">Voir Reprise</v-btn>
                </v-card-title>
                <v-card style="box-shadow: none;">
                    <v-form ref="form">
                        <v-stepper
                            v-model="e1"
                            vertical
                            style="box-shadow: none;"
                        >
                        <v-stepper-step
                            :complete="e1 > 1"
                            step="1"
                            >
                            INFORMATIONS CLIENT
                            </v-stepper-step>

                            <v-stepper-content step="1">
                                <v-card class="overflow-hidden">
                                    <v-row class="pl-6">
                                        <v-col cols="12" md="12">
                                            <div class="text-h6 ">Type d'annonce</div>
                                            <v-radio-group
                                                v-model="annonce.a_type"
                                                row
                                            >
                                            <v-radio
                                                label="Rachat direct"
                                                :value="0"
                                            ></v-radio>
                                            <v-radio
                                                label="Mise en dépôt / vente"
                                                :value="1"
                                            ></v-radio>
                                        </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <v-row v-if="new_customer == false">
                                                <v-col cols="6" md="6">
                                                    <v-autocomplete
                                                    label="Sélectionnez un client"
                                                    v-model="annonce.customer"
                                                    :items="customers"
                                                    item-value="id"
                                                    item-text="label"
                                                    required
                                                    :rules="rulesRequired"
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-btn
                                                        class="mr-2"
                                                        color="primary"
                                                        @click="new_customer = true"
                                                        >
                                                        Nouveau
                                                    </v-btn>
                                                </v-col>
                                            </v-row>

                                            <v-row v-if="new_customer == true">
                                                <v-col cols="12" md="12">
                                                    <v-btn
                                                        class="mr-2"
                                                        color="primary"
                                                        @click="new_customer = false"
                                                        >
                                                        Sélectionner un client
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_firstname"
                                                        label="Prénom"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_lastname"
                                                        label="Nom"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_email"
                                                        label="Adresse email"
                                                        required
                                                        :rules="emailRules"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_contact_from"
                                                        label="De la part de (Email)"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_phone"
                                                        label="Téléphone"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_address"
                                                        label="Adresse"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_cp"
                                                        label="Code postal"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_city"
                                                        label="Ville"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    <v-text-field
                                                        v-model="c_country"
                                                        label="Pays"
                                                        required
                                                        :rules="rulesRequired"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <!-- <v-row>
                                                <v-col md="12">
                                                    <v-radio-group
                                                        v-model="annonce.type"
                                                        :rules="rulesRequired"
                                                        required
                                                        column
                                                    >
                                                        <v-radio
                                                            label="Annonce seule"
                                                            value="out"
                                                        ></v-radio>
                                                        <v-radio
                                                            label="Annonce + RDV"
                                                            value="rdv"
                                                        ></v-radio>
                                                        <v-radio
                                                            label="Annonce + Demande de rappel"
                                                            value="tel"
                                                        ></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row> -->
                                        </v-col>
                                    </v-row>
                                    <v-btn
                                        class="primary float-right"
                                        @click="verifStep1"
                                    >
                                        Continuer
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>


                            <v-stepper-step
                            :complete="e1 > 2"
                            step="2"
                            >
                            INFORMATIONS VÉHICULE
                            </v-stepper-step>

                            <v-stepper-content step="2">
                                <v-card class="overflow-hidden">
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <v-btn
                                            class="mr-2"
                                            color="primary"
                                            @click="immat_set = true"
                                            >
                                            Immatriculation
                                            </v-btn>

                                            <v-btn
                                            color="primary"
                                            @click="e1 = 3"
                                            >
                                            Manuelle
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <v-text-field
                                                label="Immatriculation"
                                                v-if="immat_set==true"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-btn
                                        class="float-right"
                                        @click="e1 = e1 - 1"
                                    >
                                        Précédent
                                    </v-btn>
                                    <v-btn
                                        class="primary float-right"
                                        @click="e1 = e1 + 1"
                                    >
                                        Continuer
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>

                            <v-stepper-step
                            :complete="e1 > 3"
                            step="3"
                            >
                            CARACTÉRISTIQUES DU VÉHICULE
                            </v-stepper-step>

                            <v-stepper-content step="3">
                                <v-card class="overflow-hidden">
                                    <v-row>
                                        <v-col cols="6" md="3">
                                            <v-autocomplete
                                                label="Sélectionnez une marque"
                                                v-model="annonce.vehicule.marque"
                                                :items="marqueList"
                                                item-value="marque"
                                                item-text="marque"
                                                @change="getModelesByMarque(annonce.vehicule.marque)"
                                            ></v-autocomplete>
                                            <!-- <v-text-field
                                                v-model="annonce.vehicule.marque"
                                                label="Marque du véhicule"
                                            ></v-text-field> -->
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-autocomplete
                                                :disabled="!annonce.vehicule.marque"
                                                label="Sélectionnez un modele"
                                                v-model="annonce.vehicule.modele"
                                                :items="modeleList"
                                                item-value="modele"
                                                item-text="modele"
                                                @change="getFinitionsByModele(annonce.vehicule.marque,annonce.vehicule.modele)"
                                            ></v-autocomplete>
                                            <!-- <v-text-field
                                                v-model="annonce.vehicule.modele"
                                                label="Modèle du véhicule"
                                            ></v-text-field> -->
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-autocomplete
                                                :disabled="!annonce.vehicule.modele"
                                                :loading="loadingFinition"
                                                label="Sélectionnez une finition"
                                                v-model="annonce.vehicule.finition"
                                                :items="finitionList"
                                                item-value="version"
                                                item-text="version"
                                                @change="getYearsByFinition"
                                            ></v-autocomplete>
                                            <!-- <v-text-field
                                            v-model="annonce.vehicule.finition"
                                                label="Finition"
                                            ></v-text-field> -->
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="annonce.vehicule.transmission"
                                                label="Transmission"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-autocomplete
                                                :disabled="!annonce.vehicule.modele"
                                                :loading="loadingYear"
                                                label="1ère mise en circulation"
                                                v-model="annonce.vehicule.date_first"
                                                :items="yearList"
                                                @change="getDataByYears"
                                            ></v-autocomplete>
                                            <!-- <v-text-field
                                                type="number"
                                                v-model="annonce.vehicule.date_first"
                                                label="1ère mise en circulation"
                                            ></v-text-field> -->
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="annonce.vehicule.immat"
                                                label="N° Immatriculation"
                                            ></v-text-field>
                                        </v-col>

                                        <!-- <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="annonce.vehicule.carrosserie"
                                                label="État Carrosserie"
                                            ></v-text-field>
                                        </v-col> -->

                                        <v-col cols="6" md="3">
                                            <v-select
                                            label="Genre véhicule"
                                            v-model="annonce.vehicule.genre"
                                            :items="genre"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-select
                                            label="Energie"
                                            v-model="annonce.vehicule.energie"
                                            :items="['Essence', 'Diesel', 'Hybride', 'Hybride rechargeable','Électrique']"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-combobox
                                            label="Nombre de portes"
                                            v-model="annonce.vehicule.nb_porte"
                                            :items="nbPortesList"
                                            ></v-combobox>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-combobox
                                            label="Nombre de places"
                                            v-model="annonce.vehicule.nb_place"
                                            :items="nbPlacesList"
                                            ></v-combobox>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-select
                                            label="Type du vehicule"
                                            v-model="annonce.vehicule.type_vehicule"
                                            :items="['CTTE', 'VP']"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-select
                                            label="Coté du volant"
                                            v-model="annonce.vehicule.cote_volant"
                                            :items="['Droite', 'Gauche']"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                type="number"
                                                v-model="annonce.vehicule.km"
                                                label="Km au compteur"
                                                suffix="Km"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-combobox
                                                v-model="annonce.vehicule.puissance"
                                                label="Puissance véhicule"
                                                suffix="CV"
                                                :items="nbPuissanceCv"
                                            ></v-combobox>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-select
                                            label="Boite de vitesse"
                                            v-model="annonce.vehicule.boite_vitesse"
                                            :items="['Automatique', 'Manuelle']"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="annonce.vehicule.moteur"
                                                label="Cylindrée moteur"
                                            ></v-text-field>
                                        </v-col>

                                        <!-- <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_version"
                                                label="Version"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="6" md="3">
                                            <v-text-field
                                                v-model="v_nb_portes"
                                                label="Nombre de portes"
                                            ></v-text-field>
                                        </v-col> -->
                                        
                                    </v-row>

                                    <v-btn
                                        class="float-right"
                                        @click="e1 = e1 - 1"
                                    >
                                        Précédent
                                    </v-btn>
                                    <v-btn
                                        class="primary float-right mr-3"
                                        @click="e1 = e1 + 1"
                                    >
                                        Continuer
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>


                            <v-stepper-step
                            :complete="e1 > 4"
                            step="4"
                            >
                            INFORMATIONS GÉNÉRALES
                            </v-stepper-step>

                            <v-stepper-content step="4">
                                <v-card class="overflow-hidden">
                                    
                                    <v-row>
                                        <v-col class="d-flex flex-column justify-space-between" cols="12" md="12">
                                            <div class="text-h6">Dans quel état est le véhicule ?</div>
                                            <v-select
                                            label="État"
                                            v-model="annonce.etat_vehicule"
                                            :items="etat_vehicule_list"
                                            ></v-select>
                                        </v-col>
                                        <v-col class="select_color d-flex flex-column justify-space-between" cols="12" md="6">
                                            <div class="text-h6">Couleur extérieur ? <span v-if="annonce.color_ext">{{ annonce.color_ext }}</span></div>
                                            <v-radio-group v-model="annonce.color_ext" row>
                                                <v-radio v-for="item in color_ext_list" :key="item.label" :value="item.label">
                                                    <template v-slot:label>
                                                        <div class="mb-3" style="width:40px;height:40px;border-radius:50%;border:1px solid black" :style="{background:item.color}"></div>
                                                    </template>
                                                </v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col class="select_color d-flex flex-column justify-space-between" cols="12" md="6">
                                            <div class="text-h6">Couleur intérieur ? <span v-if="annonce.color_int">{{ annonce.color_int }}</span></div>
                                            <v-radio-group v-model="annonce.color_int" row>
                                                <v-radio v-for="item in color_int_list" :key="item.label" :value="item.label">
                                                    <template v-slot:label>
                                                        <div class="mb-3" style="width:40px;height:40px;border-radius:50%;border:1px solid black" :style="{background:item.color}"></div>
                                                    </template>
                                                </v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col class="d-flex flex-column justify-space-between" cols="12" md="6">
                                            <div class="text-h6">Type de siège ?</div>
                                            <v-select
                                            label="Type de siège"
                                            v-model="annonce.type_siege"
                                            :items="type_siege_list"
                                            ></v-select>
                                        </v-col>
                                        <v-col class="d-flex flex-column justify-space-between" cols="12" md="6">
                                            <div class="text-h6">Possedez-vous le carnet d’entretien ou les factures d’intervention ?</div>
                                            <v-select
                                            label="Carnet entretien / Factures"
                                            v-model="annonce.doc_vehicule"
                                            :items="['Toujours', 'Quelques', 'Aucun']"
                                            ></v-select>
                                        </v-col>
                                        <v-col class="d-flex flex-column justify-space-between" cols="12" md="12">
                                            <div class="text-h6">Vente en tant que</div>
                                            <v-select
                                            label="Type de vente"
                                            v-model="annonce.type_vente"
                                            :items="['Particulier (pas de TVA)', 'Entreprise (TVA récupérable)', 'Entreprise (TVA non récupérable)']"
                                            ></v-select>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        class="float-right"
                                        @click="e1 = e1 - 1"
                                    >
                                        Précédent
                                    </v-btn>
                                    <v-btn
                                        class="primary float-right mr-3"
                                        @click="e1 = e1 + 1"
                                    >
                                        Continuer
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>


                            <v-stepper-step
                            :complete="e1 > 5"
                            step="5"
                            >
                            INFORMATIONS TECHNIQUES
                            </v-stepper-step>

                            <v-stepper-content step="5">
                                <v-card class="overflow-hidden">
                                    
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <div class="text-h6">Le vehicule est-il en etat de rouler ?</div>
                                            <v-switch
                                            v-model="annonce.canroll"
                                            >
                                                <template v-slot:label v-if="annonce.canroll">
                                                    <div>Roulant</div>
                                                </template>
                                                <template v-slot:label v-else>
                                                    <div>Non roulant</div>
                                                </template>
                                            </v-switch>
                                        </v-col>
                                        <v-col class="d-flex flex-column justify-space-between" cols="12" md="6">
                                            <div class="text-h6">Dans quel état est la carrosserie exterieure ?</div>
                                            <v-select
                                            label="État carrosserie"
                                            v-model="annonce.etat_carrosserie"
                                            :items="['Parfait', 'Usure normale', 'Mauvais']"
                                            ></v-select>
                                        </v-col>
                                        <v-col class="d-flex flex-column justify-space-between" cols="12" md="6">
                                            <div class="text-h6">Dans quel état est l'intérieur ?</div>
                                            <v-select
                                            label="État carrosserie"
                                            v-model="annonce.etat_interieur"
                                            :items="['Parfait', 'Usure normale', 'Mauvais']"
                                            ></v-select>
                                        </v-col>
                                        <v-col class="d-flex flex-column justify-space-between" cols="12" md="6">
                                            <div class="text-h6">Le moteur fonctionne normalement ?</div>
                                            <v-switch
                                            v-model="annonce.fonction_moteur"
                                            >
                                                <template v-slot:label v-if="annonce.fonction_moteur">
                                                    <div>Oui</div>
                                                </template>
                                                <template v-slot:label v-else>
                                                    <div>Non</div>
                                                </template>
                                            </v-switch>
                                        </v-col>
                                        <v-col class="d-flex flex-column justify-space-between" cols="12" md="6">
                                            <div class="text-h6">La boite de vitesse fonctionne normalement ?</div>
                                            <v-switch
                                            v-model="annonce.fonction_boite_vitesse"
                                            >
                                                <template v-slot:label v-if="annonce.fonction_boite_vitesse">
                                                    <div>Oui</div>
                                                </template>
                                                <template v-slot:label v-else>
                                                    <div>Non</div>
                                                </template>
                                            </v-switch>
                                        </v-col>
                                        <v-col class="d-flex flex-column justify-space-between" cols="12" md="6">
                                            <div class="text-h6">Les freins fonctionnent normalement ?</div>
                                            <v-switch
                                            v-model="annonce.fonction_freins"
                                            >
                                                <template v-slot:label v-if="annonce.fonction_freins">
                                                    <div>Oui</div>
                                                </template>
                                                <template v-slot:label v-else>
                                                    <div>Non</div>
                                                </template>
                                            </v-switch>
                                        </v-col>
                                        <v-col class="d-flex flex-column justify-space-between" cols="12" md="6">
                                            <div class="text-h6">Les suspensions fonctionnent normalement ?</div>
                                            <v-switch
                                            v-model="annonce.fonction_suspensions"
                                            >
                                                <template v-slot:label v-if="annonce.fonction_suspensions">
                                                    <div>Oui</div>
                                                </template>
                                                <template v-slot:label v-else>
                                                    <div>Non</div>
                                                </template>
                                            </v-switch>
                                        </v-col>
                                        <v-col class="d-flex flex-column justify-space-between" cols="12" md="6" align-self="stretch">
                                            <div class="text-h6">La climatisation fonctionne normalement ?</div>
                                            <v-switch
                                            v-model="annonce.fonction_climatisation"
                                            >
                                                <template v-slot:label v-if="annonce.fonction_climatisation">
                                                    <div>Oui</div>
                                                </template>
                                                <template v-slot:label v-else>
                                                    <div>Non</div>
                                                </template>
                                            </v-switch>
                                        </v-col>
                                        <v-col class="d-flex flex-column justify-space-between" cols="12" md="6" align-self="stretch">
                                            <div class="text-h6">Voyants allumés sur le tableau de bord ?</div>
                                            <v-switch
                                            v-model="annonce.voyants"
                                            >
                                                <template v-slot:label v-if="annonce.voyants">
                                                    <div>Oui</div>
                                                </template>
                                                <template v-slot:label v-else>
                                                    <div>Non</div>
                                                </template>
                                            </v-switch>
                                            <v-text-field
                                                v-if="annonce.voyants"
                                                v-model="annonce.voyants_precision"
                                                label="Précisions"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col class="d-flex flex-column justify-space-between" cols="12" md="12" align-self="stretch">
                                            <div class="text-h6">Pays du certificat d'immatriculation ?</div>
                                            <v-select
                                            label="Pays certificat d'immatriculation"
                                            v-model="annonce.pays_immat"
                                            :items="list_pays"
                                            ></v-select>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        class="float-right"
                                        @click="e1 = e1 - 1"
                                    >
                                        Précédent
                                    </v-btn>
                                    <v-btn
                                        class="primary float-right mr-3"
                                        @click="e1 = e1 + 1"
                                    >
                                        Continuer
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>


                            <v-stepper-step
                            :complete="e1 > 6"
                            step="6"
                            >
                            PHOTOS VÉHICULE
                            </v-stepper-step>

                            <v-stepper-content step="6">
                                <v-card class="overflow-hidden">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-textarea
                                                v-model="annonce.observations"
                                                filled
                                                label="Observations"
                                                auto-grow
                                            ></v-textarea>
                                        </v-col>
                                        <v-col cols="12" md="6" class="bloc_repas">
                                            <v-checkbox
                                                v-for="item in v_repas_list" :key="item" :value="item"
                                                v-model="annonce.repa_list"
                                                class="d-inline-block mt-0 ml-2 mr-2"
                                            >
                                                <template v-slot:label>
                                                    {{ item }}
                                                </template>
                                            </v-checkbox>

                                            <v-text-field
                                                class="ml-2 mr-2"
                                                v-model="new_repa"
                                                label="Ajouter une réparation"
                                                append-outer-icon="mdi-plus-box"
                                                @click:append-outer="addRepa"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6" md="6">
                                            <h4>Photos Générales</h4>
                                            <v-row class="mt-2 mb-2">
                                                <v-col v-for="photo in photosGeneral" :key="photo.id" cols="12" md="4" style="position:relative">
                                                    <v-img :src="'data:image/png;base64,' + photo.image" :data-caption="photo.description" data-fancybox="photoReprise" :data-src="'data:image/png;base64,' + photo.image" min-height="100%" style="cursor: pointer;"></v-img>
                                                    <div v-if="photo.description" class="pa-1" style="position:absolute;bottom:12px;left:12px;right:12px;color:#fff;background: rgba(0,0,0,.5);">{{ photo.description }}</div>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="red" class="pa-0 mr-3" small text-color="white" style="position:absolute;top:0;right:0;" @click="activePhotoVehicule = photo.id;deletePhotoRepriseDialog = true">
                                                                <v-icon>
                                                                mdi-close
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Supprimer</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="blue" class="pa-0 mr-3" small text-color="white" style="position:absolute;bottom:16px;right:5px;" @click="activePhoto = photo;DescPhotoDialog = true">
                                                                <v-icon>
                                                                mdi-pencil
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Editer</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom v-if="photo.main_photo">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="grey" class="pa-0 ml-4 mt-4" small text-color="yellow" style="position:absolute;top:0;left:0;">
                                                                <v-icon>
                                                                mdi-star
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Photo principale</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom v-if="!photo.main_photo">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="grey" class="pa-0 ml-4 mt-4" small text-color="white" style="position:absolute;top:0;left:0;" @click="setMainPhotoVehicule(photo.id);">
                                                                <v-icon>
                                                                mdi-star-outline
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Definir photo principale</span>
                                                    </v-tooltip>
                                                </v-col>
                                                
                                            </v-row>
                                            

                                        </v-col>
                                        <v-col md="6">
                                            <h4>Photos Réparations</h4>
                                            <v-row class="mt-2 mb-2">
                                                <v-col v-for="photo in photosDamage" :key="photo.id" cols="12" md="4" style="position:relative">
                                                    <v-img :src="'data:image/png;base64,' + photo.image" :data-caption="photo.description" data-fancybox="photoReprise" :data-src="'data:image/png;base64,' + photo.image" min-height="100%" style="cursor: pointer;"></v-img>
                                                    <div v-if="photo.description" class="pa-1" style="position:absolute;bottom:12px;left:12px;right:12px;color:#fff;background: rgba(0,0,0,.5);">{{ photo.description }}</div>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="red" class="pa-0 mr-3" small text-color="white" style="position:absolute;top:0;right:0;" @click="activePhotoVehicule = photo.id;deletePhotoRepriseDialog = true">
                                                                <v-icon>
                                                                mdi-close
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Supprimer</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="blue" class="pa-0 mr-3" small text-color="white" style="position:absolute;bottom:16px;right:5px;" @click="activePhoto = photo;DescPhotoDialog = true">
                                                                <v-icon>
                                                                mdi-pencil
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Editer</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom v-if="photo.main_photo">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="grey" class="pa-0 ml-4 mt-4" small text-color="yellow" style="position:absolute;top:0;left:0;">
                                                                <v-icon>
                                                                mdi-star
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Photo principale</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom v-if="!photo.main_photo">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip v-bind="attrs" v-on="on" color="grey" class="pa-0 ml-4 mt-4" small text-color="white" style="position:absolute;top:0;left:0;" @click="setMainPhotoVehicule(photo.id);">
                                                                <v-icon>
                                                                mdi-star-outline
                                                                </v-icon>
                                                            </v-chip>
                                                        </template>
                                                        <span>Definir photo principale</span>
                                                    </v-tooltip>
                                                    
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col md="12">
                                            <v-file-input
                                                multiple
                                                counter
                                                small-chips
                                                v-model="v_photos"
                                                label="Ajouter des photos"
                                            ></v-file-input>
                                            <v-checkbox
                                                v-model="isDamage"
                                                label="Photos des dommages ?"
                                            ></v-checkbox>
                                            <v-btn
                                                color="primary"
                                                @click.stop="onUpload"
                                            >UPLOAD</v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        class="float-right"
                                        @click="e1 = e1 - 1"
                                    >
                                        Précédent
                                    </v-btn>
                                    <v-btn
                                        class="primary float-right mr-3"
                                        @click="e1 = e1 + 1"
                                    >
                                        Continuer
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>

                            <v-stepper-step
                            :complete="e1 > 7"
                            step="7"
                            >
                            PRÉCISIONS
                            </v-stepper-step>

                            <v-stepper-content step="7">
                                <v-card class="overflow-hidden">
                                    <v-row>
                                        <!-- <v-col cols="12" md="6">
                                            <div class="text-h6">Date limite d'enchère ? (Fin à minuit)</div>
                                            <v-date-picker v-model="annonce.formatDate"></v-date-picker>

                                        </v-col> -->
                                        <v-col cols="12" md="12">
                                            <div class="text-h6">Durée de l'enchère ? (à partir de la première offre)</div>
                                            <v-switch
                                                v-model="isDirectSell"
                                                hide-details
                                            >
                                            <template v-slot:label>
                                                <span v-if="isDirectSell">Vente immédiate</span>
                                                <span v-else>Enchère</span>
                                            </template>
                                            </v-switch>

                                        </v-col>

                                        <v-col cols="12" md="6" v-if="isDirectSell">
                                            <div class="text-h6">Prix de vente ?</div>
                                            <v-text-field
                                                v-model="annonce.sellPrice"
                                                label="Prix de vente"
                                                suffix="€"
                                            ></v-text-field>

                                        </v-col>

                                        <v-col cols="12" md="6" v-if="!isDirectSell">
                                            <div class="text-h6">Durée de l'enchère ? (à partir de la première offre)</div>
                                            <v-text-field
                                                v-model="annonce.bidDuration"
                                                label="Durée de l'enchère"
                                                suffix="jours"
                                            ></v-text-field>

                                        </v-col>
                                        <v-col cols="12" md="6" v-if="!isDirectSell">
                                            <div class="text-h6">Prix de départ enchère ?</div>
                                            <v-text-field
                                                v-model="annonce.beginPrice"
                                                label="Prix de départ"
                                                suffix="€"
                                            ></v-text-field>
                                            <div class="text-h6">Prix de réserve enchère ?</div>
                                            <v-text-field
                                                v-model="annonce.reservePrice"
                                                label="Prix de réserve"
                                                suffix="€"
                                            ></v-text-field>

                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <div class="text-h6">Avez-vous des elements supplementaires a nous preciser ?</div>
                                            <v-textarea
                                            v-model="annonce.autre_info"
                                            filled
                                            label="Message"
                                            auto-grow
                                            ></v-textarea>

                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        class="float-right"
                                        @click="e1 = e1 - 1"
                                    >
                                        Précédent
                                    </v-btn>
                                    <v-btn
                                        class="primary float-right mr-3"
                                        :loading="loadingSave"
                                        :disabled="loadingSave"
                                        @click="updateAnnonce(1); loader = 'loadingSave';"
                                    >
                                        Continuer
                                    </v-btn>
                                </v-card>
                                
                            </v-stepper-content>
                        
                        </v-stepper>
                    </v-form>
                    <v-dialog v-model="deletePhotoRepriseDialog" max-width="600px">
                        <DeletePhotoRepriseDialog v-on:valid="deletePhotoReprises" v-on:close="deletePhotoRepriseDialog = false"></DeletePhotoRepriseDialog>
                    </v-dialog>
                    <v-dialog v-model="DescPhotoDialog" max-width="800px">
                        <DescPhotoDialog :activePhoto="activePhoto" v-on:close="DescPhotoDialog = false" v-on:valid="DescPhotoDialog = false;getPhotoVehicule;"></DescPhotoDialog>
                    </v-dialog>
                </v-card>
            </v-card>
        </v-col>
    </v-row>
</template>

<style>
    .bloc_repas .v-input--selection-controls__input {
        display: none;
    }
    .bloc_repas .v-input:not(.v-text-field) .v-input__slot {
        margin: 0;
        padding: 15px;
        border-radius: 5px;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
        transition-duration: .2;
    }
    .bloc_repas .v-input:not(.v-text-field) .v-input__slot:hover {
        margin: 0;
        padding: 15px;
        border-radius: 5px;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .bloc_repas .v-input--is-label-active:not(.v-text-field) .v-input__slot {
        background: rgb(2, 125, 176);
        color: #fff;
    }
    .bloc_repas .v-input--is-label-active:not(.v-text-field) .v-input__slot label {
        color: #fff;
    }
    .select_color .v-input--radio-group__input .v-item--active > label > div {
        border: 3px solid #000!important;
    }
    .select_color .v-input--selection-controls__input {
        display: none;
    }
    .v-input.v-select {
        flex: 0 1 auto;
    }
</style>

<script>
    import axios from "axios";
    import { Fancybox } from "@fancyapps/ui";
    import "@fancyapps/ui/dist/fancybox/fancybox.css";


    import DeletePhotoRepriseDialog from "@/components/reprises/DeletePhotoRepriseDialog";
    import DescPhotoDialog from "@/components/photos/DescPhotoDialog";

    export default {
        name: 'AnnoncesEditView',

        components: {
            //vueCountryRegionSelect
            DeletePhotoRepriseDialog,
            DescPhotoDialog,
        },

        data() {
            return {
                deletePhotoRepriseDialog: false,
                DescPhotoDialog: false,

                loadingSave: false,

                activePhoto: null,

                e1: 1,

                annonce: [],
                new_repa: "",

                marqueList: [],
                modeleList: [],
                finitionList: [],
                loadingFinition: false,
                yearList: [],
                nbPortesList: [],
                nbPlacesList: [],
                nbPuissanceCv: [],
                loadingYear: false,

                customers: [],
                new_customer: false,
                customer: '',
                c_firstname: '',
                c_lastname: '',
                c_email: '',
                c_contact_from: '',
                c_phone: '',
                c_address: '',
                c_cp: '',
                c_city: '',
                c_country: '',

                isDirectSell: false,

               //Step 1
                immat_set: false,

                //Step 2
                marque: '',
                marquesList: [],

                modele: '',
                modelesList: [],

                finition: '',
                transmition: '',
                date_first: '',
                immat: '',
                carrosserie: '',
                genre: [
                    "Cabriolet",
                    "Coupé",
                    "Break",
                    "Berline",
                    "SUV",
                    "Pick Up",
                    "Utilitaire",
                    "Citadine",
                ],
                energie: '',
                nb_porte: '',
                km: '',
                puissance: '',
                boite_vitesse: '',
                moteur: '',

                //Step 3
                etat_vehicule: '',
                etat_vehicule_list: ['Neuf', 'Trés bon état', 'Bon état', 'Passable'],
                color_ext: '',
                color_ext_list: [
                    {
                        label: "Noir",
                        color: "black"
                    },
                    {
                        label: "Gris",
                        color: "grey"
                    },
                    {
                        label: "Argent",
                        color: "silver"
                    },
                    {
                        label: "Violet",
                        color: "violet"
                    },
                    {
                        label: "Bleu",
                        color: "blue"
                    },
                    {
                        label: "Vert",
                        color: "green"
                    },
                    {
                        label: "Beige",
                        color: "beige"
                    },
                    {
                        label: "Or",
                        color: "gold"
                    },
                    {
                        label: "Jaune",
                        color: "yellow"
                    },
                    {
                        label: "Orange",
                        color: "orange"
                    },
                    {
                        label: "Rouge",
                        color: "red"
                    },
                    {
                        label: "Marron",
                        color: "brown"
                    },
                    {
                        label: "Blanc",
                        color: "white"
                    }
                ],
                color_int: '',
                color_int_list: [
                {
                        label: "Noir",
                        color: "black"
                    },
                    {
                        label: "Gris",
                        color: "grey"
                    },
                    {
                        label: "Argent",
                        color: "silver"
                    },
                    {
                        label: "Violet",
                        color: "violet"
                    },
                    {
                        label: "Bleu",
                        color: "blue"
                    },
                    {
                        label: "Vert",
                        color: "green"
                    },
                    {
                        label: "Beige",
                        color: "beige"
                    },
                    {
                        label: "Or",
                        color: "gold"
                    },
                    {
                        label: "Jaune",
                        color: "yellow"
                    },
                    {
                        label: "Orange",
                        color: "orange"
                    },
                    {
                        label: "Rouge",
                        color: "red"
                    },
                    {
                        label: "Marron",
                        color: "brown"
                    },
                    {
                        label: "Blanc",
                        color: "white"
                    }
                ],
                type_siege: '',
                type_siege_list: ['Tissu', 'Tissu et simili-cuir', 'Cuir', 'Alcantara', 'Autre'],
                doc_vehicule: '',
                type_vente: '',

                canroll: false,
                etat_carrosserie: '',
                etat_interieur: '',
                fonction_moteur: true,
                fonction_boite_vitesse: true,
                fonction_freins: false,
                fonction_suspensions: true,
                fonction_climatisation: true,
                voyants: false,
                voyants_precision: '',
                pays_immat: '',
                list_pays: ['France', 'Luxembourg', 'Belgique'],

                v_repas_list: [
                    "Disques freins avant", 
                    "Disques freins arriere", 
                    "Plaquettes freins avant", 
                    "Plaquettes freins arriere", 
                    "2 pneus avant",
                    "2 pneus arrière",
                    "Révision / vidange",
                    "Courroie de distribution",
                    "Vidange boite auto",
                    "Embrayage"
                ],

                v_photos: [],
                photos: [],
                photosGeneral: [],
                photosDamage: [],
                isDamage: false,
                activePhotoVehicule: [],
                listeEmail: [],

                bidEnd: '',
                autre_info: '',

                rulesRequired: [
                    v => !!v || 'Veuillez renseigner ce champs',
                ],
                emailRules: [ 
                    v => /^[^\s@]+@[^\s@]+$/.test(v) || 'Adresse email non valide !',
                    v => !this.listeEmail.includes(v) || 'L\'Email existe déjà'
                ],
                //emailExistRules: [ v => this.getCustomerByEmail(v) || 'Email existe']

            }
        },
        async created() {
            console.log(this.listeEmail)
            await this.getAnnonce();
            await this.getPhotoVehicule();
            await this.getCustomers();
            await this.checkStatus();
            await this.getRepas();
            await this.getMarques();

            if(this.annonce.user.agence != this.user.agence.id && this.user.status.id != 1){
                this.$router.push({ path: '/annonces'});
            }
            //await this.getMarquesList();
            Fancybox.bind("[data-fancybox='photoReprise']", {});

        },
        methods: {
            validate () {
                this.$refs.form.validate()
            },
            verifStep1: function() {
                
                if(!this.new_customer){
                    if(!this.annonce.customer || !this.annonce.type) {
                        console.log("manque client")
                        this.validate();
                        //this.snackbarUncompleted = true;
                    } else {
                        this.e1 = this.e1 + 1;
                        //this.snackbarUncompleted = false;
                    }
                } else {
                    if( !this.c_firstname
                        || !this.c_lastname
                        || !this.c_email
                        || !this.c_phone
                        || this.listeEmail.includes(this.c_email)
                    ) {
                        console.log("manque client")
                        this.validate();
                        //this.snackbarUncompleted = true;
                    } else {
                        this.e1 = this.e1 + 1;
                        //this.snackbarUncompleted = false;
                    }
                }
            },
            checkStatus: async function () {
                try {
                    if(this.annonce.status.id == 3){
                        this.isDirectSell = true
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            // getRepas: async function () {
            //     try {
            //         const response = await axios.get(this.baseApiURL() + '/reparations', this.getHeaders());
            //         if(response){
            //             this.v_repas_list = response.data;
            //             console.log(this.v_repas_list);
            //         }
            //     } catch (e) {
            //         console.log(e);
            //     }
            // },
            // addRepa: async function () {
            //     try {
            //         console.log("test")
            //         const response = await axios.post(this.baseApiURL() + '/reparation', {
            //             label: this.new_repa,
                        
            //         }, this.getHeaders());
            //         if (response) {
            //             await this.getRepas();
            //             this.new_repa = "";
            //         }
            //     } catch (e) {
            //         console.log(e);
            //     }
            // },
            addRepa: async function () {
                try {
                    this.v_repas_list.push(this.new_repa);
                    this.new_repa = "";
                } catch (e) {
                    console.log(e);
                }
            },
            getRepas: async function () {
                try {
                    let currentRepa = this.annonce.repa_list;

                    for(let item of currentRepa){
                        console.log()
                        if(!this.v_repas_list.includes(item)) {
                            this.v_repas_list.push(item);
                        }
                    }
                    
                } catch (e) {
                    console.log(e);
                }
            },
            addReprise: async function () {
                try {
                    //const vehicule_id = await this.addVehicule();

                    const response = await axios.post(this.baseApiURL() + '/reprise', {
                        user: this.user.id,
                        customer: this.annonce.customer.id,
                        vehicule: this.annonce.vehicule.id,
                        agence: this.user.agence.id,
                        annonce: this.annonce.id,
                        pm_raison: '',
                        pm_siret: '',
                        co_firstname: "",
                        co_lastname: "",
                        type: "",
                        observations: this.annonce.observations,
                        repa_list: JSON.stringify(this.annonce.repa_list),
                        photos: "",
                        price_repa: "",
                        price_marge: "",
                        price_tva: "",
                        status: 1,
                        
                    }, this.getHeaders());
                    if (response) {
                        const reprise = response.data;
                        //console.log(response.data);
                        await axios.put(this.baseApiURL() + '/annonce/'+this.annonce.id+'/reprise/'+reprise.id, {}, this.getHeaders())
                        this.$router.push({ path: '/reprise_edit/' + reprise.id});
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getAnnonce: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/annonce/' + this.$route.params.id, this.getHeaders());
                    if(response){
                        this.annonce = response.data;

                        if(this.annonce.repa_list){
                            this.annonce.repa_list = JSON.parse(this.annonce.repa_list)
                        }else{
                            this.annonce.repa_list = [];
                        }

                        this.annonce.formatDate = await this.formatDatePicker(this.annonce.bidEnd);
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            getMarques: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/car_data/marques', this.getHeaders());
                    if(response) {
                        //console.log(response.data.rows);
                        this.marqueList = response.data;
                        if(this.annonce.vehicule.marque){
                            this.getModelesByMarque(this.annonce.vehicule.marque);

                            if(this.annonce.vehicule.modele){
                                this.getFinitionsByModele(this.annonce.vehicule.marque,this.annonce.vehicule.modele)
                            }
                        }
                        
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getModelesByMarque: async function (marque) {
                try {
                    const response = await axios.post(this.baseApiURL() + '/car_data/modeles_by_marque', {
                        marque: marque
                    },this.getHeaders());
                    if(response) {
                        // console.log(response.data);
                        this.modeleList = response.data;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getFinitionsByModele: async function (marque, modele) {
                try {
                    this.loadingFinition = true;
                    this.loadingYear = true;
                    const response = await axios.post(this.baseApiURL() + '/car_data/finitions_by_modele', {
                        marque: marque,
                        modele: modele
                    },this.getHeaders());
                    if(response) {
                        // console.log(response.data);
                        this.finitionList = response.data;
                        //console.log(this.finitionList)
                        for(let line of this.finitionList) {
                            if(!this.yearList.includes(line.annee)){
                                this.yearList.push(line.annee);
                            }
                        }
                        this.loadingFinition = false;
                        this.loadingYear = false;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getYearsByFinition: async function () {
                try {
                    this.loadingYear = true;
                    this.yearList = [];
                    for(let line of this.finitionList) {
                        if(!this.yearList.includes(line.annee) && line.version == this.annonce.vehicule.finition){
                            this.yearList.push(line.annee);
                        }
                        
                    }
                    this.loadingYear = false;
                } catch (e) {
                    console.error(e);
                }
            },
            getDataByYears: async function () {
                try {
                    
                    this.nbPortesList = [];
                    this.nbPlacesList = [];
                    this.nbPuissanceCv = [];
                    for(let line of this.finitionList) {
                        if(!this.nbPortesList.includes(line.nombre_de_portes) && line.version == this.annonce.vehicule.finition && line.annee == this.annonce.vehicule.date_first){
                            this.nbPortesList.push(line.nombre_de_portes);
                        }
                        if(!this.nbPlacesList.includes(line.nombre_de_places_assises) && line.version == this.annonce.vehicule.finition && line.annee == this.annonce.vehicule.date_first){
                            this.nbPlacesList.push(line.nombre_de_places_assises);
                        }
                        if(!this.nbPuissanceCv.includes(line.puissance_fiscale_cv) && line.version == this.annonce.vehicule.finition && line.annee == this.annonce.vehicule.date_first){
                            this.nbPuissanceCv.push(line.puissance_fiscale_cv);
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getCustomers: async function () {
                try {
                    const custType = await axios.get(this.baseApiURL() + '/settingValue/customer_list', this.getHeaders());
                    const response = await axios.get(this.baseApiURL() + '/customers', this.getHeaders());
                    if(response) {
                        this.customers = response.data;
                        for(const customer in this.customers){
                            this.customers[customer].label = this.customers[customer].firstname + " " + this.customers[customer].lastname + " (" + this.customers[customer].email + ")";
                            this.listeEmail.push(this.customers[customer].email)
                        }
                    }
                    console.log(custType.data.value)
                    if (custType.data.value == 'false' && this.user.agence.id != 1){
                        const response2 = await axios.get(this.baseApiURL() + '/customersByAgence/' + this.user.agence.id, this.getHeaders());
                        if(response2) {
                            this.customers = response2.data;
                            for(const customer in this.customers){
                                this.customers[customer].label = this.customers[customer].firstname + " " + this.customers[customer].lastname + " (" + this.customers[customer].email + ")";
                                this.listeEmail.push(this.customers[customer].email)
                            }
                        }
                    }
                    
                } catch (e) {
                    console.error(e);
                }
            },
            getCustomerByEmail: async function (email) {
                try {
                    const response = await axios.post(this.baseApiURL() + '/customer_email',{
                        email: email,
                    }, this.getHeaders());
                    if(response.data) {
                        console.log("existe")
                        return false;
                    }else{
                        return true;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            addCustomer: async function () {
                try {

                    this.success = false;
                    const response = await axios.post(this.baseApiURL() + '/customer', {
                        firstname: this.c_firstname,
                        lastname: this.c_lastname,
                        address: this.c_address,
                        cp: this.c_cp,
                        city: this.c_city,
                        country: this.c_country,
                        email: this.c_email,
                        contact_from: this.c_contact_from,
                        phone: this.c_phone,
                        note: '',
                        
                    }, this.getHeaders());
                    if (response) {
                        return response.data.id;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            updateAnnonce: async function (status) {
                try {
                    //var customer_id = 1;
                    this.loadingSave = true;
                    var customer_id = null;

                    if(this.new_customer){
                        customer_id = await this.addCustomer();
                    }else if(this.annonce.customer.id){
                        customer_id = this.annonce.customer.id;
                    }else if(this.annonce.customer){
                        customer_id = this.annonce.customer;
                    }

                    var vehicule_id = this.annonce.vehicule.id;

                    //console.log(JSON.parse(JSON.stringify(this.v_repas)));
                    await axios.put(this.baseApiURL() + '/vehicule/'+vehicule_id, {
                        marque: this.annonce.vehicule.marque,
                        modele: this.annonce.vehicule.modele,
                        finition: this.annonce.vehicule.finition,
                        transmission: this.annonce.vehicule.transmission,
                        date_first: this.annonce.vehicule.date_first,
                        immat: this.annonce.vehicule.immat,
                        carrosserie: this.annonce.vehicule.carrosserie,
                        genre: this.annonce.vehicule.genre,
                        energie: this.annonce.vehicule.energie,
                        nb_porte: this.annonce.vehicule.nb_porte,
                        nb_place: this.annonce.vehicule.nb_place,
                        type_vehicule: this.annonce.vehicule.type_vehicule,
                        cote_volant: this.annonce.vehicule.cote_volant,
                        km: this.annonce.vehicule.km,
                        puissance: this.annonce.vehicule.puissance,
                        boite_vitesse: this.annonce.vehicule.boite_vitesse,
                        moteur: this.annonce.vehicule.moteur,
                        
                    }, this.getHeaders());
                    
                    

                    if(vehicule_id && customer_id) {

                        if(this.isDirectSell){
                            status = 3;
                            this.annonce.beginPrice = null;
                            this.annonce.reservePrice = null;
                            this.annonce.bidDuration = null;
                        }else{
                            status = 1;
                            this.annonce.sellPrice = null;
                        }

                        console.log(this.annonce.a_type)

                        const response = await axios.put(this.baseApiURL() + '/annonce/'+this.annonce.id, {
                            //user: this.user.id,
                            customer: customer_id,
                            //customer: 1,
                            vehicule: this.annonce.vehicule,
                            agence: this.user.agence.id,
                            a_type: this.annonce.a_type,
                            etat_vehicule: this.annonce.etat_vehicule,
                            color_ext: this.annonce.color_ext,
                            color_int: this.annonce.color_int,
                            type_siege: this.annonce.type_siege,
                            doc_vehicule: this.annonce.doc_vehicule,
                            type_vente: this.annonce.type_vente,
                            canroll: this.annonce.canroll,
                            etat_carrosserie: this.annonce.etat_carrosserie,
                            etat_interieur: this.annonce.etat_interieur,
                            fonction_moteur: this.annonce.fonction_moteur,
                            fonction_boite_vitesse: this.annonce.fonction_boite_vitesse,
                            fonction_freins: this.annonce.fonction_freins,
                            fonction_suspensions: this.annonce.fonction_suspensions,
                            fonction_climatisation: this.annonce.fonction_climatisation,
                            voyants: this.annonce.voyants,
                            voyants_precision: this.annonce.voyants_precision,
                            pays_immat: this.annonce.pays_immat,
                            observations: this.annonce.observations,
                            repa_list: JSON.stringify(this.annonce.repa_list),
                            autre_info: this.annonce.autre_info,
                            status: status,
                            //type: this.annonce.type,
                            beginPrice: this.annonce.beginPrice,
                            reservePrice: this.annonce.reservePrice,
                            sellPrice: this.annonce.sellPrice,
                            //bidEnd: this.annonce.formatDate + " 23:59:59",
                            bidDuration: this.annonce.bidDuration,
                            
                        }, this.getHeaders());
                        if (response) {
                            //console.log(response.data);
                            this.$router.push({ path: '/annonces?isUpdateAnnonce=1'});
                            this.loadingSave = false;
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getPhotoVehicule: async function () {
                try {
                    //console.log(this.reprise.vehicule.id);
                    const response = await axios.get(this.baseApiURL() + '/vehicule/'+this.annonce.vehicule.id+'/photo', this.getHeaders());
                    if(response) {
                        this.photos = response.data;
                        this.photosGeneral = [];
                        this.photosDamage = [];
                        //console.log(this.photos)
                        //console.log(this.photos);
                        for(let photo of this.photos){
                            console.log(photo)
                           if(photo.isDamage){
                                this.photosDamage.push(photo)
                           }else{
                                this.photosGeneral.push(photo)
                           }
                        }

                        //console.log(this.photosGeneral)
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            setMainPhotoVehicule: async function (photoId) {
                try {
                    const response = await axios.put(this.baseApiURL() + '/vehicule/'+this.annonce.vehicule.id+'/main_photo/'+photoId, this.getHeaders());
                    if(response) {
                        this.getPhotoVehicule();
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            onUpload: async function () {
                try {
                    // const FormData = require("form-data");
                    const formdata = new FormData();
                    // formdata.append('photo',this.v_photos);
                    formdata.append("photos", "");
                    formdata.append("name", "");
                    //console.log(this.annonce.vehicule.id);
                    for (let photo of this.v_photos) {
                        formdata.set("photos", photo);
                        formdata.set("name", photo.name);
                        var isDamage = "";
                        if(this.isDamage){
                            isDamage = 1;
                        }else{
                            isDamage = 0;
                        }
                        //console.log(this.token)
                        await axios.post(this.baseApiURL() + '/vehicule/'+this.annonce.vehicule.id+'/photo/' + isDamage, formdata, {
                            headers: {
                                'Authorization': this.token,
                                "Content-Type": "multipart/form-data"
                            },
                        });
                        
                        this.getPhotoVehicule();
                        this.v_photos = null;
                        this.isDamage = false;
                        //return true;
                    }
                    

                    //formdata.append("photos", this.v_photos);

                    //console.log(this.getHeaders());
                    
                    // return await axios.post(this.baseApiURL() + '/reprise/'+this.reprise.id+'/photo', formdata, {
                    //     headers: {
                    //         "Content-Type": "multipart/form-data"
                    //     },
                    // });
                    
                } catch (e) {
                    console.error(e);
                }
            },
            deletePhotoReprises: async function () {
                //console.log(this.activePhotoVehicule)
                try {
                    await axios.delete(this.baseApiURL() + '/photo/'+ this.activePhotoVehicule, this.getHeaders());
                    this.deletePhotoRepriseDialog = false;
                    this.getPhotoVehicule();
                } catch (e) {
                    console.error(e);
                }
            },
            formatDatePicker: async function (date) {
                try {
                    var thisDate = new Date(date);
                    console.log(thisDate.getDate().toString().length)
                    var day = (thisDate.getDate().toString().length == 1) ? "0" + thisDate.getDate() : thisDate.getDate();
                    var month = ((thisDate.getMonth() + 1).toString().length == 1) ? "0" + (thisDate.getMonth() + 1) : (thisDate.getMonth() + 1);
                    var year = thisDate.getFullYear();

                    return year + "-" + month + "-" + day;
                } catch (e) {
                    console.log(e);
                }
            },
            deleteVehicule: async function (id) {
                try {
                    await axios.delete(this.baseApiURL() + '/vehicule/'+ id, this.getHeaders());
                } catch (e) {
                    console.error(e);
                }
            },
            deleteAnnonce: async function () {
                try {
                    const response = await axios.delete(this.baseApiURL() + '/annonce/'+ this.annonce.id, this.getHeaders());
                    if(response) {
                        await this.deleteVehicule(this.annonce.vehicule.id);
                        
                        return true;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
        },
        beforeRouteLeave: async function (to, from , next) {
            // console.log(this.saveForm)
            const response = await axios.get(this.baseApiURL() + '/annonce/' + this.$route.params.id, this.getHeaders());
            if(!response.data.customer){
                //if(!this.saveForm) {
                    const answer = window.confirm('Voulez-vous vraiement annuler cette annonce ?')
                    if (answer) {
                        if (await this.deleteAnnonce()){
                            next()
                        }
                        
                    } else {
                        next(false)
                    }
                // }else{
                //     next()
                // }
            }else{
                next()
            }
        }
    }
</script>