<template>
    <v-card elevation="1">
        <v-card-title class="mb-3">
            Liste des utilisateurs liés à l'agence
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Rechercher"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        
        <v-divider class="mb-5"></v-divider>
        <v-card-text>
            <v-data-table 
                :headers="headers"
                :items="users"
                :search="search"
            >
                <template v-slot:[`item.status.label`]="{ item }">
                    <v-chip v-if="item.status.id === 1" color="success">{{item.status.label}}</v-chip>
                    <v-chip v-if="item.status.id === 2" color="warning">{{item.status.label}}</v-chip>
                    <v-chip v-if="item.status.id === 3" color="error">{{item.status.label}}</v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip color="red" label text-color="white" v-bind="attrs" v-on="on" @click="activeUser = item; deleteUserDialog = true">
                                <v-icon>
                                mdi-trash-can-outline
                                </v-icon>
                            </v-chip>
                        </template>
                        <span>Supprimer</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card-text>

        <v-dialog v-if="activeUser" v-model="deleteUserDialog" max-width="600px">
            <DeleteUserDialog :email="activeUser.email" v-on:valid="deleteUser" v-on:close="deleteUserDialog = false"></DeleteUserDialog>
        </v-dialog>

        <SnackBar color="success" title="Utilisateur supprimé !" v-on:close="snackbarDeleteUser = false" :model="snackbarDeleteUser"></SnackBar>

    </v-card>
</template>
  
<script>
    import axios from "axios";
    import DeleteUserDialog from "@/components/users/DeleteUserDialog";
    import SnackBar from "@/components/partials/Snackbar";

    export default {
        name: 'UserAgenceDialog',
        props: ['activeAgence'],

        components: {
            DeleteUserDialog,
            SnackBar
        },

        data() {
            return {
                currentAgence: [],
                activeUser: null,
                search: null,
                deleteUserDialog: false,
                snackbarDeleteUser: false,
                users: [],
                headers: [
                    { text: 'Prénom', value: 'firstname' },
                    { text: 'Nom', value: 'lastname' },
                    { text: 'Société', value: 'company' },
                    { text: 'Email', value: 'email' },
                    { text: 'Téléphone', value: 'phone' },
                    { text: 'Agence', value: 'agence.label' },
                    { text: 'Role', value: 'role.label' },
                    { text: 'Status', value: 'status.label' },
                    { text: 'Actions', value: 'actions' },
                ],
            }
        },
        watch: {
            activeAgence(val) {
                // Be sure to validate default values
                if(val !== '') {
                    this.currentAgence = val
                    this.getUsersAgence();
                }
            }
        },
        created() {
            
            this.currentAgence = this.activeAgence;
            this.getUsersAgence();
        },
        methods: {
            getUsersAgence: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/users/agence/' + this.currentAgence.id, this.getHeaders());
                    if (response) {
                        this.users = response.data;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            deleteUser: async function () {
                try {
                    const response = await axios.delete(this.baseApiURL() + '/user/'+ this.activeUser.id, this.getHeaders());
                    if(response) {
                        await this.getUsersAgence();
                        this.deleteUserDialog = false;
                        this.snackbarDeleteUser = true;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
        }
    }
</script>
  