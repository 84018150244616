<template>
    <v-row class="mt-3">
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Gestion de mon profil
                </v-card-title>
                <v-card-text class="px-14">
                    <v-form>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="currentUser.firstname"
                                    label="Prénom"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="currentUser.lastname"
                                    label="Nom"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-text-field
                                    v-model="currentUser.company"
                                    label="Société"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-text-field
                                    v-model="currentUser.siren"
                                    label="N° Siren"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    v-model="currentUser.address"
                                    label="Adresse postale"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-text-field
                                    v-model="currentUser.cp"
                                    label="Code postal"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-text-field
                                    v-model="currentUser.city"
                                    label="Ville"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="6">
                                <v-text-field
                                    v-model="currentUser.email"
                                    label="Adresse email"
                                    :rules="rules"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-text-field
                                    v-model="currentUser.phone"
                                    label="Téléphone"
                                    required
                                ></v-text-field>
                            </v-col>
                            <!-- <v-btn @click="generatePassword" small class="ma-auto">Générer un mot de passe</v-btn> -->
                            <v-col>
                                <v-switch v-model="switch1" label="Modifier le mot de passe"></v-switch>
                            </v-col>
                            <v-col cols="12" md="12" v-if="switch1">
                                <v-text-field
                                    v-model="password"
                                    label="Mot de passe"
                                    :rules="rulesPw"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showPassword ? 'text' : 'password'"
                                    @click:append="showPassword = !showPassword"
                                    required
                                ></v-text-field>
                            </v-col>
                            
                            <v-col cols="12" md="12" v-if="switch1">
                                <v-text-field
                                    v-model="confirmPassword"
                                    label="Confirmation du mot de passe"
                                    :rules="rulesCpw"
                                    required
                                    :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showConfirmPassword ? 'text' : 'password'"
                                    @click:append="showConfirmPassword = !showConfirmPassword"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" v-if="user.role.id == 1">
                                <v-select
                                    v-model="currentUser.role.label"
                                    :items="roles"
                                    item-value="label"
                                    item-text="label"
                                    label="Rôle"
                                    required
                                ></v-select>
                            </v-col>
                            <v-col v-if="(currentUser.role.label != 'Marchand' && currentUser.agence) || user.role.id == 1" cols="12" md="12">
                                <v-select
                                    v-if="user.role.id == 1"
                                    v-model="currentUser.agence.label"
                                    :items="agences"
                                    item-value="label"
                                    item-text="label"
                                    label="Agence"
                                    required
                                ></v-select>
                            </v-col>
                            <v-col v-if="(currentUser.role.label != 'Marchand' && !currentUser.agence) || user.role.id == 1" cols="12" md="12">
                                <v-select
                                    v-model="agence"
                                    :items="agences"
                                    item-value="label"
                                    item-text="label"
                                    label="Agence"
                                    required
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="12" v-if="user.role.id == 1">
                                <v-select
                                    v-model="currentUser.status.label"
                                    :items="userStatuses"
                                    item-value="label"
                                    item-text="label"
                                    label="Status"
                                    required
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-switch v-model="currentUser.setAlert" label="Recevoir une alerte dès qu'une annonce est créée ?"></v-switch>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        
                        <v-row class="d-flex pa-4">
                            <v-col>
                                <v-btn class="float-right" @click="editUser" v-if="switch1"
                                        :disabled="!currentUser.email || !confirmPassword || !currentUser.role || !currentUser.status || (confirmPassword !== password)">
                                        Modifier le profil
                                </v-btn>

                                <v-btn class="float-right" @click="editUser" v-if="!switch1"
                                        :disabled="!currentUser.email || !currentUser.role || !currentUser.status">
                                        Modifier le profil
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <SnackBar color="success" title="Profil mis à jour !" v-on:close="snackbarUpdateProfil = false" :model="snackbarUpdateProfil"></SnackBar>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from "axios";
import SnackBar from "@/components/partials/Snackbar";

export default {
    name: 'AccountView',

    components: {
        SnackBar,
    },

    data() {
      return {
        snackbarUpdateProfil: false,
        currentUser: [],
        agence: null,
        agenceLabel: null,
        switch1: false,
        password: null,
        confirmPassword: null,
        rules: [
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Adresse email invalide.'
          }
        ],
        rulesPw: [
          value => {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*.*[0-9]).+$/
            return pattern.test(value) && value.length >= 8 || 'Minimum 8 caractères avec 1 majuscule, 1 minuscule, 1 chiffre'
          }
        ],
        rulesCpw: [
          value => {
            return value == this.password || 'Vérifiez votre saisie'
          }
        ],
        showPassword: false,
        showConfirmPassword: false,
        agences: [],
        roles: [],
        userStatuses: [],
      }
    },
    created() {
        this.getUser();
        this.getAgences();
        this.getRoles();
        this.getUserStatus();
    },
    methods: {
        getUser: async function () {
            try {
                const response = await axios.get(this.baseApiURL() + '/user/' +this.user.id, this.getHeaders());
                if (response) {
                    this.currentUser = response.data;
                }
            } catch (e) {
                console.error(e);
            }
        },
        getAgences: async function () {
            try {
                const response = await axios.get(this.baseApiURL() + '/agences', this.getHeaders());
                if (response) {
                    this.agences = response.data;
                }
            } catch (e) {
                console.error(e);
            }
        },
        getRoles: async function () {
            try {
                const response = await axios.get(this.baseApiURL() + '/roles', this.getHeaders());
                if (response) {
                    this.roles = response.data;
                }
            } catch (e) {
                console.error(e);
            }
        },
        getUserStatus: async function () {
            try {
                const response = await axios.get(this.baseApiURL() + '/usersstatuses', this.getHeaders());
                if (response) {
                    this.userStatuses = response.data;
                }
            } catch (e) {
                console.error(e);
            }
        },
        editUser: async function () {
            
            if (this.currentUser.agence){
              this.agenceLabel = this.currentUser.agence.label;
            }else{
              this.agenceLabel = this.agence;
            }

            if (this.currentUser.role.label == "Marchand") this.agenceLabel = null;
            
            try {
                console.log(this.currentUser)
                const response = await axios.put(this.baseApiURL() + '/user/'+ this.currentUser.id + '/update', {
                    email: this.currentUser.email,
                    phone: this.currentUser.phone,
                    password: this.password,
                    confirmPassword: this.confirmPassword,
                    firstname: this.currentUser.firstname,
                    lastname: this.currentUser.lastname,
                    company: this.currentUser.company,
                    siren: this.currentUser.siren,
                    address: this.currentUser.address,
                    cp: this.currentUser.cp,
                    city: this.currentUser.city,
                    agence: this.agenceLabel,
                    role: this.currentUser.role.label,
                    status: this.currentUser.status.label,
                    setAlert: this.currentUser.setAlert,
                },this.getHeaders());
                
                if(response) {
                    this.$vuetify.goTo(0);
                    this.snackbarUpdateProfil = true;
                }
            } catch (e) {
                console.error(e);
            }
        },
        refresh: async function () {
                try {
                    await this.getUser();
                } catch (e) {
                    console.error(e);
                }
            }
    }
}
</script>
  