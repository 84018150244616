<template>
    <v-row class="mt-3">
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Liste des Annonces
                    <v-spacer></v-spacer>
                    <v-btn class="mr-3" @click="addAnnonce">Créer une annonce</v-btn>
                    <!-- <v-btn @click="addAnnonceDialog = true">Ajouter une annonce</v-btn>
                    <v-dialog v-model="addAnnonceDialog" max-width="800px">
                        <AddAnnonceDialog v-on:close="closeAddAnnonceDialog" v-on:abort="addAnnonceDialog = false"></AddAnnonceDialog>
                    </v-dialog> -->
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="searchAnnonce"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table 
                    :headers="headersAnnonce"
                    :items="annonces"
                    :search="searchAnnonce"
                >
                    <template v-slot:[`item.aTypeLine`]="{ item }">
                        <v-chip v-if="item.a_type === 0" color="light-green lighten-1">{{item.aTypeLine}}</v-chip>
                        <v-chip v-if="item.a_type === 1" color="light-blue lighten-1">{{item.aTypeLine}}</v-chip>
                    </template>

                    <template v-slot:[`item.custLine`]="{ item }">
                        <div><a :href="'/customers/?idCustomer=' + item.customer.id">{{item.custLine}}</a></div>
                    </template>

                    <template v-slot:[`item.userLine`]="{ item }">
                        <div>{{item.userLine}}</div>
                    </template>

                    <template v-slot:[`item.vehiculeLine`]="{ item }">
                        <div>{{item.vehiculeLine}}</div>
                    </template>

                    <template v-slot:[`item.status.label`]="{ item }">
                        <v-chip v-if="item.status.id === 1" color="yellow">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 2" color="light-blue lighten-1">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 3" color="light-green lighten-1">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 4" color="pink accent-2">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 5" color="orange darken-1">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 6" color="blue-grey lighten-1">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 7" color="deep-purple lighten-2">{{item.status.label}}</v-chip>
                    </template>

                    <template v-slot:[`item.typeLine`]="{ item }">
                        <v-chip v-if="item.type === 'tel'" color="light-green lighten-1">{{ item.typeLine }}</v-chip>
                        <v-chip v-if="item.type === 'rdv'" color="light-blue lighten-1">{{ item.typeLine }}</v-chip>
                        <v-chip v-if="item.type === 'out'" color="grey">{{ item.typeLine }}</v-chip>
                    </template>

                    <!-- <template v-slot:[`item.repa_list`]="{ item }">
                        
                        <v-chip x-small v-for="repa in JSON.parse(item.repa_list)" :key="repa">{{ repa }}</v-chip>
                    </template> -->

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="blue" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" :to="{ name: 'AnnoncesSingleView', params: { id: item.id }}">
                                    <v-icon>
                                    mdi-eye
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Voir détails</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="blue" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" :to="{ name: 'AnnoncesEditView', params: { id: item.id }}">
                                    <v-icon>
                                    mdi-pencil
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Editer</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="red" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeAnnonce = item;deleteAnnonceDialog = true">
                                    <v-icon>
                                    mdi-trash-can-outline
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Supprimer</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="orange" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeAnnonce = item;archiveAnnonceDialog = true">
                                    <v-icon>
                                    mdi-archive-outline
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Archiver</span>
                        </v-tooltip>
                        

                        <!-- <v-tooltip bottom v-if="item.status.id == 2">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="green" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeReprise = item;updateRepriseStatus(3)">
                                    <v-icon>
                                    mdi-check
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Valider</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="item.status.id == 2">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="red" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeReprise = item;updateRepriseStatus(4)">
                                    <v-icon>
                                    mdi-close
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Refuser</span>
                        </v-tooltip> -->
                    </template>
            
                </v-data-table>
                <SnackBar color="success" title="Annonce ajouté !" v-on:close="snackbarAddAnnonce = false" :model="snackbarAddAnnonce"></SnackBar>
                <SnackBar color="success" title="Annonce mis à jour !" v-on:close="snackbarUpdateAnnonce = false" :model="snackbarUpdateAnnonce"></SnackBar>
                <SnackBar color="success" title="Annonce supprimé !" v-on:close="snackbarDeleteAnnonce = false" :model="snackbarDeleteAnnonce"></SnackBar>
                <SnackBar color="success" title="Annonce archivé !" v-on:close="snackbarArchAnnonce = false" :model="snackbarArchAnnonce"></SnackBar>
            </v-card>
        </v-col>

        <v-col md="12" sm="12">
            <v-card elevation="2">
                <v-card-title>
                    Liste des Reprise
                    <v-spacer></v-spacer>
                    <!-- <v-btn href="/reprise_add">Ajouter une reprise</v-btn> -->
                    <v-btn @click="addReprise">Creer une reprise</v-btn>
                    <!-- <v-btn @click="addAnnonceDialog = true">Ajouter une annonce</v-btn>
                    <v-dialog v-model="addAnnonceDialog" max-width="800px">
                        <AddAnnonceDialog v-on:close="closeAddAnnonceDialog" v-on:abort="addAnnonceDialog = false"></AddAnnonceDialog>
                    </v-dialog> -->
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table 
                    :headers="headersReprise"
                    :items="reprises"
                    :search="search"
                >
                    <template v-slot:[`item.typeLine`]="{ item }">
                        <v-chip v-if="item.r_type === 0" color="light-green lighten-1">{{item.typeLine}}</v-chip>
                        <v-chip v-if="item.r_type === 1" color="light-blue lighten-1">{{item.typeLine}}</v-chip>
                    </template>

                    <template v-slot:[`item.custLine`]="{ item }">
                        <div v-if="item.custLine"><a :href="'/customers/?idCustomer=' + item.customer.id">{{item.custLine}}</a></div>
                    </template>

                    <template v-slot:[`item.userLine`]="{ item }">
                        <div>{{item.userLine}}</div>
                    </template>

                    <template v-slot:[`item.vehiculeLine`]="{ item }">
                        {{item.vehiculeLine}}
                    </template>

                    <template v-slot:[`item.price`]="{ item }">
                        <div v-if="(item.price_type == 0 && item.r_type == 0)">{{ parseInt(item.price_marge).toFixed(2) }} € TTC</div>
                        <div v-if="(item.price_type == 1 && item.r_type == 0)">{{ (parseInt(item.price_tva)+parseInt((item.price_tva*0.2))).toFixed(2) }} € TTC</div>
                        <div v-if="(item.r_type == 1)">{{ (parseInt(item.dv_price)+parseInt((item.dv_price*0.2))).toFixed(2) }} € TTC</div>
                    </template>

                    <!-- <template v-slot:[`item.price_tva`]="{ item }">
                        
                    </template>

                    <template v-if="(item.r_type == 1)" v-slot:[`item.dv_price`]="{ item }">
                        
                    </template> -->

                    <!-- <template v-slot:[`item.price_tva`]="{ item }">
                        <div>{{item.price_tva}} €</div>
                    </template> -->

                    <template v-slot:[`item.status.label`]="{ item }">
                        <v-chip v-if="item.status.id === 1" color="grey-darken-1">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 2" color="warning">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 3" color="success">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 4" color="error">{{item.status.label}}</v-chip>
                    </template>

                    <!-- <template v-slot:[`item.repa_list`]="{ item }">
                        
                        <v-chip x-small v-for="repa in JSON.parse(item.repa_list)" :key="repa">{{ repa }}</v-chip>
                    </template> -->

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="blue" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" :to="{ name: 'ReprisesEditView', params: { id: item.id }}">
                                    <v-icon>
                                    mdi-pencil
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Editer</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="red" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeReprise = item;deleteRepriseDialog = true">
                                    <v-icon>
                                    mdi-trash-can-outline
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Supprimer</span>
                        </v-tooltip>

                        

                        <v-tooltip bottom v-if="item.status.id == 2">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="green" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeReprise = item;updateRepriseStatus(3)">
                                    <v-icon>
                                    mdi-check
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Valider</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="item.status.id == 2">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="red" class="mr-1 ml-1" label text-color="white" v-bind="attrs" v-on="on" @click="activeReprise = item;updateRepriseStatus(4)">
                                    <v-icon>
                                    mdi-close
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Refuser</span>
                        </v-tooltip>
                    </template>
            
                </v-data-table>

                <v-dialog v-model="deleteRepriseDialog" max-width="600px">
                    <DeleteRepriseDialog :vehicule="activeReprise.vehicule" v-on:valid="deleteReprises" v-on:close="deleteRepriseDialog = false"></DeleteRepriseDialog>
                </v-dialog>

                <v-dialog v-model="deleteAnnonceDialog" max-width="600px">
                    <DeleteAnnonceDialog :vehicule="activeAnnonce.vehicule" v-on:valid="deleteAnnonce" v-on:close="deleteAnnonceDialog = false"></DeleteAnnonceDialog>
                </v-dialog>

                <v-dialog v-model="archiveAnnonceDialog" max-width="600px">
                    <ArchiveAnnonceDialog :vehicule="activeAnnonce.vehicule" v-on:valid="archiveAnnonce" v-on:close="archiveAnnonceDialog = false"></ArchiveAnnonceDialog>
                </v-dialog>

                <SnackBar color="success" title="Formulaire de reprise ajouté !" v-on:close="snackbarAddReprise = false" :model="snackbarAddReprise"></SnackBar>
                <SnackBar color="success" title="Formulaire de reprise mis à jour !" v-on:close="snackbarUpdateReprise = false" :model="snackbarUpdateReprise"></SnackBar>
                <SnackBar color="success" title="Formulaire de reprise supprimé !" v-on:close="snackbarDeleteReprise = false" :model="snackbarDeleteReprise"></SnackBar>

                
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import axios from "axios";
    import SnackBar from "@/components/partials/Snackbar";
    import DeleteRepriseDialog from "@/components/reprises/DeleteRepriseDialog";
    import DeleteAnnonceDialog from "@/components/reprises/DeleteAnnonceDialog";
    import ArchiveAnnonceDialog from "@/components/reprises/ArchiveAnnonceDialog";

    export default {
        name: 'AnnoncesView',

        components: {
            SnackBar,
            DeleteRepriseDialog,
            DeleteAnnonceDialog,
            ArchiveAnnonceDialog
        },

        data: () => ({
            // Components
            //addAnnonceDialog: false,
            snackbarAddAnnonce: false,
            snackbarDeleteAnnonce: false,
            snackbarUpdateAnnonce: false,
            snackbarArchAnnonce: false,

            snackbarAddReprise: false,
            snackbarDeleteReprise: false,
            snackbarUpdateReprise: false,

            deleteRepriseDialog: false,
            deleteAnnonceDialog: false,
            archiveAnnonceDialog: false,
            activeReprise: [],
            activeAnnonce: [],

            search: null,
            searchAnnonce: null,
            
            annonces: [],
            reprises: [],

            headersAnnonce: [
                { text: 'N°', value: 'reference'},
                { text: 'Type vente', value: 'aTypeLine'},
                { text: 'Client', value: 'custLine'},
                { text: 'Utilisateur', value: 'userLine' },
                { text: 'Véhicule', value: 'vehiculeLine' },
                // { text: 'Prix TVA sur marge', value: 'price_marge' },
                // { text: 'Prix TVA récupérable', value: 'price_tva' },
                // { text: 'Liste Réparations', value: 'repa_list' },
                // { text: 'Role', value: 'role.label' },
                { text: 'Status', value: 'status.label' },
                { text: 'Demande client', value: 'typeLine' },
                { text: 'Actions', value: 'actions' },
            ],

            headersReprise: [
                { text: 'N°', value: 'reference'},
                { text: 'Type vente', value: 'typeLine'},
                { text: 'Client', value: 'custLine'},
                { text: 'Utilisateur', value: 'userLine' },
                { text: 'Véhicule', value: 'vehiculeLine' },
                { text: 'Prix', value: 'price' },
                // { text: 'Prix TVA récupérable', value: 'price_tva' },
                // { text: 'Liste Réparations', value: 'repa_list' },
                // { text: 'Role', value: 'role.label' },
                { text: 'Status', value: 'status.label' },
                { text: 'Actions', value: 'actions' },
            ],
        }),
        async created() {
            
            if(this.user.role.id != 1){
                await this.getReprisesByAgence();
            }else{
                await this.getReprises();
            }
            if(this.user.role.id != 1){
                await this.getAnnoncesByAgence();
            }else{
                await this.getAnnonces();
            }
            
            if(this.$route.query.isCreate == 1){
                this.snackbarAddReprise = true;
            }

            if(this.$route.query.isUpdateAnnonce == 1){
                this.snackbarUpdateAnnonce = true;
            }

            if(this.$route.query.isUpdate == 1){
                this.snackbarUpdateReprise = true;
            }
        },
        methods: {
            closeAddAnnonceDialog: async function () {
                await this.getUsers();
                this.addUserDialog = false;
                this.snackbarAddUser = true;
            },
            getReprises: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/reprises', this.getHeaders());
                    if(response) {
                        
                        for(let reprise of response.data) {
                            let agence_id = reprise.user.agence;
                            const aResponse = await axios.get(this.baseApiURL() + '/agence/' + agence_id, this.getHeaders());
                            //console.log(aResponse.data);
                            reprise.typeLine = reprise.r_type == 0 ? "Vente direct" : "Dépôt / Vente";
                            reprise.user.agence = aResponse.data.label;
                            reprise.custLine = reprise.customer.firstname + " " + reprise.customer.lastname;
                            reprise.userLine = reprise.user.firstname + " " + reprise.user.lastname + '(' + aResponse.data.label + ')';
                            reprise.vehiculeLine = reprise.vehicule.marque + " " + reprise.vehicule.modele;
                        }
                        console.log(this.user);
                        this.reprises = response.data;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getReprisesByAgence: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/reprises/' + this.user.agence.id, this.getHeaders());
                    if(response) {
                        
                        for(let reprise of response.data) {
                            let agence_id = reprise.user.agence;
                            const aResponse = await axios.get(this.baseApiURL() + '/agence/' + agence_id, this.getHeaders());
                            //console.log(aResponse.data);
                            reprise.typeLine = reprise.r_type == 0 ? "Vente direct" : "Dépôt / Vente";
                            reprise.user.agence = aResponse.data.label;
                            reprise.custLine = reprise.customer.firstname + " " + reprise.customer.lastname;
                            reprise.userLine = reprise.user.firstname + " " + reprise.user.lastname + '(' + aResponse.data.label + ')';
                            reprise.vehiculeLine = reprise.vehicule.marque + " " + reprise.vehicule.modele;
                        }
                        console.log(this.reprises);
                        this.reprises = response.data;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getAnnonces: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/annonces', this.getHeaders());
                    if(response) {
                        
                        for(let annonce of response.data) {
                            let agence_id = annonce.user.agence;
                            const aResponse = await axios.get(this.baseApiURL() + '/agence/' + agence_id, this.getHeaders());
                            //console.log(aResponse.data);
                            annonce.aTypeLine = annonce.a_type == 0 ? "Vente direct" : "Dépôt / Vente";
                            annonce.user.agence = aResponse.data.label;
                            annonce.custLine = annonce.customer.firstname + " " + annonce.customer.lastname;
                            annonce.userLine = annonce.user.firstname + " " + annonce.user.lastname + '(' + aResponse.data.label + ')';
                            annonce.vehiculeLine = annonce.vehicule.marque + " " + annonce.vehicule.modele;
                            switch(annonce.type){
                                case 'tel':
                                    annonce.typeLine = 'Demande de rappel'
                                    break;
                                case 'rdv':
                                    annonce.typeLine = 'RDV prit'
                                    break;
                                case 'out':
                                    annonce.typeLine = 'Pas de rappel / pas de RDV'
                                    break;
                            }
                        }
                        this.annonces = response.data;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            getAnnoncesByAgence: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/annonces/' + this.user.agence.id, this.getHeaders());
                    if(response) {
                        
                        for(let annonce of response.data) {
                            let agence_id = annonce.user.agence;
                            const aResponse = await axios.get(this.baseApiURL() + '/agence/' + agence_id, this.getHeaders());
                            //console.log(aResponse.data);
                            annonce.aTypeLine = annonce.a_type == 0 ? "Vente direct" : "Dépôt / Vente";
                            annonce.user.agence = aResponse.data.label;
                            annonce.custLine = annonce.customer.firstname + " " + annonce.customer.lastname;
                            annonce.userLine = annonce.user.firstname + " " + annonce.user.lastname + '(' + aResponse.data.label + ')';
                            annonce.vehiculeLine = annonce.vehicule.marque + " " + annonce.vehicule.modele;
                            switch(annonce.type){
                                case 'tel':
                                    annonce.typeLine = 'Demande de rappel'
                                    break;
                                case 'rdv':
                                    annonce.typeLine = 'RDV prit'
                                    break;
                                case 'out':
                                    annonce.typeLine = 'Pas de rappel / pas de RDV'
                                    break;
                            }

                        }
                        this.annonces = response.data;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            addVehicule: async function () {
                try {
                    const response = await axios.post(this.baseApiURL() + '/vehicule', {
                        marque: "",
                        modele: "",
                        finition: "",
                        transmission: "",
                        date_first: "",
                        immat: "",
                        carrosserie: "",
                        genre: "",
                        energie: "",
                        nb_porte: "",
                        nb_place: "",
                        type_vehicule: "",
                        cote_volant: "",
                        km: null,
                        puissance: null,
                        boite_vitesse: "",
                        moteur: "",
                        
                    }, this.getHeaders());
                    if (response) {
                        //console.log(response.data.id);
                        return response.data.id;
            
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            addReprise: async function () {
                try {
                    const vehicule_id = await this.addVehicule();

                    const response = await axios.post(this.baseApiURL() + '/reprise', {
                        user: this.user.id,
                        customer: null,
                        vehicule: vehicule_id,
                        agence: this.user.agence.id,
                        pm_raison: '',
                        pm_siret: '',
                        co_firstname: "",
                        co_lastname: "",
                        type: "",
                        observations: "",
                        repa_list: "",
                        photos: "",
                        price_repa: "",
                        price_marge: "",
                        price_tva: "",
                        status: 1,
                        
                    }, this.getHeaders());
                    if (response) {
                        const reprise = response.data;
                        //console.log(response.data);
                        this.$router.push({ path: 'reprise_edit/' + reprise.id});
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            addAnnonce: async function () {
                try {
                    const vehicule_id = await this.addVehicule();

                    const response = await axios.post(this.baseApiURL() + '/annonce', {
                        user: this.user.id,
                        customer: null,
                        vehicule: vehicule_id,
                        agence: this.user.agence.id,
                        etat_vehicule: '',
                        color_ext: '',
                        color_int: '',
                        type_siege: '',
                        doc_vehicule: '',
                        type_vente: '',
                        canroll: true,
                        etat_carrosserie: '',
                        etat_interieur: '',
                        fonction_moteur: true,
                        fonction_boite_vitesse: true,
                        fonction_freins: true,
                        fonction_suspensions: true,
                        fonction_climatisation: true,
                        voyants: '',
                        voyants_precision: '',
                        pays_immat: '',
                        repa_list: '',
                        observations: '',
                        autre_info: '',
                        status: 1,
                        
                    }, this.getHeaders());
                    if (response) {
                        const annonce = response.data;
                        //console.log(response.data);
                        this.$router.push({ path: 'annonce_edit/' + annonce.id});
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            updateRepriseStatus: async function (status) {
                try {
                    const response = await axios.put(this.baseApiURL() + '/reprise/status/'+ this.activeReprise.id,{status: status}, this.getHeaders());
                    if(response) {
                        if(this.user.role.id != 1){
                            await this.getReprisesByAgence();
                        }else{
                            await this.getReprises();
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            deleteReprises: async function () {
                try {
                    const response = await axios.delete(this.baseApiURL() + '/reprise/'+ this.activeReprise.id, this.getHeaders());
                    if(response) {
                        await this.deleteVehicule(this.activeReprise.vehicule.id);
                        if(this.user.role.id != 1){
                            await this.getReprisesByAgence();
                        }else{
                            await this.getReprises();
                        }
                        this.deleteRepriseDialog = false;
                        this.snackbarDeleteReprise = true;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            archiveAnnonce: async function () {
                try {
                    const response = await axios.put(this.baseApiURL() + '/annonce/'+ this.activeAnnonce.id,{
                        status: 7
                    } ,this.getHeaders());
                    if(response) {
                        if(this.user.role.id != 1){
                            await this.getAnnoncesByAgence();
                        }else{
                            await this.getAnnonces();
                        }
                        this.archiveAnnonceDialog = false;
                        this.snackbarArchAnnonce = true;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            deleteAnnonce: async function () {
                try {
                    const response = await axios.delete(this.baseApiURL() + '/annonce/'+ this.activeAnnonce.id, this.getHeaders());
                    if(response) {
                        await this.deleteVehicule(this.activeAnnonce.vehicule.id);
                        if(this.user.role.id != 1){
                            await this.getAnnoncesByAgence();
                        }else{
                            await this.getAnnonces();
                        }
                        this.deleteAnnonceDialog = false;
                        this.snackbarDeleteAnnonce = true;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            deleteVehicule: async function (id) {
                try {
                    await axios.delete(this.baseApiURL() + '/vehicule/'+ id, this.getHeaders());
                } catch (e) {
                    console.error(e);
                }
            },
        }
    }
</script>
  