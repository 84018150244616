import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import mixin from "@/mixin";
//import AnnoncesEditView from './views/AnnoncesEditView.vue';
//import axios from "axios";

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const localToken = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));
  if(to.name === 'LoginView' && localToken) {
    if(user.role.label === 'Administrateur'){
      return next({name: 'HomeView'})
    }
    return next({name: 'HomeView'})
  }
  if(to.name !== 'LoginView' && to.name !== 'CustomerValidationView' && !localToken) {
    if(to.name === 'ForgotPasswordView') {
      return next();
    }
    return next({name: 'LoginView'})
  }
  // if(to.name === 'AnnoncesEditView'){
  //   console.log(this)
  //   const response = axios.get(this.baseApiURL() + '/annonce/' + to.params.id, this.getHeaders());
  //   if(response.data.user.agence == user.agence.id){
  //     return next();
  //   }else{
  //     return next({name: 'HomeView'})
  //   }
  // }
  //add all admin routes
  // if((to.name === 'AddTrainee'
  //     || to.name === 'AdminHome'
  //     || to.name === 'Note'
  //     || to.name === 'Calendar'
  //     || to.name === 'CalendarProfessor'
  //     || to.name === 'CalendarArchive'
  //     || to.name === 'TrainingFollow'
  //     || to.name === 'EditRecord'
  //     || to.name === 'Module'
  //     || to.name === 'Formation'
  //     || to.name === 'Repertory'
  //     || to.name === 'Qualiopi'
  //     || to.name === 'Email') && localToken){
  //   if(user.role.label === 'Administrateur'){
  //     return next();
  //   }
  //   else {
  //     return next({name: 'Home'});
  //   }
  // }
  // //add all trainee routes
  // if((to.name === 'Home'
  //     || to.name === 'Profile'
  //     || to.name === 'Inscription'
  //     || to.name === 'Records'
  //     || to.name === 'SendRecord'
  //     || to.name === 'CalendarTrainee'
  //     || to.name === 'Record') && localToken){
  //   if(user.role.label === 'Stagiaire' || user.role.label === 'Professeur'){
  //     return next();
  //   }
  //   else {
  //     return next({name: 'AdminHome'});
  //   }
  // }
  return next();

})

new Vue({
  router,
  vuetify,
  mixins: [mixin],
  render: h => h(App)
}).$mount('#app')
