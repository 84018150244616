<template>
    <v-card elevation="1">
      <v-card-title class="mb-3">
        Ajouter un utilisateur
      </v-card-title>
      <v-divider class="mb-5"></v-divider>
      <v-card-text>
        <v-form>
            <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="firstname"
                  label="Prénom"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="lastname"
                  label="Nom"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="company"
                  label="Société"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="siren"
                  label="N° Siren"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="address"
                  label="Adresse postale"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="cp"
                  label="Code postal"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="city"
                  label="Ville"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="email"
                  label="Adresse email"
                  :rules="rules"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="phone"
                  label="Téléphone"
                  required
              ></v-text-field>
            </v-col>
            <!-- <v-btn @click="generatePassword" small class="ma-auto">Générer un mot de passe</v-btn> -->
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="password"
                  label="Mot de passe"
                  :rules="rulesPw"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="confirmPassword"
                  label="Confirmation du mot de passe"
                  :rules="rulesCpw"
                  required
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                  v-model="role"
                  :items="roles"
                  item-value="label"
                  item-text="label"
                  label="Rôle"
                  required
              ></v-select>
            </v-col>
            <v-col v-if="role != 'Marchand'" cols="12" md="12">
              <v-select
                  v-model="agence"
                  :items="agences"
                  item-value="label"
                  item-text="label"
                  label="Agence"
                  required
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                  v-model="userStatus"
                  :items="userStatuses"
                  item-value="label"
                  item-text="label"
                  label="Status"
                  required
              ></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          
          <v-row class="d-flex pa-4">
            <v-col>
              <v-btn text color="primary" @click="$emit('abort')">Annuler</v-btn>
              <v-btn class="float-right" @click="addUser"
                :disabled="!email || password.length < 8 || !confirmPassword || !role || !userStatus || (confirmPassword !== password)" >Créer
                le compte
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: 'AddUserDialog',
    data() {
      return {
        email: null,
        phone: null,
        password: null,
        confirmPassword: null,
        firstname: null,
        lastname: null,
        company: null,
        siren: null,
        address: null,
        cp: null,
        city: null,
        agence: null,
        role: null,
        userStatus: null,
        rules: [
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Adresse email invalide.'
          }
        ],
        rulesPw: [
          value => {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*.*[0-9]).+$/
            return pattern.test(value) && value.length >= 8 || 'Minimum 8 caractères avec 1 majuscule, 1 minuscule, 1 chiffre'
          }
        ],
        rulesCpw: [
          value => {
            return value == this.password || 'Vérifiez votre saisie'
          }
        ],
        showPassword: false,
        showConfirmPassword: false,
        agences: [],
        roles: [],
        userStatuses: [],
      }
    },
    created() {
      this.getAgences();
      this.getRoles();
      this.getUserStatus();
    },
    methods: {
      getAgences: async function () {
            try {
                const response = await axios.get(this.baseApiURL() + '/agences', this.getHeaders());
                if (response) {
                    this.agences = response.data;
                }
            } catch (e) {
                console.error(e);
            }
        },
        getRoles: async function () {
            try {
                const response = await axios.get(this.baseApiURL() + '/roles', this.getHeaders());
                if (response) {
                    this.roles = response.data;
                }
            } catch (e) {
                console.error(e);
            }
        },
        getUserStatus: async function () {
            try {
                const response = await axios.get(this.baseApiURL() + '/usersstatuses', this.getHeaders());
                if (response) {
                    this.userStatuses = response.data;
                }
            } catch (e) {
                console.error(e);
            }
        },
        addUser: async function () {
            try {
            this.success = false;
            const response = await axios.post(this.baseApiURL() + '/user', {
                firstname: this.firstname,
                lastname: this.lastname,
                company: this.company,
                siren: this.siren,
                address: this.address,
                cp: this.cp,
                city: this.city,
                email: this.email,
                phone: this.phone,
                password: this.password,
                confirmPassword: this.confirmPassword,
                agence: this.agence,
                role: this.role,
                status: this.userStatus
                
            }, this.getHeaders());
            if (response) {
                if (!response.data.register) {
                    this.message = response.data.message;
                    this.snackbarError = true;
                } else {
                //   const response2 = await axios.post(this.baseApiURL() + '/email/send', {
                //     to: this.email,
                //     subject: 'Un compte vous à été créé sur la plateforme de l\'École de Formations Maritimes',
                //     html: '<p>Bonjour,</p><br>' +
                //         '<p> Un administrateur de la plateforme de l\'École de formations maritimes vous a créé un compte pour que vous puissiez remplir votre dossier d\'inscription !</p>' +
                //         '<p>Rendez vous sur <a href="' + this.selfURL() + '">' + this.selfURL() +'</a> pour vous connecter avec les identifiants suivants : </p><br>' +
                //         '<p>Nom d\'utilisateur : ' + this.email + '</p>' +
                //         '<p>Mot de passe : ' + this.password + '</p><br>' +
                //         '<p>A bientôt</p>' +
                //         '<p>Administration Ecole des Formations Maritimes</p>' +
                //         '<img width="250px" height="200px" src="' + this.selfURL() + '/images/logo-efm-2021.png" alt="Logo">'
                //   }, this.getHeaders());
                //   if(response2) {
                //    this.success = true;
                //   }
                }
                this.firstname = null;
                this.lastname = null;
                this.company = null;
                this.siren = null;
                this.address = null;
                this.cp = null;
                this.city = null;
                this.email = null;
                this.phone = null;
                this.password = null;
                this.confirmPassword = null;
                this.agence = null;
                this.role = null;
                this.userStatus = null;
                this.$emit('close');
    
            }
            } catch (e) {
            console.error(e);
            }
        },
        // generatePassword: function () {
        //     let generator = require('generate-password');
    
        //     let password = generator.generate({
        //         numbers: true,
        //         length:14,
        //         excludeSimilarCharacters: true,
        //     });
        //     this.password = password;
        //     this.confirmPassword = password;
        // }
    }
  }
  </script>
  