<template>
    <v-row class="mt-3">
        <v-col md="12" sm="12">
            <v-card elevation="1">
                <v-card-title>
                    Liste des utilisateurs
                    <v-spacer></v-spacer>
                    <v-btn @click="addUserDialog = true">Ajouter un compte utilisateur</v-btn>
                    <v-dialog v-model="addUserDialog" max-width="800px">
                        <AddUserDialog v-on:close="closeAddUserDialog" v-on:abort="addUserDialog = false"></AddUserDialog>
                    </v-dialog>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table 
                    :headers="headers"
                    :items="users"
                    :search="search"
                >
                    <template v-slot:[`item.status.label`]="{ item }">
                        <v-chip v-if="item.status.id === 1" color="success">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 2" color="warning">{{item.status.label}}</v-chip>
                        <v-chip v-if="item.status.id === 3" color="error">{{item.status.label}}</v-chip>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="blue" class="mr-3" label text-color="white" v-bind="attrs" v-on="on" @click="activeUser = item;editUserDialog = true">
                                    <v-icon>
                                    mdi-pencil
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Editer</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip color="red" label text-color="white" v-bind="attrs" v-on="on" @click="activeUser = item; deleteUserDialog = true">
                                    <v-icon>
                                    mdi-trash-can-outline
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Supprimer</span>
                        </v-tooltip>
                    </template>
            
                </v-data-table>
                <v-dialog v-model="deleteUserDialog" max-width="600px">
                    <DeleteUserDialog :email="activeUser.email" v-on:valid="deleteUser" v-on:close="deleteUserDialog = false"></DeleteUserDialog>
                </v-dialog>

                <v-dialog v-model="editUserDialog" max-width="800px">
                    <EditUserDialog :activeUser="activeUser" v-on:close="editUserDialog = false" v-on:valid="refresh"></EditUserDialog>
                </v-dialog>

                <!-- <v-dialog v-model="editUserDialog" max-width="800px">
                    <EditUserDialog :user="activeUser.id" v-on:close="editUserDialog = false" v-on:validEmail="refreshEmail" v-on:valid="refresh"></EditUserDialog>
                </v-dialog> -->
                <SnackBar color="success" title="Utilisateur ajouté !" v-on:close="snackbarAddUser = false" :model="snackbarAddUser"></SnackBar>
                <SnackBar color="success" title="Utilisateur supprimé !" v-on:close="snackbarDeleteUser = false" :model="snackbarDeleteUser"></SnackBar>
                <SnackBar color="success" title="Utilisateur modifié !" v-on:close="snackbarEditUser = false" :model="snackbarEditUser"></SnackBar>

                <!-- Debut snackbar -->
                <!-- <template>
                    <v-snackbar
                        v-model="snackbarDeleteUser"
                        absolute
                        timeout="-1"
                        top
                        outlined
                        color="success"
                    >
                        Utilisateur supprimé !
                        <template v-slot:action="{ attrs }">
                            <v-icon v-bind="attrs" @click="snackbarDeleteUser = false">mdi-close-circle</v-icon>
                        </template>
                    </v-snackbar>
                </template> -->
                <!-- Fin snackbar -->

            </v-card>
        </v-col>
    </v-row>
</template>
  
<script>
    import axios from "axios";
    import DeleteUserDialog from "@/components/users/DeleteUserDialog";
    import AddUserDialog from "@/components/users/AddUserDialog";
    import EditUserDialog from "@/components/users/EditUserDialog";
    import SnackBar from "@/components/partials/Snackbar";
  
    export default {
        name: 'UsersView',
    
        components: {
            DeleteUserDialog,
            AddUserDialog,
            EditUserDialog,
            SnackBar,
        },

        data: () => ({
            // Components
            activeUser: [],
            deleteUserDialog: false,
            snackbarDeleteUser: false,
            addUserDialog: false,
            snackbarAddUser: false,
            editUserDialog: false,
            snackbarEditUser: false,
            // Data table
            search: null,
            users: [],
            headers: [
                { text: 'Prénom', value: 'firstname' },
                { text: 'Nom', value: 'lastname' },
                { text: 'Société', value: 'company' },
                { text: 'Email', value: 'email' },
                { text: 'Téléphone', value: 'phone' },
                { text: 'Agence', value: 'agence.label' },
                { text: 'Role', value: 'role.label' },
                { text: 'Status', value: 'status.label' },
                { text: 'Actions', value: 'actions' },
            ],
        }),
        async created() {
            document.title = 'Gestion des utilisateurs';
            await this.getUsers();
        },
        methods: {
            getUsers: async function () {
                try {
                    const response = await axios.get(this.baseApiURL() + '/users', this.getHeaders());
                    this.addUserDialog = false;
                    if(response) {
                        this.users = response.data;
                    }
                } catch (e) {
                    console.error(e);
                }
            },

            deleteUser: async function () {
                try {
                    const response = await axios.delete(this.baseApiURL() + '/user/'+ this.activeUser.id, this.getHeaders());
                    if(response) {
                        await this.getUsers();
                        this.deleteUserDialog = false;
                        this.snackbarDeleteUser = true;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            closeAddUserDialog: async function () {
                await this.getUsers();
                this.addUserDialog = false;
                this.snackbarAddUser = true;
            },
            refresh: async function () {
                try {
                    await this.getUsers();
                    this.editUserDialog = false;
                    this.snackbarEditUser = true;
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }
</script>
  