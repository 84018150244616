<template>
    <v-card>
        <v-card-title class="headline"><p>Modifier le description de la photo</p></v-card-title>
        <v-card-text>
            <v-textarea
                v-model="currentPhoto.description"
                filled
                label="Description de la photo"
                auto-grow
            ></v-textarea>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">Annuler</v-btn>
            <v-btn color="red darken-1" text @click="editPhoto">Confirmer</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from "axios";

export default {
    
    name: 'DescPhotoDialog',
    props: ['activePhoto'],

    data() {
        return {
            currentPhoto: null,
            descPhoto: ""
        }
    },

    watch: {
        activePhoto(val) {
            // Be sure to validate default values
            //if(val !== '') {
                this.currentPhoto = val;
                console.log("load")
            //}
        }
    },
    created() {
        
        this.currentPhoto = this.activePhoto;
        
    },
    methods: {
        editPhoto: async function () {
            try {
                const response = await axios.put(this.baseApiURL() + '/descPhoto/' + this.currentPhoto.id, {
                  description: this.currentPhoto.description
                },this.getHeaders());
                
                if(response) {
                    this.descPhoto = "";
                    this.$emit('valid');
                }
            } catch (e) {
                console.error(e);
            }
        },
    }
}
</script>
