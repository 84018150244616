<template>
    <v-card elevation="1">
      <v-card-title class="mb-3">
        Modifier l'utilisateur
      </v-card-title>
      <v-divider class="mb-5"></v-divider>
      <v-card-text>
        <v-form>
            <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="currentUser.firstname"
                  label="Prénom"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="currentUser.lastname"
                  label="Nom"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentUser.company"
                  label="Société"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentUser.siren"
                  label="N° Siren"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="currentUser.address"
                  label="Adresse postale"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentUser.cp"
                  label="Code postal"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentUser.city"
                  label="Ville"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentUser.email"
                  label="Adresse email"
                  :rules="rules"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="currentUser.phone"
                  label="Téléphone"
                  required
              ></v-text-field>
            </v-col>
            <!-- <v-btn @click="generatePassword" small class="ma-auto">Générer un mot de passe</v-btn> -->
            <v-col>
                <v-switch v-model="switch1" label="Modifier le mot de passe"></v-switch>
            </v-col>
            <v-col cols="12" md="12" v-if="switch1">
              <v-text-field
                  v-model="password"
                  label="Mot de passe"
                  :rules="rulesPw"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  required
              ></v-text-field>
            </v-col>
            
            <v-col cols="12" md="12" v-if="switch1">
              <v-text-field
                  v-model="confirmPassword"
                  label="Confirmation du mot de passe"
                  :rules="rulesCpw"
                  required
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                  v-model="currentUser.role.label"
                  :items="roles"
                  item-value="label"
                  item-text="label"
                  label="Rôle"
                  required
              ></v-select>
            </v-col>
            <v-col v-if="currentUser.role.label != 'Marchand' && currentUser.agence" cols="12" md="12">
              <v-select
                  v-model="currentUser.agence.label"
                  :items="agences"
                  item-value="label"
                  item-text="label"
                  label="Agence"
                  required
              ></v-select>
            </v-col>
            <v-col v-if="currentUser.role.label != 'Marchand' && !currentUser.agence" cols="12" md="12">
              <v-select
                  v-model="agence"
                  :items="agences"
                  item-value="label"
                  item-text="label"
                  label="Agence"
                  required
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                  v-model="currentUser.status.label"
                  :items="userStatuses"
                  item-value="label"
                  item-text="label"
                  label="Status"
                  required
              ></v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          
          <v-row class="d-flex pa-4">
            <v-col>
              <v-btn text color="primary" @click="$emit('close')">Annuler</v-btn>
              <v-btn class="float-right" @click="editUser" v-if="switch1"
                     :disabled="!currentUser.email || !confirmPassword || !currentUser.role || !currentUser.status || (confirmPassword !== password)">
                     Modifier le compte
              </v-btn>

              <v-btn class="float-right" @click="editUser" v-if="!switch1"
                    :disabled="!currentUser.email || !currentUser.role || !currentUser.status">
                     Modifier le compte
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: 'EditUserDialog',
    props: ['activeUser'],
    
    data() {
      return {
        currentUser: [],
        agence: null,
        agenceLabel: null,
        switch1: false,
        password: null,
        confirmPassword: null,
        rules: [
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Adresse email invalide.'
          }
        ],
        rulesPw: [
          value => {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*.*[0-9]).+$/
            return pattern.test(value) && value.length >= 8 || 'Minimum 8 caractères avec 1 majuscule, 1 minuscule, 1 chiffre'
          }
        ],
        rulesCpw: [
          value => {
            return value == this.password || 'Vérifiez votre saisie'
          }
        ],
        showPassword: false,
        showConfirmPassword: false,
        agences: [],
        roles: [],
        userStatuses: [],
      }
    },
    watch: {
        activeUser(val) {
            // Be sure to validate default values
            if(val !== '') {
                this.currentUser = val
            }
        }
    },
    created() {
        
        this.currentUser = this.activeUser;
        this.getAgences();
        this.getRoles();
        this.getUserStatus();
    },
    methods: {
        getAgences: async function () {
            try {
                const response = await axios.get(this.baseApiURL() + '/agences', this.getHeaders());
                if (response) {
                    this.agences = response.data;
                }
            } catch (e) {
                console.error(e);
            }
        },
        getRoles: async function () {
            try {
                const response = await axios.get(this.baseApiURL() + '/roles', this.getHeaders());
                if (response) {
                    this.roles = response.data;
                }
            } catch (e) {
                console.error(e);
            }
        },
        getUserStatus: async function () {
            try {
                const response = await axios.get(this.baseApiURL() + '/usersstatuses', this.getHeaders());
                if (response) {
                    this.userStatuses = response.data;
                }
            } catch (e) {
                console.error(e);
            }
        },
        editUser: async function () {
            if (this.currentUser.agence){
              this.agenceLabel = this.currentUser.agence.label;
            }else{
              this.agenceLabel = this.agence;
            }

            if (this.currentUser.role.label == "Marchand") this.agenceLabel = null;
            
            try {
                const response = await axios.put(this.baseApiURL() + '/user/'+ this.currentUser.id + '/update', {
                    email: this.currentUser.email,
                    phone: this.currentUser.phone,
                    password: this.password,
                    confirmPassword: this.confirmPassword,
                    firstname: this.currentUser.firstname,
                    lastname: this.currentUser.lastname,
                    company: this.currentUser.company,
                    siren: this.currentUser.siren,
                    address: this.currentUser.address,
                    cp: this.currentUser.cp,
                    city: this.currentUser.city,
                    agence: this.agenceLabel,
                    role: this.currentUser.role.label,
                    status: this.currentUser.status.label,
                },this.getHeaders());
                
                if(response) {
                    this.$emit('valid');
                }
            } catch (e) {
                console.error(e);
            }
        },
    }
  }
  </script>
  