<template>
    <div>
      <v-navigation-drawer v-model="drawer" app fixed width="300">
        <v-img src="../../assets/logo-monoto.png" height="150px" contain class="mt-4"></v-img>
        <v-list flat class="mt-3">
          <v-list-item-group v-model="model" color="#027DB0">

            <v-list-item to="/">
              <v-list-item-icon>
                <v-icon>mdi-gauge</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Tableau de bord</v-list-item-title>
              </v-list-item-content>
            </v-list-item>


            <v-list-item v-if="role==1" to="/settings">
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Paramètre généraux</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="role==1" to="/factures_admin">
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Gestion des factures</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/account">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Mon profil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="role==1 || role==2" to="/open-hours">
              <v-list-item-icon>
                <v-icon>mdi-store</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Horaires d'ouverture</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/calendar">
              <v-list-item-icon>
                <v-icon>mdi-calendar-blank</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Calendrier</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="role!=1" to="/factures_user">
              <v-list-item-icon>
                <v-icon>mdi-file-document-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Mes Factures</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <!-- Admin Tabs -->
            <v-list-item v-if="role==1" to="/users">
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Utilisateurs</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="role==1" to="/agences">
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Agences</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="role==1" to="/customers">
              <v-list-item-icon>
                <v-icon>mdi-account-cash</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Clients</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item v-if="role==1" to="/ventes">
              <v-list-item-icon>
                <v-icon>mdi-cash</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Vente</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/annonces">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Gerer Annonces / Reprise</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/annonces_list">
              <v-list-item-icon>
                <v-icon>mdi-handshake-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Voir Annonces</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
  
            <!-- <v-list-group prepend-icon="mdi-bookshelf">
              <template v-slot:activator>
                <v-list-item-title>Nos formations</v-list-item-title>
              </template>
              <v-list-item :to="{name: 'Repertory', params: {school: 'olonne'}}" class="ml-3">
                <v-list-item-icon>
                  <v-icon>mdi-map-marker-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Les Sables d'Olonne</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="{name: 'Repertory', params: {school: 'yeu'}}" class="ml-3">
                <v-list-item-icon>
                  <v-icon>mdi-map-marker-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>L'Île d'Yeu</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>  -->
            
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar app color="#027DB0" class="white--text">
        <v-app-bar-nav-icon class="white--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
  
        <v-toolbar-title>JVMA</v-toolbar-title>
        <v-spacer></v-spacer>
        <span>Bonjour, {{ user.email}} </span>
        <v-btn class="ml-5" color="#9CCAE2" v-on:click="disconnect">Deconnexion</v-btn>
      </v-app-bar>
    </div>
  </template>
  
  <script>
  import axios from "axios";

  export default {
    name: 'NavAdmin',
    data: () => ({
      role: null,
      drawer: null,
      model: 1
    }),
    created() {
        this.getUserRole();
    },
    methods: {
      getUserRole: async function () {
        try {
            const response = await axios.get(this.baseApiURL() + '/user/' +this.user.id, this.getHeaders());
            if (response) {
                this.role = response.data.role.id;
            }
        } catch (e) {
            console.error(e);
        }
      },
      disconnect: function () {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        this.$router.push({name: 'LoginView'});
      }
    }
  }
  </script>
  