<template>
    <v-card elevation="1">
      <v-card-title class="mb-3">
        Ajouter un client
      </v-card-title>
      <v-divider class="mb-5"></v-divider>
      <v-card-text>
        <v-form>
            <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="firstname"
                  label="Prénom"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="lastname"
                  label="Nom"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="email"
                  label="Adresse email"
                  :rules="rules"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="contact_from"
                  label="De la part de (Email)"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="phone"
                  label="Téléphone"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="address"
                  label="Adresse postale"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="cp"
                  label="Code postal"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="city"
                  label="Ville"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                  v-model="country"
                  label="Pays"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea 
                  v-model="note"
                  label="Note"
              ></v-textarea >
            </v-col>
          </v-row>
          <v-divider></v-divider>
          
          <v-row class="d-flex pa-4">
            <v-col>
              <v-btn text color="primary" @click="$emit('abort')">Annuler</v-btn>
              <v-btn class="float-right" @click="addCustomer"
                :disabled="!email || !cp || !firstname || !lastname" >
                Créer le client
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: 'AddCustomerDialog',
    data() {
      return {
        email: null,
        contact_from: null,
        phone: null,
        firstname: null,
        lastname: null,
        address: null,
        cp: null,
        city: null,
        country: null,
        note: null,
        rules: [
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Adresse email invalide.'
          }
        ],
      }
    },
    created() {
    },
    methods: {
        addCustomer: async function () {
            try {
            this.success = false;
            const response = await axios.post(this.baseApiURL() + '/customer', {
                firstname: this.firstname,
                lastname: this.lastname,
                address: this.address,
                cp: this.cp,
                city: this.city,
                country: this.country,
                email: this.email,
                contact_from: this.contact_from,
                phone: this.phone,
                note: this.note,
                
            }, this.getHeaders());
            if (response) {
                if (!response.data.register) {
                    this.message = response.data.message;
                    this.snackbarError = true;
                } else {
                //   const response2 = await axios.post(this.baseApiURL() + '/email/send', {
                //     to: this.email,
                //     subject: 'Un compte vous à été créé sur la plateforme de l\'École de Formations Maritimes',
                //     html: '<p>Bonjour,</p><br>' +
                //         '<p> Un administrateur de la plateforme de l\'École de formations maritimes vous a créé un compte pour que vous puissiez remplir votre dossier d\'inscription !</p>' +
                //         '<p>Rendez vous sur <a href="' + this.selfURL() + '">' + this.selfURL() +'</a> pour vous connecter avec les identifiants suivants : </p><br>' +
                //         '<p>Nom d\'utilisateur : ' + this.email + '</p>' +
                //         '<p>Mot de passe : ' + this.password + '</p><br>' +
                //         '<p>A bientôt</p>' +
                //         '<p>Administration Ecole des Formations Maritimes</p>' +
                //         '<img width="250px" height="200px" src="' + this.selfURL() + '/images/logo-efm-2021.png" alt="Logo">'
                //   }, this.getHeaders());
                //   if(response2) {
                //    this.success = true;
                //   }
                }
                this.firstname = null;
                this.lastname = null;
                this.address = null;
                this.cp = null;
                this.city = null;
                this.country = null;
                this.email = null;
                this.contact_from = null;
                this.phone = null;
                this.note = null;
                this.$emit('close');
    
            }
            } catch (e) {
            console.error(e);
            }
        },
    }
  }
  </script>
  